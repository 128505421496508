import { memo, useCallback, useState } from "react"
import { BoxProps, Button } from "@chakra-ui/react"
import { useCustomerContext } from "@app/providers/customer"
import { useCore } from "@app/hooks/useCore"
import { useConfigContext } from "@app/providers/config"
import { graphql, navigate, useStaticQuery } from "gatsby"

type ProductCardNotifyMeProps = {
  variant: any
  fontSize?: string | Array<string>
} & BoxProps

const isBrowser = typeof document !== "undefined"

const ProductCardNotifyMe: React.FC<ProductCardNotifyMeProps> = ({ variant, fontSize = ["sm", "sm", "md"], ...props }) => {
  // State setters
  const [willBeNotified, setWillBeNotified] = useState(false)

  const { template } = useStaticQuery<GatsbyTypes.StaticProductCardLabelsQuery>(graphql`
    query StaticProductCardLabels {
      template: sanityTemplateProduct {
        additionalNotifyMeClickedLabel
        additionalNotifyMeLabel
      }
    }
  `)

  // External hook calls
  const { customer } = useCustomerContext()
  const {
    helpers: { decodeBase64 },
  } = useCore()
  const {
    settings: { functions, routes },
  } = useConfigContext()

  // Helper variables
  const decodedVariantId = variant?.id ? decodeBase64(variant?.id) : ""
  const variantId = decodedVariantId ? decodedVariantId?.split("/")?.[4] : ""
  const buttonText = willBeNotified ? template?.additionalNotifyMeClickedLabel : template?.additionalNotifyMeLabel

  // Handler functions
  const handleNotifyMe = useCallback(async () => {
    if (!variantId) console.warn("No variant ID for variant in handleNotifyMe", variant)

    if (!customer?.email) navigate(routes.LOGIN_REGISTER)
    const encodedEmail = isBrowser ? window?.encodeURIComponent(customer?.email) : ""
    if (!encodedEmail) return null

    setWillBeNotified(true)

    const response = await fetch(`/api/${functions.klaviyoBackInStock}?email=${encodedEmail}&variant=${variantId}`, { method: "post" })
    console.info("Notify me response", response)
  }, [customer?.email, functions.klaviyoBackInStock, routes.LOGIN_REGISTER, variant, variantId])

  return (
    <Button
      variant="outline"
      w={["full", "full"]}
      p="unset"
      maxW="full"
      mb={[0, 2, 2]}
      fontSize={fontSize}
      onClick={handleNotifyMe}
      minW="unset"
      {...props}
    >
      {buttonText}
    </Button>
  )
}

const MemoProductCardNotifyMe = memo(ProductCardNotifyMe)
export { MemoProductCardNotifyMe as ProductCardNotifyMe }
