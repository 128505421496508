import { useMemo, createContext, useContext, useEffect, useCallback, useState } from "react"
import { useConfigContext } from "@app/providers/config"
import { useCore } from "@app/hooks/useCore"
import { useWaitForGlobal } from "@app/hooks/useWaitForGlobal"
import { useCustomerContext } from "@app/providers/customer"
import { useFunctions } from "@app/hooks/useFunctions"
import { useStorage } from "@app/hooks/useCore"
import { useDate } from "@app/hooks/useDate"

type ContextProps = {
  hasInitialized: boolean
  logoutLoyaltyLionCustomer: () => void
  loyaltyLionCustomer: any
  loyaltyLionGlobalReady: boolean
}

const RewardsContext = createContext<ContextProps | undefined>(undefined)

const RewardsProvider: React.FC = ({ children }) => {
  // Hook calls
  const { waitForGlobal } = useWaitForGlobal()
  const {
    helpers: { isBrowser },
  } = useCore()

  const {
    store: { loyaltyLionApiToken },
    settings: { functions, keys },
  } = useConfigContext()
  const { customer } = useCustomerContext()
  const { callFunction } = useFunctions()
  const { getStorage, setStorage, removeStorage } = useStorage()
  const { timeAgo } = useDate()

  // State Setters
  const [loyaltyLionGlobalReady, setLoyaltyLionGlobalReady] = useState(false)
  const [hasInitialized, setHasInitialized] = useState(false)
  const [loyaltyLionCustomer, setLoyaltyLionCustomer] = useState(getStorage(keys.loyaltyLionCustomer) || null)
  const [customerAuthToken, setCustomerAuthToken] = useState(isBrowser ? window?.sessionStorage?.getItem(keys.loyaltyLionAuthToken) : null)
  const [customerAuthDate, setCustomerAuthDate] = useState(isBrowser ? window?.sessionStorage?.getItem(keys.loyaltyLionAuthDate) : null)
  const [loyaltyLionFetching, setLoyaltyLionFetching] = useState(false)

  // This authenticates the logged in customer with LoyaltyLion
  // via a custom gatsby function, since it's using a secret
  // See https://developers.loyaltylion.com/sdk/javascript-api/customer-authentication/
  const createCustomerAuthtoken = useCallback(async () => {
    try {
      const response = await callFunction(functions.loyaltyLionCreateCustomerToken, {
        customerId: customer?.id,
        customerEmail: customer?.email,
      })
      if ("error" === response.status) throw new Error("Error creating loyaltylion customer auth token", response)
      const { customerAuthToken, date } = response?.body || {}

      // Store the auth token and date in sessionstorage, and update state
      if (isBrowser) {
        window?.sessionStorage?.setItem(keys.loyaltyLionAuthToken, customerAuthToken)
        window?.sessionStorage?.setItem(keys.loyaltyLionAuthDate, date)
        setCustomerAuthToken(customerAuthToken)
        setCustomerAuthDate(date)
      }
    } catch (err) {
      console.error(err)
    }
  }, [callFunction, functions, isBrowser, keys, customer])

  // Helper function to get loyalty lion customer for use throughout the site
  // where the customer details are needed
  const getLoyaltyLionCustomer = useCallback(
    async (forceRefetch = false) => {
      const storedCustomerInfo = getStorage(keys.loyaltyLionCustomer)

      if (storedCustomerInfo && !forceRefetch) {
        setLoyaltyLionCustomer(storedCustomerInfo)
        return
      }

      setStorage(keys.loyaltyLionLastFetch, new Date().toString(), 1)
      try {
        setLoyaltyLionFetching(true)

        const response = await callFunction(functions.loyaltyLionGetCustomerInfo, {
          customerEmail: customer?.email,
        })

        if (response.status === "error")
          throw new Error(`Error getting loyaltylion customer info with ${customer?.email}, Error: ${response}`)

        if (response.status !== "error") {
          setLoyaltyLionCustomer(response?.body)
          setStorage(keys.loyaltyLionCustomer, response?.body, 1)
        }
      } catch (err) {
        console.error(err)
        setStorage(keys.loyaltyLionLastFetch, "", 1)
      }
      setLoyaltyLionFetching(false)
      return
    },
    [callFunction, customer, functions, keys, setStorage, getStorage, setLoyaltyLionCustomer, setLoyaltyLionFetching]
  )

  // Helper function to log loyaltylion customer out
  const logoutLoyaltyLionCustomer = useCallback(async () => {
    if (loyaltyLionGlobalReady && isBrowser) {
      await window?.loyaltylion.logoutCustomer()
      removeStorage(keys.loyaltyLionCustomer)
      window.sessionStorage.removeItem(keys.loyaltyLionAuthToken)
      console.info("LoyaltyLion customer is logged out!")
    }
  }, [loyaltyLionGlobalReady, keys, removeStorage, isBrowser])

  // First, wait for the window.loyaltylion to become available
  // Intentionally only run on first render
  useEffect(() => {
    waitForGlobal("loyaltylion", () => {
      setLoyaltyLionGlobalReady(true)
    })
  }, [waitForGlobal])

  // Next, after window.loyaltylion is ready, isBrowser, and customer is available,
  // get or create loyaltylion auth token
  useEffect(() => {
    if (loyaltyLionGlobalReady) {
      // If an auth token or date doesn't already exist in the sessionStorage, create one
      // If the shopify customer is logged in
      if ((!customerAuthToken || !customerAuthDate) && customer) {
        createCustomerAuthtoken()
      }
    }
  }, [isBrowser, loyaltyLionGlobalReady, createCustomerAuthtoken, customerAuthToken, customerAuthDate, customer])

  // Once everything is ready, initialize loyaltylion widget with customer info and auth tokens
  useEffect(() => {
    const shouldInitialize = !!(customerAuthDate && customerAuthToken && loyaltyLionGlobalReady && customer && isBrowser)

    // See https://developers.loyaltylion.com/sdk/javascript-api/getting-started#initialization
    if (shouldInitialize && !hasInitialized) {
      window?.loyaltylion?.init({
        token: loyaltyLionApiToken,
        customer: {
          id: customer?.id,
          email: customer?.email,
        },
        auth: {
          date: customerAuthDate,
          token: customerAuthToken,
        },
      })

      // https://developers.loyaltylion.com/sdk/#disabling-our-default-fonts
      window?.loyaltylion?.configure({
        disableBundledFonts: true,
      })

      setHasInitialized(true)
    }
  }, [
    customerAuthToken,
    customerAuthDate,
    loyaltyLionGlobalReady,
    customer,
    loyaltyLionApiToken,
    isBrowser,
    hasInitialized,
    setHasInitialized,
  ])

  // Get the loyalty lion customer info to pass down into the context for use throughout the site
  // once the everything is ready
  useEffect(() => {
    const lastFetch = getStorage(keys.loyaltyLionLastFetch)
    const lastFetchExpired = !customer?.id ? false : lastFetch ? timeAgo(lastFetch, "minutes", 1) : loyaltyLionCustomer?.id ? true : false
    const shouldGetCustomerInfo = customer && loyaltyLionGlobalReady && hasInitialized && !loyaltyLionCustomer?.id && !loyaltyLionFetching
    if (shouldGetCustomerInfo || lastFetchExpired) {
      getLoyaltyLionCustomer(lastFetchExpired)
    }
  }, [
    customer,
    loyaltyLionGlobalReady,
    loyaltyLionCustomer,
    hasInitialized,
    getLoyaltyLionCustomer,
    getStorage,
    timeAgo,
    keys.loyaltyLionLastFetch,
    loyaltyLionFetching,
  ])

  const contextValue = useMemo<ContextProps>(
    () => ({
      hasInitialized,
      logoutLoyaltyLionCustomer,
      loyaltyLionCustomer,
      loyaltyLionGlobalReady,
    }),
    [hasInitialized, logoutLoyaltyLionCustomer, loyaltyLionCustomer, loyaltyLionGlobalReady]
  )

  return <RewardsContext.Provider value={contextValue}>{children}</RewardsContext.Provider>
}

const useRewardsContext = (): ContextProps => ({ ...useContext(RewardsContext) } as ContextProps)

export { RewardsContext, RewardsProvider, useRewardsContext }
export type { ContextProps }
