import { Button, HStack } from "@chakra-ui/react"
import { memo, useMemo } from "react"
import { OneTimeOrRecurring } from "../Product/ProductOneTimeOrRecurring"

type ProductCardVariantSelectorProps = {
  item: any
  setSelectedVariantIdx: (variantIdx: number) => void
  selectedVariantIdx: number
  hideLabel?: boolean
  isPlp?: boolean
  stripSizeSuffix?: boolean
  isSubscriptionAndOneTimeProduct?: boolean
  recurrence?: any
  appliedDiscount?: number | undefined
}

const ProductCardVariantSelector: React.FC<ProductCardVariantSelectorProps> = ({
  item,
  setSelectedVariantIdx,
  selectedVariantIdx,
  hideLabel = false,
  isPlp = false,
  stripSizeSuffix = false,
  isSubscriptionAndOneTimeProduct = false,
  recurrence,
  appliedDiscount,
}) => {
  const optionsWithoutDefault = useMemo(() => {
    return item?.options?.filter((option: any) => {
      if (typeof option === "string") {
        return option !== "Title"
      }
      if (typeof option === "object" && option.name) {
        return option.name !== "Title"
      }
      return true
    })
  }, [item?.options])

  const hasOptions = !!optionsWithoutDefault?.length
  const { purchaseRecurrence, setPurchaseRecurrence } = recurrence || {}

  const shouldShowSelector = (isPlp && hasOptions) || (isPlp && isSubscriptionAndOneTimeProduct)

  if (!shouldShowSelector) return null

  const firstOptionName = stripSizeSuffix ? item?.options?.[0].replace("Size", "") : item?.options?.[0]

  return (
    <HStack my={2} maxW="full" flexWrap="wrap" spacing={0} gap={1}>
      {isSubscriptionAndOneTimeProduct && (
        <OneTimeOrRecurring
          setPurchaseRecurrence={setPurchaseRecurrence}
          purchaseRecurrence={purchaseRecurrence}
          appliedDiscount={appliedDiscount}
          isPlp={isPlp}
        />
      )}
      {hasOptions &&
        item?.variants?.map((variant, idx) => {
          const isActiveVariant = selectedVariantIdx === idx

          return (
            <Button
              variant={isActiveVariant ? "solid" : "outline"}
              key={idx}
              minW="unset"
              px={2}
              height="unset"
              py={1}
              fontSize="xxs"
              borderRadius="68px"
              onClick={() => setSelectedVariantIdx(idx)}
            >
              {variant.title} {hideLabel ? null : firstOptionName}
            </Button>
          )
        })}
    </HStack>
  )
}

const MemoProductCardVariantSelector = memo(ProductCardVariantSelector)
export { MemoProductCardVariantSelector as ProductCardVariantSelector }
