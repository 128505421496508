import { breakpoints } from "./breakpoints"
import { colors } from "./colors"
import global from "./global"
import { sizes } from "./sizes"
import { typography } from "./typography"
import { borders } from "./borders"
import { radii } from "./radius"
import { space } from "./space"
import { transition } from "./transition"

const foundations = {
  borders,
  breakpoints,
  colors,
  global,
  radii,
  sizes,
  space,
  transition,
  ...typography,
}

export default foundations
