import { SubscribeBanner } from "@components/Subscribe/SubscribeBanner"
import { NavigationFooter } from "@components/Navigation/Footer/NavigationFooter"
import { graphql, useStaticQuery } from "gatsby"
import { memo } from "react"
import { FooterAcknowledgement } from "./FooterAcknowledgement"
import { FooterCopyright } from "./FooterCopyright"
import { FooterLogoMessage } from "./FooterLogoMessage"
import { AboveDividerStack, BelowDividerStack, BgWrapper, CopyrightUtilityFlex, StyledDivider, StyledPaymentTypes } from "./FooterStyles"
import { FooterUtility } from "./FooterUtility"

import type { Location } from "@root/types/global"

type Props = {
  location: Location
}

const Footer: React.FC<Props> = ({ location }) => {
  const { organisation, footer } = useStaticQuery<GatsbyTypes.StaticLayoutQuery>(graphql`
    query StaticLayout {
      organisation: sanitySettingOrganisation {
        title
      }
      footer: sanitySettingFooter {
        isEnabledAcknowledgement
        acknowledgementText
        message
      }
    }
  `)

  return (
    <>
      <SubscribeBanner location={location} />

      <BgWrapper>
        <AboveDividerStack>
          <FooterLogoMessage message={footer?.message} />
          <NavigationFooter />
        </AboveDividerStack>
        <StyledDivider />
        <BelowDividerStack>
          <CopyrightUtilityFlex>
            <FooterCopyright title={organisation?.title} />
            <FooterUtility />
          </CopyrightUtilityFlex>
          <StyledPaymentTypes />
        </BelowDividerStack>
      </BgWrapper>

      <FooterAcknowledgement isEnabled={footer?.isEnabledAcknowledgement} text={footer?.acknowledgementText} />
    </>
  )
}

export default memo(Footer)
