import { useCallback } from "react"
import { useCore } from "@app/hooks/useCore"
import { useShopify } from "@app/hooks/useShopify"
import { useKlaviyo } from "@app/hooks/useKlaviyo"
import { useTrackingContext } from "@app/providers/tracking"
import { useCustomerContext } from "@app/providers/customer"

import type { ProductVariant } from "shopify-storefront-api-typings"
import type { CartLine } from "@shopify/hydrogen-react/storefront-api-types"
import { useCartContext } from "@app/providers/cart"

export const useAnalytics = () => {
  const { tracked, gtm, latestVersion, colourOptionName } = useTrackingContext()
  const {
    helpers: { isDev, decodeShopifyId, formatPrice, capitalise, isBrowser },
  } = useCore()
  const { cart, currencyCode } = useCartContext()
  const { customer } = useCustomerContext()
  const { track } = useKlaviyo()
  const { productNormaliser, variantNormaliser } = useShopify()

  const decorateUrl = useCallback(
    (url: string): string => {
      if (isBrowser) {
        // @ts-ignore next-line
        const ga = window[window["GoogleAnalyticsObject"]]
        // @ts-ignore next-line
        if (ga && typeof ga.getAll === "function") {
          // @ts-ignore next-line
          const tracker = ga.getAll()[0]
          // @ts-ignore next-line
          url = new window.gaplugins.Linker(tracker).decorate(url)
        }
        return url
      } else {
        return url
      }
    },
    [isBrowser]
  )

  const getVariantOptionValue = useCallback(
    (options: any, selectedName: string) =>
      options?.find(({ name }: { name: string }) => name?.toLowerCase() === selectedName?.toLowerCase())?.value,
    []
  )

  const pushDataLayer = useCallback(
    (dataLayer: any) => {
      if (gtm) {
        if (isDev) console.info({ ...dataLayer })
        if (!isDev) gtm.dataLayer({ dataLayer })
      }
    },
    [gtm, isDev]
  )

  const pushElevarDataLayer = useCallback((dataLayer: any) => {
    window.ElevarDataLayer = window.ElevarDataLayer ?? []
    window.ElevarDataLayer.push(dataLayer)
  }, [])

  const trackUserData = useCallback(() => {
    const ElevarUserData = {
      event: "dl_user_data",
      cart_total: cart?.cost?.subtotalAmount?.amount,
      user_properties: {
        customer_id: customer?.id,
        customer_email: customer?.email,
        visitor_type: customer?.id ? "logged_in" : "guest",
      },
      ecommerce: {
        currencyCode,
        cart_contents: {
          products: cart?.lines?.map(lineitem => ({
            id: decodeShopifyId(lineitem?.merchandise?.id, "ProductVariant"),
            name: lineitem?.merchandise?.product?.title || "N/A",
            brand: lineitem?.merchandise?.product?.vendor || "N/A",
            category: lineitem?.merchandise?.product?.productType || "N/A",
            variant: lineitem?.merchandise?.title || "N/A",
            price: formatPrice(lineitem?.merchandise?.priceV2?.amount),
            compare_at_price:
              formatPrice(lineitem?.merchandise?.compareAtPriceV2?.amount) === "NaN"
                ? formatPrice(lineitem?.merchandise?.priceV2?.amount)
                : formatPrice(lineitem?.merchandise?.compareAtPriceV2?.amount),
            quantity: lineitem?.quantity.toString(),
            product_id: decodeShopifyId(lineitem?.merchandise?.product?.id, "Product"),
            variant_id: decodeShopifyId(lineitem?.merchandise?.id, "ProductVariant"),
            list: "N/A", // TODO
            image: lineitem?.merchandise?.image?.originalSrc || "N/A",
          })),
        },
      },
    }
    pushElevarDataLayer(ElevarUserData)
  }, [
    cart?.cost?.subtotalAmount?.amount,
    cart?.lines,
    currencyCode,
    customer?.email,
    customer?.id,
    decodeShopifyId,
    formatPrice,
    pushElevarDataLayer,
  ])

  const trackElevarPageView = useCallback(() => {
    window.ElevarInvalidateContext?.()
    setTimeout(() => {
      trackUserData()
    }, 0)
  }, [cart, customer])

  const trackPageView = useCallback(() => {
    if (tracked) {
      setTimeout(() => {
        const dataLayer = latestVersion
          ? {
              event: "page_view",
              page_title: document?.title,
              page_path: `${document?.location?.pathname}${document?.location?.search || ""}`,
              path_location: `${document?.location?.protocol}//${document?.location?.hostname}${document?.location?.pathname}${document?.location?.search}`,
              ...(customer?.id && { customer_id: customer?.id }),
            }
          : {
              event: "Pageview",
              pagePath: `${document?.location?.pathname}${document?.location?.search ? document.location.search : ""}`,
              pageTitle: document?.title,
              originalLocation: `${document?.location?.protocol}//${document?.location?.hostname}${document?.location?.pathname}${document?.location?.search}`,
            }
        pushDataLayer(dataLayer)
      }, 400)
    }
  }, [tracked, customer, latestVersion, pushDataLayer])

  const trackProductImpression = useCallback(
    async (product, index, list = null) => {
      const { collections, id, title, vendor, priceRange, productType } = productNormaliser(product)
      if (title) {
        const dataLayer = latestVersion
          ? {
              event: "view_item_list",
              ecommerce: {
                currency: currencyCode,
                items: [
                  {
                    item_id: decodeShopifyId(id, "Product"),
                    item_name: title,
                    item_brand: vendor,
                    item_variant: getVariantOptionValue(product?.variants?.[0]?.selectedOptions, colourOptionName),
                    item_category: productType ?? collections?.[0]?.title,
                    item_list_name: list || "Collection Results", // Product Page, Collection Results, Instant Search, Search Results, Featured Products, Related Products, Cart
                    item_list_id: "", // TODO: Define lists
                    index,
                    quantity: 1,
                    price: formatPrice(priceRange?.minVariantPrice?.amount),
                  },
                ],
              },
            }
          : {
              event: "productImpression",
              ecommerce: {
                currencyCode: currencyCode,
                impressions: [
                  {
                    id: id,
                    name: title,
                    brand: vendor,
                    category: collections?.[0]?.title || productType,
                    price: priceRange?.minVariantPrice?.amount,
                    list: list || "Collection", // Collection Results, Instant Search, Search Results, Featured Products, Related Products, WishList
                    position: index,
                  },
                ],
              },
            }
        pushDataLayer(dataLayer)
      }
    },
    [currencyCode, productNormaliser, decodeShopifyId, formatPrice, latestVersion, colourOptionName, pushDataLayer, getVariantOptionValue]
  )

  const trackProductClick = useCallback(
    async (product, variant, index, list = null) => {
      const { collections, id, priceRange, title, vendor, productType } = product
      if (title) {
        const ElevarSelectItem = {
          event: "dl_select_item",
          ecommerce: {
            currencyCode: currencyCode,
            click: {
              actionField: {
                list: "N/A", // TODO
              },
              products: [
                {
                  id: decodeShopifyId(id, "Product"),
                  name: title || "N/A",
                  brand: vendor || "N/A",
                  category: productType || "N/A",
                  variant: variant?.title || "N/A",
                  price: formatPrice(variant?.priceV2?.amount),
                  compare_at_price:
                    formatPrice(variant?.compareAtPriceV2?.amount) === "NaN"
                      ? formatPrice(variant?.priceV2?.amount)
                      : formatPrice(variant?.compareAtPriceV2?.amount),
                  quantity: 1,
                  position: index,
                  list: "N/A", // TODO
                  product_id: decodeShopifyId(id, "Product"),
                  variant_id: decodeShopifyId(variant?.id, "ProductVariant"),
                  image: variant?.image?.src || product?.featuredImage?.originalSrc || "",
                  url: window.location.href,
                },
              ],
            },
          },
        }
        pushElevarDataLayer(ElevarSelectItem)
        const dataLayer = latestVersion
          ? {
              event: "select_item",
              ecommerce: {
                currency: currencyCode,
                items: [
                  {
                    item_id: decodeShopifyId(id, "Product"),
                    item_name: title,
                    item_brand: vendor,
                    item_variant: getVariantOptionValue(
                      variant?.selectedOptions || (product?.variants && product?.variants?.[0]?.selectedOptions),
                      colourOptionName
                    ),
                    item_category: productType ?? collections?.[0]?.title,
                    item_list_name: list || "Collection Results", // Product Page, Collection Results, Instant Search, Search Results, Featured Products, Related Products, Cart
                    item_list_id: "", // TODO: Define lists
                    index,
                    quantity: 1,
                    price: formatPrice(priceRange?.minVariantPrice?.amount),
                  },
                ],
              },
            }
          : {
              event: "productClick",
              ecommerce: {
                currencyCode,
                click: {
                  actionField: { list: list || "Collection" }, // Collection Results, Instant Search, Search Results, Featured Products, Related Products, WishList
                  products: [
                    {
                      id: id,
                      name: title,
                      brand: vendor,
                      category: collections?.[0]?.title || productType,
                      price: priceRange?.minVariantPrice?.amount,
                      position: index,
                      variant: getVariantOptionValue(
                        variant?.selectedOptions || (product?.variants && product?.variants?.[0]?.selectedOptions),
                        colourOptionName
                      ),
                    },
                  ],
                },
              },
            }

        pushDataLayer(dataLayer)
      }
    },
    [currencyCode, decodeShopifyId, formatPrice, pushElevarDataLayer, latestVersion, getVariantOptionValue, colourOptionName, pushDataLayer]
  )

  const trackProductView = useCallback(
    async (product, variant, parentCollection) => {
      const { collections, id, productType, title, vendor } = productNormaliser(product)
      if (title) {
        const ElevarViewItem = {
          event: "dl_view_item",
          ecommerce: {
            currencyCode,
            detail: {
              actionField: {
                list: "N/A", // TODO
              },
              products: [
                {
                  id: decodeShopifyId(id, "Product"),
                  name: title || "N/A",
                  brand: vendor || "N/A",
                  category: productType || "N/A",
                  variant: variant?.title || "N/A",
                  price: formatPrice(variant?.priceV2),
                  compare_at_price:
                    formatPrice(variant?.compareAtPriceV2) === "NaN"
                      ? formatPrice(variant?.priceV2)
                      : formatPrice(variant?.compareAtPriceV2),
                  list: "N/A", // TODO
                  product_id: decodeShopifyId(id, "Product"),
                  variant_id: decodeShopifyId(variant?.id, "ProductVariant"),
                  image: variant?.image?.src || product?.featuredImage?.originalSrc || "",
                },
              ],
            },
          },
        }
        pushElevarDataLayer(ElevarViewItem)
        const dataLayer = latestVersion
          ? {
              event: "view_item",
              ecommerce: {
                currency: currencyCode,
                items: [
                  {
                    item_id: decodeShopifyId(id, "Product"),
                    item_name: title,
                    item_brand: vendor,
                    item_variant: getVariantOptionValue(
                      variant?.selectedOptions || (product?.variants && product?.variants?.[0]?.selectedOptions),
                      colourOptionName
                    ),
                    item_category: parentCollection || productType || collections?.[0]?.title,
                    quantity: 1,
                    price: formatPrice(variant?.priceV2?.amount || (product?.variants && product?.variants?.[0]?.priceV2?.amount)),
                  },
                ],
              },
            }
          : {
              event: "productDetail",
              ecommerce: {
                currencyCode,
                detail: {
                  actionField: { list: "Product Page" }, // Product Page
                  products: [
                    {
                      id: decodeShopifyId(id, "Product"),
                      name: title,
                      brand: vendor,
                      category: parentCollection || collections?.[0]?.title || productType,
                      price: product?.variants && product?.variants[0]?.priceV2?.amount,
                      variant: getVariantOptionValue(
                        variant?.selectedOptions || (product?.variants && product?.variants?.[0]?.selectedOptions),
                        colourOptionName
                      ),
                    },
                  ],
                },
              },
            }

        pushDataLayer(dataLayer)
      }
    },
    [
      productNormaliser,
      currencyCode,
      decodeShopifyId,
      formatPrice,
      pushElevarDataLayer,
      latestVersion,
      getVariantOptionValue,
      colourOptionName,
      pushDataLayer,
    ]
  )

  const trackCartUpdate = useCallback(
    async (type, variantId, quantity, lineitems: CartLine[]) => {
      const selectedLineItem = lineitems?.filter(line => line?.merchandise?.id === variantId)[0]
      const title = selectedLineItem?.merchandise?.product?.title
      const product = productNormaliser(selectedLineItem?.merchandise?.product)
      const { id, vendor } = productNormaliser(selectedLineItem?.merchandise?.product)
      const variant = variantNormaliser(selectedLineItem?.merchandise)
      if (title) {
        const products = [
          {
            id: decodeShopifyId(id, "Product"),
            name: title || "N/A",
            brand: vendor || "N/A",
            category: selectedLineItem?.merchandise?.product?.productType || "N/A",
            variant: variant?.title || "N/A",
            price: formatPrice(variant?.priceV2?.amount),
            compare_at_price:
              formatPrice(variant?.compareAtPriceV2?.amount) === "NaN"
                ? formatPrice(variant?.priceV2?.amount)
                : formatPrice(variant?.compareAtPriceV2?.amount),
            quantity: quantity.toString(),
            list: "N/A", // TODO
            product_id: decodeShopifyId(product.id, "Product"),
            variant_id: decodeShopifyId(variant?.id, "ProductVariant"),
            image: variant?.image?.src || product?.featuredImage?.originalSrc || "",
            url: window.location.href,
          },
        ]
        const ElevarAddToCartOrRemoveFromCart = {
          event: ["add", "change"].includes(type) ? "dl_add_to_cart" : "dl_remove_from_cart", // TODO: This doesn't work, type isn't being passed in as change if removed
          ecommerce: {
            currencyCode,
            ...(["add", "change"].includes(type)
              ? {
                  add: {
                    actionField: {
                      list: "N/A", // TODO
                    },
                    products: products,
                  },
                }
              : {
                  remove: {
                    actionField: {
                      list: "N/A", // TODO
                    },
                    products: products,
                  },
                }),
          },
        }
        pushElevarDataLayer(ElevarAddToCartOrRemoveFromCart)
        const dataLayer = latestVersion
          ? {
              event: ["add", "change"].includes(type) ? "add_to_cart" : "remove_from_cart",
              ecommerce: {
                currency: currencyCode,
                items: [
                  {
                    item_id: decodeShopifyId(variantId, "ProductVariant"),
                    item_name: selectedLineItem?.merchandise?.product?.title,
                    item_brand: selectedLineItem?.merchandise?.product?.vendor,
                    item_variant: getVariantOptionValue(selectedLineItem?.merchandise?.selectedOptions, colourOptionName),
                    item_category: selectedLineItem?.merchandise?.product?.productType,
                    price: formatPrice(selectedLineItem?.merchandise?.priceV2?.amount),
                    quantity,
                  },
                ],
              },
            }
          : {
              event: ["add", "change"].includes(type) ? "addToCart" : "removeFromCart",
              ecommerce: {
                currencyCode: currencyCode,
                [type]: {
                  products: [
                    {
                      id: decodeShopifyId(variantId, "ProductVariant"),
                      name: selectedLineItem?.merchandise?.product?.title,
                      brand: selectedLineItem?.merchandise?.product?.vendor,
                      category: selectedLineItem?.merchandise?.product?.productType,
                      price: selectedLineItem?.merchandise?.priceV2?.amount,
                      quantity,
                      variant: getVariantOptionValue(selectedLineItem?.merchandise?.selectedOptions, colourOptionName),
                      dimension2: getVariantOptionValue(selectedLineItem?.merchandise?.selectedOptions, `Size`),
                      dimension3: selectedLineItem?.merchandise?.availableForSale ? `In Stock` : `Out of Stock`,
                    },
                  ],
                },
              },
            }

        console.log("pushing datalayer", dataLayer)
        pushDataLayer(dataLayer)

        const eventType = type === "add" ? "Added to Cart" : type == "change" ? "Cart Update" : "Cart Remove"

        track(eventType, {
          ...selectedLineItem,
          quantity,
          product: selectedLineItem.merchandise?.product,
        })
      }
    },
    [
      productNormaliser,
      variantNormaliser,
      decodeShopifyId,
      formatPrice,
      currencyCode,
      pushElevarDataLayer,
      latestVersion,
      getVariantOptionValue,
      colourOptionName,
      pushDataLayer,
      track,
    ]
  )

  const trackCartView = useCallback(async () => {
    if (cart?.lines?.length) {
      const ElevarViewCart = {
        event: "dl_view_cart",
        cart_total: cart?.cost?.subtotalAmount?.amount,
        ecommerce: {
          currencyCode: currencyCode,
          actionField: {
            list: "Shopping Cart",
          },
          impressions: cart?.lines?.map((lineitem, index) => ({
            id: decodeShopifyId(lineitem?.merchandise?.id, "ProductVariant"),
            name: lineitem?.merchandise?.product?.title || "N/A",
            brand: lineitem?.merchandise?.product?.vendor || "N/A",
            category: lineitem?.merchandise?.product?.productType || "N/A",
            variant: lineitem?.merchandise?.title || "N/A",
            price: formatPrice(lineitem?.merchandise?.priceV2?.amount),
            compare_at_price:
              formatPrice(lineitem?.merchandise?.compareAtPrice?.amount) === "NaN"
                ? formatPrice(lineitem?.merchandise?.priceV2?.amount)
                : formatPrice(lineitem?.merchandise?.compareAtPrice?.amount),
            quantity: lineitem?.quantity.toString(),
            product_id: decodeShopifyId(lineitem?.merchandise?.product?.id, "Product"),
            variant_id: decodeShopifyId(lineitem?.merchandise?.id, "ProductVariant"),
            position: index,
          })),
        },
      }
      pushElevarDataLayer(ElevarViewCart)
      if (latestVersion) {
        const dataLayer = {
          event: "view_cart",
          ecommerce: {
            currency: currencyCode,
            items: cart?.lines?.map(lineitem => ({
              item_id: decodeShopifyId(lineitem?.merchandise?.id, "ProductVariant"),
              item_name: lineitem?.merchandise?.product?.title,
              item_brand: lineitem?.merchandise?.product?.vendor,
              item_variant: getVariantOptionValue(lineitem?.merchandise?.selectedOptions, colourOptionName),
              item_category: lineitem?.merchandise?.product?.productType,
              price: formatPrice(lineitem?.merchandise?.priceV2?.amount),
              quantity: lineitem?.quantity,
            })),
          },
        }
        pushDataLayer(dataLayer)
      }
    }
  }, [
    cart?.lines,
    cart?.cost?.subtotalAmount?.amount,
    currencyCode,
    pushElevarDataLayer,
    latestVersion,
    decodeShopifyId,
    formatPrice,
    pushDataLayer,
    getVariantOptionValue,
    colourOptionName,
  ])

  const trackWishListUpdate = useCallback(
    async (type, product) => {
      if (product?.title) {
        if (latestVersion) {
          const dataLayer = {
            event: ["add"].includes(type) ? "add_to_shoppinglist" : "remove_from_shoppinglist",
            ecommerce: {
              currency: currencyCode,
              items: [
                {
                  item_id: decodeShopifyId(product?.selectedVariant?.id, "ProductVariant"),
                  item_name: product?.title,
                  item_brand: product?.vendor,
                  item_variant: getVariantOptionValue(product?.selectedVariant?.selectedOptions, colourOptionName),
                  item_category: product?.productType,
                  price: formatPrice(
                    product?.selectedVariant?.priceV2?.amount ?? product?.variants?.[0]?.priceV2?.amount ?? product?.variants?.[0]?.price
                  ),
                },
              ],
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, currencyCode, decodeShopifyId, getVariantOptionValue, colourOptionName, formatPrice, pushDataLayer]
  )

  const trackPromoImpression = useCallback(
    async ({ analyticsId, name, creative, position }) => {
      if (name) {
        const dataLayer = latestVersion
          ? {
              event: "view_promotion",
              ecommerce: {
                items: [
                  {
                    promotion_id: analyticsId,
                    promotion_name: name,
                    creative_name: creative,
                    creative_slot: position,
                  },
                ],
              },
            }
          : {
              event: "promotionView",
              ecommerce: {
                promoView: {
                  promotions: [{ id: analyticsId, name, creative, position }],
                },
              },
            }
        pushDataLayer(dataLayer)
      }
    },
    [latestVersion, pushDataLayer]
  )

  const trackPromoClick = useCallback(
    async ({ analyticsId, name, creative, position }) => {
      if (name) {
        const dataLayer = latestVersion
          ? {
              event: "select_promotion",
              ecommerce: {
                items: [
                  {
                    promotion_id: analyticsId,
                    promotion_name: name,
                    creative_name: creative,
                    creative_slot: position,
                  },
                ],
              },
            }
          : {
              event: "promotionClick",
              ecommerce: {
                promoClick: {
                  promotions: [{ id: analyticsId, name, creative, position }],
                },
              },
            }
        pushDataLayer(dataLayer)
      }
    },
    [latestVersion, pushDataLayer]
  )

  const trackLogin = useCallback(
    async method => {
      if (method) {
        if (latestVersion) {
          const dataLayer = {
            event: "login",
            ecommerce: {
              method: capitalise(method),
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, capitalise, pushDataLayer]
  )

  const trackSignup = useCallback(
    async method => {
      if (method) {
        if (latestVersion) {
          const dataLayer = {
            event: "sign_up",
            ecommerce: {
              method: capitalise(method),
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, capitalise, pushDataLayer]
  )

  const trackShare = useCallback(
    async (method, type, id) => {
      if (method) {
        if (latestVersion) {
          const dataLayer = {
            event: "share",
            ecommerce: {
              method: capitalise(method),
              content_type: type,
              content_id: id,
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, capitalise, pushDataLayer]
  )

  const trackClick = useCallback(
    async (type, id) => {
      if (type) {
        if (latestVersion) {
          const dataLayer = {
            event: "select_content",
            ecommerce: {
              content_type: type,
              content_id: id,
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, pushDataLayer]
  )

  const trackSearch = useCallback(
    async term => {
      if (term) {
        if (latestVersion) {
          const dataLayer = {
            event: "search",
            ecommerce: {
              search_term: term,
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, pushDataLayer]
  )

  return {
    tracked,
    trackPageView,
    trackElevarPageView,
    trackProductImpression,
    trackProductView,
    trackProductClick,
    trackCartView,
    trackCartUpdate,
    trackWishListUpdate,
    trackPromoImpression,
    trackPromoClick,
    trackLogin,
    trackSignup,
    trackShare,
    trackSearch,
    trackClick,
    decorateUrl,
  }
}
