import { space } from "./space"

export const sizes = {
  "11xl": "120rem",
  container: {
    sm: "700px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
  },
  full: "100%",
  half: "50%",
  third: "33%",
  ...space,
}
