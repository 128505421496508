import { Table, Thead, Tbody, Tr, Th, Td, TableContainer } from "@chakra-ui/react"

type CustomThProps = {
  text: string
  firstCell?: boolean
  fontSize: string
}

type CustomTableProps = {
  fontSize?: string
  locales?: any
  table?: any
  column2Title?: string
  column3Title?: string
}

const CustomTh: React.FC<CustomThProps> = ({ text, firstCell, fontSize }) => (
  <Th
    fontSize={fontSize}
    fontWeight={400}
    py={2}
    px={4}
    bg={firstCell ? "unset" : "brand.midSand"}
    border={firstCell ? "none" : "1px"}
    borderColor={firstCell ? "inherit" : "brand.avocado"}
    fontFamily="body"
    textTransform="none"
    color="brand.avocado"
    textAlign="center"
  >
    {text}
  </Th>
)

const TableRows = ({ rows, fontSize }: any) => {
  return rows?.map((row: any) => (
    <Tr key={row?._key} borderColor="brand.avocado">
      {row?.cells?.map((cell: any, cellIdx: number) => {
        // hiding more than 3 columns since it's unsupported by the design
        if (cellIdx > 2) return null

        return (
          <Td fontSize={fontSize} fontFamily="body" key={`${row?._key}-${cellIdx}`} border="1px" py={2} px={4} textAlign="center">
            {cell}
          </Td>
        )
      })}
    </Tr>
  ))
}

const CustomTable: React.FC<CustomTableProps> = ({ table, fontSize = ["sm", "md"], column2Title, column3Title }) => {
  if (!table?.rows) return null

  const showLocalesTHead = !!column2Title && !!column3Title
  const rowsCopy = [...table.rows]
  const firstRow = rowsCopy.splice(0, 1)?.[0]

  return (
    <TableContainer>
      <Table mt={3} width="calc(100% - 12px)" mx={1.5}>
        <Thead>
          <Tr>
            {showLocalesTHead && (
              <>
                <CustomTh text="" firstCell fontSize={fontSize} />
                <CustomTh text={column2Title} fontSize={fontSize} />
                <CustomTh text={column3Title} fontSize={fontSize} />
              </>
            )}
            {!showLocalesTHead && (
              <>
                {firstRow?.cells?.map((cell: any, cellIdx: number) => (
                  <CustomTh key={cellIdx} text={cell} firstCell={cellIdx === 0} fontSize={fontSize} />
                ))}
              </>
            )}
          </Tr>
        </Thead>
        <Tbody>
          <TableRows rows={showLocalesTHead ? table?.rows : rowsCopy} fontSize={fontSize} />
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export { CustomTable }
