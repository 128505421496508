const baseStyle = {
  field: {
    p: 4,
    border: "1px",
    borderColor: "brand.apricot",
    bg: "transparent",
    fontSize: "md",
    borderRadius: "base",
    _invalid: {
      borderColor: "brand.error",
    },
    _focus: {
      borderColor: "brand.avocado",
    },
    _disabled: {
      background: "brand.disabledLight",
      borderColor: "brand.disabledDark",
      color: "brand.avocado",
      opacity: 0.7,
    },
    _placeholder: {
      color: "brand.avocado",
    },
    ":autofill": {
      fontFamily: "Relative Book",
    },
    ":autofill:hover": {
      fontFamily: "Relative Book",
    },
    ":-webkit-autofill": {
      fontFamily: "Relative Book",
    },
    ":-webkit-autofill:focus": {
      fontFamily: "Relative Book",
    },
    ":-webkit-autofill::first-line": {
      fontFamily: "Relative Book",
    },
    ":-webkit-autofill:hover": {
      fontFamily: "Relative Book",
    },
    "&:-webkit-autofill:focus": {
      fontFamily: "Relative Book",
    },
    "&:-webkit-autofill:active": {
      fontFamily: "Relative Book",
    },
  },
}

const variantUnstyled = {
  field: {
    bg: "unset",
    p: 0,
    border: "none",
  },
}

const variantCorrect = {
  field: {
    borderColor: "brand.accepted",
  },
}

const variantSearch = {
  field: {
    height: [11, 11, 10],
    borderRadius: "roundedInput",
    bg: "brand.inputBg",
    pl: [4, 4, 6],
    border: "none",
    fontSize: "sm",
    _placeholder: {
      opacity: 1,
    },
  },
}

const variantGiftMessage = {
  field: {
    borderColor: "#e8cdaf",
    bg: "#fffcf7",
  },
}

const variants = {
  unstyled: variantUnstyled,
  correct: variantCorrect,
  search: variantSearch,
  giftMessage: variantGiftMessage,
}

const sizes = {}

const defaultProps = {
  // See https://github.com/chakra-ui/chakra-ui/issues/2347#issuecomment-997215492
  variant: null,
  size: null,
}

const parts = ["addon", "field", "element"]

export const Input = {
  parts,
  baseStyle,
  variants,
  sizes,
  defaultProps,
}
