import { useEffect, useState } from "react"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useAppContext } from "@app/providers/app"
import { useCustomerContext } from "@app/providers/customer"
import { useShopifyProduct } from "@app/hooks/useShopify"
import { useMaintenance } from "@app/hooks/useMaintenance"
import Global from "@app/theme/foundations/global"

import type { Location } from "@root/types/global"
import { useCartContext } from "@app/providers/cart"

export const withLayout =
  (Component: React.FC<{ data: any; location: Location }>) =>
  ({ name = "Layout", location, children, data }: any) => {
    const { active, authorised } = useMaintenance(location)
    const { selectProduct } = useShopifyProduct()
    const { tracked, trackPageView, trackElevarPageView, trackCartView } = useAnalytics()
    const { dispatch } = useAppContext()

    useEffect(() => {
      dispatch({ type: "initial" })
    }, [location?.pathname, dispatch])

    const { id: cartId } = useCartContext()
    const { customer } = useCustomerContext()
    const [locationPathname, setLocationPathname] = useState("")

    useEffect(() => {
      if (cartId && (customer?.id || customer === false) && locationPathname !== location?.pathname) {
        trackElevarPageView()
        if (location?.pathname === "/cart") {
          setTimeout(() => {
            trackCartView()
          }, 0)
        }
        setLocationPathname(location?.pathname)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.pathname, cartId, customer])

    useEffect(() => {
      trackPageView()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.pathname, tracked])

    useEffect(() => {
      selectProduct(data?.product, location?.pathname)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.pathname, data?.product])

    useEffect(() => {
      if (typeof window !== "undefined" && window.renderBadge) window.renderBadge()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    Component.displayName = name
    return active ? (
      <>
        <Global />
        {children}
      </>
    ) : (
      authorised && (
        <Component data={data} location={location}>
          {children}
        </Component>
      )
    )
  }
