import { fontSizes } from "./font-sizes"

// See for explanation https://github.com/necolas/normalize.css/issues/665
const systemFallbackFonts = `-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif`

// Usage: https://chakra-ui.com/docs/styled-system/theming/theme#typography
export const typography = {
  fonts: {
    heading: `'Brick Display Pro Black', Arial, ${systemFallbackFonts}`,
    body: `'Relative Book', Arial, ${systemFallbackFonts}`,
  },
  lineHeights: {
    normal: "normal",
    none: "1",
    overline: "150%",
    body: "30px",
  },
  fontSizes,
}
