import { useState, useEffect, useCallback, useMemo, useContext, createContext } from "react"
import { useQuery } from "@apollo/client"
import { useCore, useStorage } from "@app/hooks/useCore"
import { useConfigContext } from "@app/providers/config"
import { useLocationContext } from "@app/providers/location"

type ContextProps = {
  customer: any
  getCustomer: () => void
  setCustomer: (customer: any) => void
  clearCustomer: () => void
  saveCustomer: (customerAccessToken: any) => void
  customerLoading: boolean
}

export const CustomerContext = createContext<ContextProps | undefined>(undefined)

export const CustomerProvider: React.FC = ({ children }) => {
  const {
    graphql: {
      queries: { GET_CUSTOMER },
    },
  } = useCore()
  const { getStorage, setStorage, removeStorage } = useStorage()
  const {
    settings: { keys },
  } = useConfigContext()
  const [customer, setCustomer] = useState(false)
  const [customerLoading, setCustomerLoading] = useState(true)
  // const { logoutLoyaltyLionCustomer } = useRewardsContext()

  const { refetch: getCustomerQuery } = useQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    skip: true,
  })

  const { store } = useLocationContext()

  const token = useMemo(() => getStorage(keys.customer), [getStorage, keys])

  const getCustomer = useCallback(
    async (accessToken?: string) => {
      if (token?.accessToken || accessToken) {
        try {
          const {
            data: { customer, customerUserErrors },
          } = await getCustomerQuery({
            customerAccessToken: token?.accessToken || accessToken,
            countryCode: store?.locationRegion,
          })

          if (!customerUserErrors?.length) setCustomer(customer)
          if (customerUserErrors?.length) removeStorage(keys.customer)
        } catch (e) {
          console.error((e as Error).message)
        }
      }
      setCustomerLoading(false)
    },
    [getCustomerQuery, setCustomer, removeStorage, keys, token, store]
  )

  useEffect(() => {
    getCustomer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const saveCustomer = useCallback(
    customerAccessToken => {
      try {
        const { accessToken, expiresAt } = customerAccessToken
        setStorage(keys.customer, { accessToken, expiresAt })
        getCustomer(accessToken)
      } catch (e) {
        console.error((e as Error).message)
      }
    },
    [getCustomer, setStorage, keys]
  )

  const clearCustomer = useCallback(() => {
    try {
      removeStorage(keys.customer)
      removeStorage(keys.customerPortalUrl)
      setCustomer(false)

      // TODO: fix the below. currently failing since the customer provider is above the rewards provider
      // logoutLoyaltyLionCustomer()
    } catch (e) {
      console.error((e as Error).message)
    }
  }, [setCustomer, removeStorage, keys])

  const contextValue = useMemo<ContextProps>(
    () => ({
      customer,
      getCustomer,
      setCustomer,
      saveCustomer,
      clearCustomer,
      customerLoading,
    }),
    [customer, getCustomer, saveCustomer, clearCustomer, customerLoading]
  )

  return <CustomerContext.Provider value={contextValue}>{children}</CustomerContext.Provider>
}

export const useCustomerContext = (): ContextProps => ({ ...useContext(CustomerContext) } as ContextProps)
