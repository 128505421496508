const baseStyle = {
  transitionProperty: "common",
  transitionDuration: "fast",
  transitionTimingFunction: "ease-out",
  cursor: "pointer",
  outline: "none",
  color: "inherit",
  _focusVisible: {
    boxShadow: "outline",
  },
}

const variants = {
  underline: {
    textDecoration: "underline",
    _hover: {
      textDecoration: "none",
    },
  },
  "no-underline": {
    textDecoration: "none",
    _hover: {
      textDecoration: "underline",
    },
  },
  unstyled: {
    textDecoration: "none",
    _hover: {
      textDecoration: "none",
    },
  },
}

const sizes = {}

const defaultProps = {
  variant: "unstyled",
}

export const Link = {
  baseStyle,
  variants,
  sizes,
  defaultProps,
}
