import { useMemo, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useRoutes } from "@app/hooks/useRoutes"
import { usePromotions } from "@app/hooks/usePromotions"

// Usage: this hook retrieves menus set in Sanity under the `Navigation` tab
// by handle. eg `const { account } = useNavigation()` to get the account menu

export const useNavigation = () => {
  const { urlResolver } = useRoutes()
  const { menu } = usePromotions()

  const { menus } = useStaticQuery<GatsbyTypes.StaticMenuQuery>(graphql`
    query StaticMenu {
      menus: sanitySettingMenus {
        account {
          top {
            ...SanityNavItemFragment
          }
          middle {
            ...SanityNavItemFragment
          }
          bottom {
            ...SanityNavItemFragment
          }
        }
        header {
          items: _rawItems(resolveReferences: { maxDepth: 7 })
          handle {
            current
          }
        }
        headerMobile {
          items: _rawItems(resolveReferences: { maxDepth: 8 })
          handle {
            current
          }
        }
        headerExtraLinks {
          title
          _key
          _type
          document {
            ...SanityInternalFragment
          }
        }
        footer {
          ...SanityNavItemFragment
        }
        utility {
          ...SanityNavItemFragment
        }
      }
    }
    fragment SanityInternalFragment on SanityArticleOrArticleCategoryOrCollectionOrPageAccountAddressesOrPageAccountChangeOrPageAccountDashboardOrPageAccountForgotOrPageAccountLoginAndRegisterOrPageAccountOrdersOrPageAccountRewardsOrPageAccountWishListOrPageArticlesOrPageBrandsOrPageCartOrPageCollectionsOrPageContactOrPageDownloadsOrPageFAQsOrPageFlexibleOrPageGenericOrPageHomepageOrPageNutritionistsOrPageReviewsOrPageSearchOrPageStoresOrProductOrStore {
      ...SanityInternalItems
    }
    fragment SanityNavItemFragment on SanityNavigation {
      _id
      _type
      title
      _key
      id
      handle {
        current
      }
      titleLink {
        title
        _key
      }
      items {
        ...SanityNavItemsWithSubFragment
      }
    }
    fragment SanityInternalItems on SanityArticleOrArticleCategoryOrCollectionOrPageAccountAddressesOrPageAccountChangeOrPageAccountDashboardOrPageAccountForgotOrPageAccountLoginAndRegisterOrPageAccountOrdersOrPageAccountRewardsOrPageAccountWishListOrPageArticlesOrPageBrandsOrPageCartOrPageCollectionsOrPageContactOrPageDownloadsOrPageFAQsOrPageFlexibleOrPageGenericOrPageHomepageOrPageNutritionistsOrPageReviewsOrPageSearchOrPageStoresOrProductOrStore {
      ... on SanityPageAccountAddresses {
        id
        title
        url
        _key
        _type
      }
      ... on SanityPageAccountChange {
        id
        title
        url
        _key
        _type
      }
      ... on SanityPageAccountDashboard {
        id
        title
        url
        _key
        _type
      }
      ... on SanityPageAccountForgot {
        id
        title
        url
        _key
        _type
      }
      ... on SanityPageAccountLoginAndRegister {
        id
        titleLogin
        titleRegister
        url
        _key
        _type
      }
      ... on SanityPageAccountOrders {
        id
        title
        url
        _key
        _type
      }
      ... on SanityPageAccountRewards {
        id
        title
        url
        _key
        _type
      }
      ... on SanityPageAccountWishList {
        id
        title
        url
        _key
        _type
      }
      ... on SanityPageArticles {
        id
        title
        url
        _key
        _type
      }
      ... on SanityPageBrands {
        id
        title
        url
        _key
        _type
      }
      ... on SanityPageCart {
        id
        title
        url
        _key
        _type
      }
      ... on SanityPageCollections {
        id
        title
        url
        _key
        _type
      }
      ... on SanityPageContact {
        id
        title
        url
        _key
        _type
      }
      ... on SanityPageDownloads {
        id
        title
        url
        _key
        _type
      }
      ... on SanityPageFAQs {
        id
        title
        url
        _key
        _type
      }
      ... on SanityPageFlexible {
        id
        title
        url
        _key
        _type
        handle {
          current
        }
      }
      ... on SanityPageGeneric {
        id
        title
        url
        _key
        _type
        handle {
          current
        }
      }
      ... on SanityPageHomepage {
        id
        title
        url
        _key
        _type
        handle {
          current
        }
      }
      ... on SanityPageNutritionists {
        id
        title
        url
        _key
        _type
      }
      ... on SanityPageReviews {
        id
        title
        url
        _key
        _type
      }
      ... on SanityPageSearch {
        id
        title
        url
        _key
        _type
      }
      ... on SanityPageStores {
        id
        title
        url
        _key
        _type
      }
    }
    fragment SanityNavItemsWithSubFragment on SanityLinkOrNavigationArticleOrNavigationBrandOrNavigationCollectionOrNavigationPageOrNavigationProductOrNavigationStoreOrNavigationSubOrNavigationTile {
      ...SanityNavItemsWithoutSubFragment
      ... on SanityNavigationSub {
        _id
        _key
        _type
        title
        items {
          ...SanityNavItemsWithoutSubFragment
        }
      }
    }
    fragment SanityNavItemsWithoutSubFragment on SanityLinkOrNavigationArticleOrNavigationBrandOrNavigationCollectionOrNavigationPageOrNavigationProductOrNavigationStoreOrNavigationSubOrNavigationTile {
      ... on SanityLink {
        _key
        _type
        link
        title
        external
      }
      ... on SanityNavigationArticle {
        _key
        _type
        title
        article {
          title
          url
          id
          _key
          handle {
            current
          }
        }
      }
      ... on SanityNavigationBrand {
        _key
        _type
        title
        brand {
          url
          title
          shopify {
            handle
          }
        }
      }
      ... on SanityNavigationCollection {
        _key
        _type
        title
        collection {
          id
          url
          _type
          title
          shopify {
            handle
          }
        }
      }
      ... on SanityNavigationProduct {
        _key
        _type
        title
        product {
          _id
          _key
          title
          url
          _type
          shopify {
            handle
          }
        }
      }
      ... on SanityNavigationStore {
        _key
        _type
        title
        _type
        store {
          title
          url
          id
          _key
          _type
        }
      }
      ... on SanityNavigationPage {
        _key
        _type
        title
        page {
          ... on SanityPageAccountAddresses {
            id
            title
            url
            _key
            _type
          }
          ... on SanityPageAccountChange {
            id
            title
            url
            _key
            _type
          }
          ... on SanityPageAccountDashboard {
            id
            title
            url
            _key
            _type
          }
          ... on SanityPageAccountForgot {
            id
            title
            url
            _key
            _type
          }
          ... on SanityPageAccountLoginAndRegister {
            id
            titleLogin
            titleRegister
            url
            _key
            _type
          }
          ... on SanityPageAccountOrders {
            id
            title
            url
            _key
            _type
          }
          ... on SanityPageAccountReset {
            id
            title
            url
            _key
            _type
          }
          ... on SanityPageAccountRewards {
            id
            title
            url
            _key
            _type
          }
          ... on SanityPageAccountWishList {
            id
            title
            url
            _key
            _type
          }
          ... on SanityPageArticles {
            id
            title
            url
            _key
            _type
          }
          ... on SanityPageBrands {
            id
            title
            url
            _key
            _type
          }
          ... on SanityPageCart {
            id
            title
            url
            _key
            _type
          }
          ... on SanityPageCollections {
            id
            title
            url
            _key
            _type
          }
          ... on SanityPageContact {
            id
            title
            url
            _key
            _type
          }
          ... on SanityPageDownloads {
            id
            title
            url
            _key
            _type
          }
          ... on SanityPageFAQs {
            id
            title
            url
            _key
            _type
          }
          ... on SanityPageFlexible {
            id
            title
            url
            _key
            _type
            handle {
              current
            }
          }
          ... on SanityPageGeneric {
            id
            title
            url
            _key
            _type
            handle {
              current
            }
          }
          ... on SanityPageHomepage {
            id
            title
            url
            _key
            _type
            handle {
              current
            }
          }
          ... on SanityPageNutritionists {
            id
            title
            url
            _key
            _type
          }
          ... on SanityPageReviews {
            id
            title
            url
            _key
            _type
          }
          ... on SanityPageSearch {
            id
            title
            url
            _key
            _type
          }
          ... on SanityPageStores {
            id
            title
            url
            _key
            _type
          }
        }
      }
    }
  `)

  const buildItems: any = useCallback(
    (items: any) =>
      items?.map((link: any) => {
        const type = link?._type?.replace("navigation", "").toLowerCase()
        const internal = ["link", "sub"].includes(type)

        const item = !internal ? link[type] || link.link : false
        const items = buildItems(link?.items) || false
        const resolvedUrl = urlResolver(item)
        const image = link?.image || resolvedUrl?.image
        const title = link?.title || resolvedUrl?.title
        const url = !internal ? resolvedUrl?.url : link.link || false
        const external = link?.external || false
        const _key = link?._key
        const extraBrand = link?.brand
          ? {
              title: link?.title || resolvedUrl?.title,
              brand: link?.brand,
              brandLogo: link?.brandLogo,
            }
          : {}

        return {
          type,
          external,
          url: typeof url !== "string" ? urlResolver(url)?.url : url,
          image: image?.asset?.url || image?.url || image,
          hideTitle: link?.hideTitle || false,
          _key,
          title,
          ...(item && { item }),
          ...(items && { items }),
          ...extraBrand,
        }
      }) || [],
    [urlResolver]
  )

  const buildAccountItem: any = useCallback(
    (accountItem: any) => ({
      title: accountItem?.title,
      items: buildItems(accountItem?.items),
      titleLink: accountItem?.titleLink ? urlResolver(accountItem?.titleLink) : null,
    }),
    [buildItems, urlResolver]
  )

  const header = useMemo(() => {
    if (menu === "default") {
      return buildItems(menus?.header?.items)
    }

    if (menus) {
      const matchingKey = Object.keys(menus)?.find(key => key === menu)
      const matchingMenu: any = menus?.[matchingKey as keyof typeof menus]

      if (matchingMenu?.items) return buildItems(matchingMenu?.items)
    }

    return []
  }, [menu, menus, buildItems])

  const headerMobile = useMemo(() => buildItems(menus?.headerMobile?.items), [menus, buildItems])

  const headerExtraLinks = useMemo(() => menus?.headerExtraLinks?.map(link => urlResolver(link)), [menus, urlResolver])

  const footer = useMemo(() => buildItems(menus?.footer?.items), [menus, buildItems])

  const account = useMemo(
    () => ({
      top: buildAccountItem(menus?.account?.top),
      middle: buildAccountItem(menus?.account?.middle),
      bottom: buildAccountItem(menus?.account?.bottom),
    }),
    [menus, buildAccountItem]
  )

  const utility = useMemo(() => buildItems(menus?.utility?.items), [menus, buildItems])

  return { header, headerMobile, headerExtraLinks, footer, account, utility }
}
