import { useState, useCallback, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useMutation, useApolloClient } from "@apollo/client"

import { useShopify } from "@app/hooks/useShopify"
import { useAnalytics } from "@app/hooks/useAnalytics"

import { useCartContext } from "@app/providers/cart"
import { useQueries } from "./useQueries"
import type { Attribute, Cart, CartUserError } from "@shopify/hydrogen-react/storefront-api-types"
import { AttributeProps } from "@root/types/global"

const useCart = () => {
  const client = useApolloClient()
  const {
    graphql: {
      queries: { GET_SELLING_PLAN_ALLOCATIONS },
      mutations: { CART_LINE_ADD, CART_LINE_UPDATE, CART_LINE_REMOVE, CART_ATTRIBUTES_UPDATE, CART_GIFTCARDS_APPEND, CART_NOTE_UPDATE },
    },
  } = useQueries()
  const { cart, id: cartId, countryCode, saveCart, loading: mutationLoading, refreshCartStorage } = useCartContext()
  const { formatMoney, cartNormaliser } = useShopify()
  const { trackCartUpdate } = useAnalytics()

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Array<CartUserError>>([])
  const [cartLinesAdd] = useMutation(CART_LINE_ADD)
  const [cartLinesUpdate] = useMutation(CART_LINE_UPDATE)
  const [cartLinesRemove] = useMutation(CART_LINE_REMOVE)
  const [cartAttributesUpdate] = useMutation(CART_ATTRIBUTES_UPDATE)
  const [cartGiftcardAppend] = useMutation(CART_GIFTCARDS_APPEND)
  const [cartNoteUpdate] = useMutation(CART_NOTE_UPDATE)

  const saveCartOrSetErrors = useCallback(
    (cart: Cart, userErrors: CartUserError[], successCallback?: VoidFunction) => {
      if (userErrors?.length) {
        console.error(userErrors)
        setErrors(userErrors)
      } else if (cart) {
        saveCart(cart)
        const normalisedCart = cartNormaliser(cart)
        refreshCartStorage(normalisedCart)
        successCallback?.()
      } else {
        console.error("No cart returned.")
      }
      setLoading(false)
    },
    [cartNormaliser, refreshCartStorage, saveCart]
  )

  const prepareAttributes = useCallback(
    (attributes: Array<AttributeProps>) =>
      attributes?.map(({ key, value }) => ({
        key,
        value,
      })) || [],
    []
  )

  // TODO: test this
  const applyGiftCardCode = useCallback(
    async (giftCardCode: string) => {
      setLoading(true)
      setErrors([])
      const {
        data: {
          cartGiftcardAppend: { cart, userErrors },
        },
      } = await cartGiftcardAppend({
        variables: { cartId, giftCardCodes: [giftCardCode], countryCode },
      })
      saveCartOrSetErrors(cart, userErrors)
    },
    [cartGiftcardAppend, cartId, countryCode, saveCartOrSetErrors]
  )

  const updateCartNote = useCallback(
    async (note: string) => {
      const {
        data: {
          cartNoteUpdate: { cart, userErrors },
        },
      } = await cartNoteUpdate({
        variables: { cartId, note, countryCode },
      })
      saveCartOrSetErrors(cart, userErrors)
    },
    [cartId, cartNoteUpdate, countryCode, saveCartOrSetErrors]
  )

  const updateAttributes = useCallback(
    async (attributes: Attribute[]) => {
      const {
        data: {
          cartAttributesUpdate: { cart, userErrors },
        },
      } = await cartAttributesUpdate({
        variables: { cartId, attributes, countryCode },
      })
      saveCartOrSetErrors(cart, userErrors)
    },
    [cartAttributesUpdate, cartId, countryCode, saveCartOrSetErrors]
  )

  const addToCart = useCallback(
    async ({
      productId,
      variantId,
      quantity = 1,
      attributes = [],
      newCart = "",
    }: {
      productId: string
      variantId: string
      quantity?: number
      attributes?: any[]
      newCart?: any
    }) => {
      if (!cartId) {
        console.error("Cart ID not found")
        return
      }
      setLoading(true)

      // Remove empty attributes if any, this causes an error in the cart
      attributes = attributes?.filter(({ value }) => !!value)

      try {
        const { data } = await client.query({
          query: GET_SELLING_PLAN_ALLOCATIONS,
          variables: { productId },
        })

        let sellingPlanId = data?.product?.variants?.edges?.find(({ node }: { node: any }) => node.id === variantId)?.node
          ?.sellingPlanAllocations?.edges?.[0]?.node?.sellingPlan?.id

        const subscription = attributes.find(({ key }) => key === "_oneTimeOrSubscription")
        if (subscription && subscription.value === "one-time") {
          sellingPlanId = null
        }

        const lines =
          {
            attributes: [...(prepareAttributes(attributes) || []), ...(attributes || [])],
            quantity: quantity,
            merchandiseId: variantId,
            ...(sellingPlanId && { sellingPlanId }),
          } || []

        const {
          data: { cartLinesAdd: cartData },
        } = await cartLinesAdd({
          variables: {
            cartId: newCart.length ? newCart : cartId,
            lines,
          },
        })

        saveCartOrSetErrors(cartData?.cart, cartData?.userErrors, () => {
          trackCartUpdate("add", variantId, quantity, cartNormaliser(data.cart)?.lines)
        })

        setLoading(false)

        return
      } catch (error) {
        console.error(error)
      }
    },
    [cartId, prepareAttributes, cartLinesAdd, saveCartOrSetErrors, cartNormaliser, trackCartUpdate]
  )

  const addToCartMultiple = useCallback(
    async items => {
      if (!cartId) {
        console.error("Cart ID not found")
        return
      }
      setLoading(true)

      const lines =
        items?.map(line => ({
          attributes: (line?.attributes as Attribute[])
            ?.filter(({ value }) => !!value)
            ?.map(({ key, value }) => ({
              key,
              value,
            })),
          quantity: line?.quantity,
          merchandiseId: line?.variantId,
        })) || []

      const {
        data: { cartLinesAdd: data },
      } = await cartLinesAdd({
        variables: {
          countryCode,
          cartId,
          lines,
        },
      })

      saveCartOrSetErrors(data?.cart, data?.userErrors, () => {
        for (const item of items) {
          trackCartUpdate("add", item.variantId, item.quantity, cartNormaliser(data?.cart)?.lines)
        }
      })

      setLoading(false)

      return
    },
    [cartId, cartLinesAdd, countryCode, saveCartOrSetErrors, cartNormaliser, trackCartUpdate]
  )

  const removeFromCart = useCallback(
    async (id, variantId) => {
      if (!cartId || !cart) {
        console.error("Cart ID not found")
        return
      }
      setLoading(true)

      const quantity = cart?.lines.filter(line => line.id === id).map(({ quantity }) => quantity)[0] || 1
      const lineIds = cart?.lines.filter(line => line.id === id).map(line => line.id)

      const {
        data: { cartLinesRemove: data },
      } = await cartLinesRemove({
        variables: { cartId, lineIds },
      })

      saveCartOrSetErrors(data?.cart, data?.userErrors, () => {
        trackCartUpdate("remove", variantId, quantity, cart?.lines)
      })
    },
    [cartId, cart, cartLinesRemove, saveCartOrSetErrors, trackCartUpdate]
  )

  const updateQuantity = useCallback(
    async (id, variantId, quantity, action = "add") => {
      if (!cartId || !cart) {
        console.error("Cart ID not found")
        return
      }
      setLoading(true)
      const lines = cart?.lines
        .filter(line => line.id === id)
        .map(line => ({
          id: line.id,
          quantity: quantity,
          merchandiseId: line?.merchandise?.id,
        }))

      const {
        data: { cartLinesUpdate: data },
      } = await cartLinesUpdate({
        variables: { cartId, lines },
      })

      saveCartOrSetErrors(data?.cart, data?.userErrors, () => {
        trackCartUpdate(action, variantId, quantity, cartNormaliser(data?.cart)?.lines)
      })
    },
    [cartId, cart, cartLinesUpdate, saveCartOrSetErrors, trackCartUpdate, cartNormaliser]
  )

  const updateItem = useCallback(
    async (id, _variantId, quantity: number, attributes: Attribute[], sellingPlanId?: string) => {
      if (!cartId || !cart) {
        console.error("Cart ID not found")
        return
      }
      setLoading(true)
      const lines = cart?.lines
        .filter(line => line.id === id)
        .map(line => ({
          id: line.id,
          quantity,
          attributes,
          merchandiseId: line?.merchandise?.id,
          ...(sellingPlanId && { sellingPlanId }),
        }))

      const {
        data: { cartLinesUpdate: data },
      } = await cartLinesUpdate({
        variables: { cartId, lines },
      })

      saveCartOrSetErrors(data?.cart, data?.userErrors)
    },
    [cartId, cart, cartLinesUpdate, saveCartOrSetErrors]
  )

  const { shipping } = useStaticQuery<GatsbyTypes.StaticShippingQuery>(graphql`
    query StaticShipping {
      shipping: sanitySettingShipping {
        threshold
        includeDiscounts
        messageBefore
        messageAfter
      }
    }
  `)

  const freeShipping = useMemo(() => {
    const threshold = shipping?.threshold ?? 100
    const includeDiscounts = shipping?.includeDiscounts ?? true
    const total = Number(includeDiscounts ? cart?.cost?.subtotalAmount?.amount : cart?.cost?.subtotalAmount?.amount)
    const percentage = total > threshold ? 100 : (total / threshold) * 100
    const remaining = total > threshold ? 0 : threshold - total
    const isFreeShipping = percentage === 100
    const rawMessage = isFreeShipping ? shipping?.messageAfter : shipping?.messageBefore

    // replaces {threshold} susbtring with actual threshhold value
    // replaces {remaining} substring with actual remaining value
    // replaces [substring] with bolded `substring`
    const message = rawMessage
      ?.replace("{threshold}", formatMoney(threshold))
      ?.replace("{remaining}", formatMoney(remaining))
      ?.replace(/\[(.+)\]/, (i, match) => `<span style="font-weight: 700">${match}</span>`)

    return { threshold, percentage, message, isFreeShipping }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipping, cart, cart?.cost?.subtotalAmount?.amount, formatMoney])

  return {
    addToCart,
    addToCartMultiple,
    removeFromCart,
    updateQuantity,
    updateItem,
    freeShipping,
    loading,
    errors,
    mutationLoading,
    updateAttributes,
    applyGiftCardCode,
    updateCartNote,
  }
}

export { useCart }
