module.exports = {
  id: "goodnessme-box-staging",
  title: "GoodnessMe Box (Staging)",
  description: "Shop healthy snacks and monthly boxes at GoodnessMe.",
  url: "goodnessmestaging.gatsbyjs.io",

  locationRegion: "AU",
  locationCountries: "AU",
  locationForceRegion: true,
  locationLookupUrl: "https://ipapi.co/json/",

  shopifyPlus: true,
  shopifyApiVersion: "2024-07",
  shopifyShopDomain: "goodnessme-box-staging.myshopify.com",
  shopifyCheckoutUrl: "goodnessme-box-staging.myshopify.com",
  shopifyStorefrontToken: "084277929971fb193240419e07a81514",
  shopifyAdminToken: "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",

  shopifyApiKey: "487fae2fe413bcd52dcddf4d05706880",
  shopifyApiSecret: "6c05768454f9d4005c51f4f3762bef34",

  stampedStoreUrl: "goodnessme-box.myshopify.com",
  stampedApiKey: "pubkey-CG99P3NwN8SM614FoVXYB2rVB8k3Y1",

  sanityProjectId: "h6pfw6o3",
  sanityDataset: "staging",
  sanityApiVersion: "2022-02-24",
  sanityToken:
    "skIava9ZtpowyCnHtyqT9B3VhokOjBj9vOlvM16ex8zGiyXSemzcWZ7zwZJhaHFFXqpRDrAftAlnqafR3fIVutYMaG3IY4m3pct8geCrU4UbVvjs45Vwiyfe5vt9ekvXcWFndWKU3FY63qfjcfbemHym1mz2qopl632X0f1JgFngGuTq5MbI",

  searchIndex: "goodnessme-staging-search",

  googleAuthClientId: "....apps.googleusercontent.com",
  googleMapsApiKey: "",

  klaviyoApiKey: "QUHrKg",

  emailSender: "hello@goodnessmebox.com",

  facebookAppId: "...",

  rechargeBaseApiUrl: "https://api.rechargeapps.com",
  rechargeApiVersion: "2021-01",

  loyaltyLionBaseApiUrl: "https://api.loyaltylion.com/v2",
  loyaltyLionApiToken: "11180e01ffc86c52204e26fa5b9c23ed",

  gorgiasHelpChatAppId: "21754",
  siteCurrencyCode: "AUD",
  TEMP_CONTROL_POUCH_ID: "43252216725743",
}
