// Used as a template to create other overrides
// Default chakra components https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme/src/components

import { Input } from "./input"

const baseStyle = {
  ...Input.baseStyle.field,
  resize: "none",
  _focus: {
    borderColor: "brand.avocado",
  },
}

const variants = {}

const sizes = {}

const defaultProps = {
  variant: null,
  size: null,
}

export const Textarea = {
  baseStyle,
  variants,
  sizes,
  defaultProps,
}
