import { memo } from "react"
import { Box } from "@chakra-ui/react"

// Purpose: provides bespoke custom styling to allow the swiper carousel to only overflow the container on the right side, as per the designs

// IMPORTANT: If you use the CarouselverflowWrapper component, make sure to spread the props below onto the outer parent
const extraOverflowParentProps = {
  overflowX: "hidden",

  // needed to correctly position the carousel arrows
  position: "relative",
}

type CarouselOverflowWrapperProps = {
  children: React.ReactNode
  type?: string
}

// Used only for this instance of swiper
const getSwiperStyles = (type = "product") => {
  const isArticles = type === "articles"
  const isArticle = type === "article"
  const isSamples = type === "samples"

  const slideWidths = {
    product: ["335px !important", "210px !important", "210px !important"],
    articles: ["320px !important", "320px !important", "320px !important"],
    article: ["160px !important", "160px !important", "362px !important"],
    boxCallout: ["270px !important"],
    samples: ["auto !important", "auto !important", "auto !important"],
  }

  const hideArrowsOnMobileStyles = {
    "@media (min-width: 600px)": {
      ".swiper-button-next, .swiper-button-prev": {
        display: "none",
      },
    },
  }
  const test = {
    "@media (max-width: 600px)": {
      ".swiper-slide": {
        marginLeft: "0",
      },
    },
  }

  const hideOverflowInMainCart = {
    ".swiper": {
      overflow: "hidden !important",
    },
    ".swiper-slide": {
      flex: ["0 0 calc(75% - 12px)", "0 0 calc(33.4% - 12px)", "0 0 calc(25% - 12px)"],
    },
  }
  return {
    ".swiper": { overflow: "visible !important", position: "unset !important" },
    ".swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled": {
      display: "none !important",
    },
    ".swiper-button-next": { right: "20px !important" },
    ".swiper-button-prev": { left: "20px !important" },
    ".swiper-slide": { width: slideWidths[type as keyof typeof slideWidths], marginLeft: 2, marginRight: 2 },
    ...test,
    ...((isArticles || isArticle) && hideArrowsOnMobileStyles),
    ...(isSamples && hideOverflowInMainCart),
  }
}

// TODO: type this
// Pass Swiper as a react child
const CarouselOverflowWrapper: React.FC<CarouselOverflowWrapperProps> = ({ children, type = "product" }) => {
  const swiperStyles = getSwiperStyles(type) || {}

  return <Box sx={swiperStyles}>{children}</Box>
}

const MemoCarouselOverflowWrapper = memo(CarouselOverflowWrapper)
export { MemoCarouselOverflowWrapper as CarouselOverflowWrapper, extraOverflowParentProps }
