import { useCallback, useEffect, useState, useMemo } from "react"
import { useConfigContext } from "@app/providers/config"

const STAMPED_TYPES = {
  REVIEWS: "reviews",
  QUESTIONS: "questions",
} as const

type Props = {
  productId: string | null | undefined
  pageSize?: number
  currentPage?: number
  sortBy?: SortOptions
  type: "reviews" | "questions"
}

type Return = {
  size: number
  page: number
  pages: number
  reviews: any
  questions: any
  voted: string[]
  loading: boolean
  sort: SortOptions
  avatarColours: string[]
  sortOptions: {
    label: string
    value: string
  }[]
  getItems: () => any
  changePageSize: (value: number) => any
  changeSort: (value: SortOptions) => any
  changePage: (props: ChangePageProps) => any
  submitVote: (id: string, value: number) => any
  submitVoteWithProductId: (id: string, productId: string, value: number) => any
  createReview: (author: string, email: string, title: string, message: string, rating: number, recommended: boolean) => any
  recommendedCount: number
}

type ChangePageProps = {
  next?: boolean
  prev?: boolean
  pageNumber?: number
}

type SortOptions = "featured" | "highest-rating" | "lowest-rating" | "most-votes"

const sortOptions = [
  { label: "Featured", value: "featured" },
  { label: "Highest Rating", value: "highest-rating" },
  { label: "Lowest Rating", value: "lowest-rating" },
  { label: "Most Votes", value: "most-votes" },
]

const avatarColours = ["brand.peachy", "brand.lavender", "brand.rain", "brand.yellow", "brand.disabledLight", "brand.apricot"]

const useStamped = ({ productId, pageSize = 5, currentPage = 1, sortBy = "featured", type = "reviews" }: Props): Return => {
  const [sort, setSort] = useState<SortOptions>(sortBy)
  const [page, setPage] = useState<number>(currentPage)
  const [size, setPageSize] = useState<number>(pageSize)
  const [pages, setPages] = useState<number>(0)
  const [reviews, setReviews] = useState<any>()
  const [questions, setQuestions] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [voted, setVoted] = useState<string[]>([])

  const {
    store: { stampedStoreUrl, stampedApiKey },
  } = useConfigContext()

  const getHtml = (html: any, className: string) => html.getElementsByClassName(className)?.[0]?.innerHTML
  const getText = (html: any, className: string) => html.getElementsByClassName(className)?.[0]?.textContent
  const getAttribute = (html: any, className: string, attribute: string) =>
    html.getElementsByClassName(className)?.[0]?.getAttribute(attribute)
  const cleanHtml = (html: any, replace?: string) =>
    html
      ?.replaceAll("\n", "")
      ?.replaceAll("&nbsp;", "")
      ?.replaceAll(replace || "", "")
      ?.trim()

  const getReviews = useCallback(async () => {
    const response = await fetch(
      `https://stamped.io/api/widget/reviews?take=${size}&page=${page}&sortReviews=${sort}${
        productId ? `&productId=${productId}` : ""
      }&storeUrl=${stampedStoreUrl}&apiKey=${stampedApiKey}`
    )
    const body = await response.json()
    setReviews(body)
    setPages(Math.round(body.total / size))
  }, [size, page, sort, productId, stampedStoreUrl, stampedApiKey])

  const getQuestions = useCallback(async () => {
    const response = await fetch(
      `https://stamped.io/api/widget/questions?take=${size}&page=${page}&sortReviews=${sort}${
        productId ? `&productId=${productId}` : ""
      }&storeUrl=${stampedStoreUrl}&apiKey=${stampedApiKey}`
    )
    const body = await response.json()
    if (body.result) {
      const results = []
      const html: any = new DOMParser().parseFromString(body.result, "text/html")
      const questionElements = html.getElementsByClassName("stamped-review")

      for (let index = 0; index < questionElements.length; index++) {
        const element = questionElements[index]
        results.push({
          id: getAttribute(element, "stamped-thumbs-up", "data-question-id"),
          author: getHtml(element, "author"),
          date: getHtml(element, "created"),
          question: cleanHtml(getText(element, "stamped-review-content-body"), "Q:"),
          reply: cleanHtml(getText(element, "stamped-review-reply-body"), "A:"),
          votesUp: cleanHtml(getText(element, "stamped-fa-thumbs-up")),
          votesDown: cleanHtml(getText(element, "stamped-fa-thumbs-down")),
        })
      }
      setQuestions(results)
    } else {
      setQuestions([])
      setPages(page)
    }
  }, [size, page, sort, productId, stampedStoreUrl, stampedApiKey])

  const getItems = useCallback(async () => {
    setLoading(true)
    if (type === STAMPED_TYPES.REVIEWS) await getReviews()
    if (type === STAMPED_TYPES.QUESTIONS) await getQuestions()
    setLoading(false)
  }, [type, getReviews, getQuestions])

  const changeSort = useCallback(
    (value: SortOptions) => {
      if (sort !== value) {
        setPage(1)
        setPageSize(pageSize)
        setSort(value)
      }
    },
    [sort, pageSize]
  )

  const changePageSize = useCallback(
    (value: number) => {
      if (size !== value) {
        setPage(1)
        setPageSize(value)
      }
    },
    [size]
  )

  const changePage = useCallback(
    ({ next, prev, pageNumber }: ChangePageProps) => {
      if (next) setPage(prevPage => prevPage + 1)
      if (prev) setPage(prevPage => prevPage - 1)
      if (pageNumber && pageNumber !== page) setPage(pageNumber)
      // scrollToTop()
    },
    [page]
  )

  const submitVoteWithProductId = useCallback(
    async (id: string, productId: string | null, value: number) => {
      const data = new URLSearchParams()
      data.append("vote", `${value}`)
      data.append("productId", productId || "")
      data.append("apiKey", stampedApiKey)
      data.append("storeUrl", stampedStoreUrl)
      if (type === STAMPED_TYPES.REVIEWS) data.append("reviewId", id)
      if (type === STAMPED_TYPES.QUESTIONS) data.append("questionId", id)
      await fetch(`https://stamped.io/api/${type}/vote`, { method: "post", body: data })
      setVoted([...voted, id])
    },
    [stampedApiKey, stampedStoreUrl, type, voted]
  )

  const submitVote = useCallback(
    async (id: string, value: number) => submitVoteWithProductId(id, productId, value),
    [productId, submitVoteWithProductId]
  )

  const createReview = useCallback(
    async (author: string, email: string, title: string, message: string, rating: number, recommended: boolean) => {
      const data = new URLSearchParams()
      data.append("productId", productId || "")
      data.append("apiKey", stampedApiKey)
      data.append("storeUrl", stampedStoreUrl)
      data.append("author", author)
      data.append("email", email)
      data.append("location", "Australia")
      data.append("reviewTitle", title)
      data.append("reviewMessage", message)
      data.append("reviewRating", rating.toString())
      data.append("reviewRecommendProduct", recommended.toString())
      data.append("reviewSource", "widget")
      await fetch(`https://stamped.io/api/reviews3?apiKey=${stampedApiKey}&storeUrl=${stampedStoreUrl}`, { method: "post", body: data })
    },
    [productId, stampedApiKey, stampedStoreUrl]
  )

  const recommendedCount = useMemo(() => {
    if (!reviews) return 0

    const count = (reviews?.data?.filter((review: any) => review.isRecommend)?.length / reviews?.data?.length) * 100
    const roundedCount = Math.round(count)
    return roundedCount
  }, [reviews])

  useEffect(() => {
    getItems()
    // eslint-disable-next-line
  }, [page, size, sort, pages])

  return {
    voted,
    loading,
    reviews,
    questions,
    getItems,
    sortOptions,
    avatarColours,
    page,
    pages,
    changePage,
    sort,
    changeSort,
    size,
    changePageSize,
    submitVote,
    submitVoteWithProductId,
    createReview,
    recommendedCount,
  }
}

export { useStamped, STAMPED_TYPES }
