import { memo, useCallback, useState, useEffect } from "react"
import { Menu, HStack } from "@chakra-ui/react"
import { useAppContext } from "@app/providers/app"
import { useNavigation } from "@app/hooks/useNavigation"
import { NavigationHeaderDesktopButtonDropdown } from "./NavigationHeaderDropdownDesktop"

import type { MenuItemPropsDesktop } from "./NavigationHeaderDropdownDesktop"
import type { Location } from "@root/types/global"

type NavigationHeaderDesktopProps = {
  location: Location
}

const NavigationHeaderDesktop: React.FC<NavigationHeaderDesktopProps> = ({ location }) => {
  const { header: items } = useNavigation()
  const { state } = useAppContext()
  const [active, setActive] = useState<string | null>(null)

  const handleActive = useCallback(key => setActive(key), [setActive])
  const handleClick = useCallback(() => setActive(null), [setActive])

  useEffect(() => {
    if (!state.activeMenu) handleActive(null)
  }, [state.activeMenu, handleActive])

  return (
    <Menu>
      <HStack
        overflow="auto"
        as="nav"
        display={["flex"]}
        columnGap={{ base: 5, xl: 0 }}
        fontSize="sm"
        role="navigation"
        aria-label="Main navigation"
        wrap="nowrap"
        px={6}
        spacing={0}
        sx={{
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {items.map((item: MenuItemPropsDesktop) => (
          <NavigationHeaderDesktopButtonDropdown
            {...item}
            active={active}
            key={item.title.toString()}
            handleActive={handleActive}
            handleClick={handleClick}
            location={location}
          />
        ))}
      </HStack>
    </Menu>
  )
}

export default memo(NavigationHeaderDesktop)
