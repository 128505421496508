import { memo } from "react"
import { Box, HStack, Heading, Flex, VStack } from "@chakra-ui/react"
import { useContent } from "@app/hooks/useContent"
import { CarouselOverflowWrapper } from "@app/components/CarouselOverflowWrapper"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"

const NumberTitle = ({ number, title }: { number: number; title: string }) => (
  <HStack spacing={4} mb={[6, 9]}>
    <Flex
      bg="brand.avocado"
      color="white"
      fontWeight={700}
      fontSize="sm"
      w={8}
      h={8}
      alignItems="center"
      justifyContent="center"
      borderRadius="base"
    >
      {number}
    </Flex>
    <Heading as="p" size="h4">
      {title}
    </Heading>
  </HStack>
)

const ProductCallout: React.FC<any> = ({ title, number, content: rawContent, idx }) => {
  const { getContent } = useContent({ type: "article" })
  const content = getContent({ content: rawContent })

  return (
    <Box
      maxW={["unset", "unset", "85%", 97]}
      mx="auto"
      my={[14, 14, 16]}
      sx={{
        a: {
          fontSize: ["xl", "2xl"],
          textDecoration: "underline",
        },
      }}
    >
      <NumberTitle number={number || idx} title={title} />
      {content}
    </Box>
  )
}

const ProductCalloutHorizontal = ({ content: rawContent }: { content: any }) => {
  const { getContent } = useContent({ type: "productCalloutsHorizontal" })
  const content = getContent({ content: rawContent })

  return (
    <VStack
      textAlign="left"
      sx={{
        p: { fontSize: "md", lineHeight: "24px" },
        a: {
          fontSize: ["md", "22px"],
          textDecoration: "underline",
        },
      }}
      w="full"
      spacing={3.5}
    >
      {content}
    </VStack>
  )
}

const ProductCallouts: React.FC<any> = ({ layout = "vertical", productCallouts, number, title }) => {
  const hasCallouts = !!productCallouts?.length
  if (!hasCallouts) return null

  if (layout === "vertical") {
    return productCallouts?.map((callout: any, idx: number) => <ProductCallout key={callout?._key} idx={idx} {...callout} />)
  }

  if (layout === "horizontal") {
    return (
      <Box my={[14, 14, 16]} position="relative" overflow="visible">
        <NumberTitle number={number} title={title} />
        <CarouselOverflowWrapper type="article">
          <Swiper modules={[Navigation]} navigation pagination={{ clickable: true }} slidesPerView="auto">
            {productCallouts?.map((callout: any) => (
              <SwiperSlide key={callout?._key}>
                <ProductCalloutHorizontal {...callout} />
              </SwiperSlide>
            ))}
          </Swiper>
        </CarouselOverflowWrapper>
      </Box>
    )
  }
}

const MemoProductCallouts = memo(ProductCallouts)
export { MemoProductCallouts as ProductCallouts }
