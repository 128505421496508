import { extendTheme } from "@chakra-ui/react"
import { styles } from "./styles"
import * as components from "./components"
import foundations from "./foundations"

// Overrides the Chakra theme tokens and components
// See https://chakra-ui.com/docs/styled-system/theming/customize-theme

const overrides = {
  styles,
  ...foundations,
  components: {
    ...components,
  },
}

export const themeConfig = extendTheme(overrides)

export default themeConfig
