import { memo } from "react"
import { Box, HStack, Text, Button, ChakraProps, Flex } from "@chakra-ui/react"
import { useSubscriptions } from "@app/hooks/useSubscriptions"

type RecurrenceButtonProps = {
  optionValue: string
  optionName: string
  handleButtonClick: () => void
  loading?: boolean | undefined
  isActiveRecurrenceOption: boolean
  isPlp?: boolean
}

type OneTimeOrRecurringProps = {
  loading?: boolean | undefined
  setPurchaseRecurrence: (recurrence: string) => void
  purchaseRecurrence: string
  appliedDiscount?: number | undefined
  isPlp?: boolean
}

const recurringOptions = [
  { name: "monthly", value: "Subscribe monthly" },
  { name: "one-time", value: "Buy once" },
]

const RecurrenceButton: React.FC<RecurrenceButtonProps> = ({
  optionValue,
  optionName,
  handleButtonClick,
  loading,
  isActiveRecurrenceOption = false,
  isPlp = false,
}) => {
  const plpProps = {
    borderRadius: "68px",
    fontSize: "xxs",
    height: "unset",
    px: 2,
    py: 1,
  }
  const pdpProps = {
    borderRadius: "46px",
    fontSize: "sm",
    height: 8,
    px: 3,
  }
  const restProps = isPlp ? plpProps : pdpProps
  return (
    <Button
      isDisabled={loading}
      key={optionValue}
      onClick={handleButtonClick}
      variant={isActiveRecurrenceOption ? "solid-no-hover" : "outline"}
      minW="unset"
      data-name={optionName}
      data-value={optionValue}
      {...restProps}
    >
      {optionValue}
    </Button>
  )
}

const boxProps: ChakraProps = {
  mb: 0,
  flex: ["1 1 30%", "1 1 auto"],
  color: "white",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  textAlign: "center",
}

const OneTimeOrRecurring: React.FC<OneTimeOrRecurringProps> = ({
  loading,
  setPurchaseRecurrence,
  purchaseRecurrence,
  appliedDiscount,
  isPlp = false,
}) => {
  const { subscriptionBadges } = useSubscriptions()

  return (
    <Box>
      {!isPlp && (
        <Text fontFamily="heading" fontSize="md" fontWeight={900} mb={2.5}>
          Select your delivery preference:
        </Text>
      )}

      <HStack spacing={0} rowGap={1} columnGap={2} wrap="wrap" mb={5}>
        {recurringOptions.map(option => (
          <RecurrenceButton
            key={`recurring-opt-${option.name}`}
            optionValue={option.value}
            optionName={option.name}
            loading={loading}
            handleButtonClick={() => setPurchaseRecurrence(option.name)}
            isActiveRecurrenceOption={purchaseRecurrence === option.name}
            isPlp={isPlp}
          />
        ))}
      </HStack>

      {!isPlp && (
        <Box w="100%">
          <Text fontFamily="heading" fontSize="md" fontWeight={900} mb={2.5}>
            Why subscribe?
          </Text>
          <Flex columnGap={3} justifyContent="space-between" wrap="wrap">
            {subscriptionBadges?.badges?.map(badge => (
              <Box {...boxProps} key={badge?.title}>
                <Box w="50px" mb={2}>
                  <img src={`${badge?.image?.asset?.url}`} alt={badge?.title} />
                </Box>
                <Text fontSize="12px">
                  {appliedDiscount && appliedDiscount > 0 ? badge?.title?.replace(`{{X%}}`, `${appliedDiscount}%`) : badge?.title}
                </Text>
              </Box>
            ))}
          </Flex>
        </Box>
      )}
    </Box>
  )
}

const MemoOneTimeOrRecurring = memo(OneTimeOrRecurring)
export { MemoOneTimeOrRecurring as OneTimeOrRecurring }
