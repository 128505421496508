import { useCallback } from "react"

import type { ProductVariant, Product } from "shopify-storefront-api-typings"

type useOutOfStockArgs = {
  variant?: ProductVariant | null
  product?: Product
}

const useOutOfStock = () => {
  const checkIfOutOfStock = useCallback(({ variant }: useOutOfStockArgs) => {
    if (!variant) return true

    if (variant?.availableForSale) return false
    if (variant?.currentlyNotInStock || !variant?.availableForSale) return true

    return false
  }, [])

  return { checkIfOutOfStock }
}

export { useOutOfStock }
