import { useState, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useStorage } from "@app/hooks/useCore"
import { useConfigContext } from "../providers/config"

export const useAnnouncement = () => {
  const { getStorage, setStorage } = useStorage()
  const {
    settings: { keys },
  } = useConfigContext()

  const [show, setShow] = useState(getStorage(keys.announcement) !== "hidden")

  const { data } = useStaticQuery<GatsbyTypes.StaticAnnouncementsQuery>(graphql`
    query StaticAnnouncements {
      data: sanitySettingAnnouncement {
        duration
        announcements {
          title
          link {
            link
            external
          }
        }
      }
    }
  `)

  const items = data?.announcements || []

  const hide = useCallback(() => {
    setStorage(keys.announcement, "hidden")
    setShow(false)
  }, [setShow, setStorage, keys])

  const durationSeconds = data?.duration ? data?.duration * 1000 : 0

  return {
    items,
    show,
    hide,
    durationSeconds,
  }
}
