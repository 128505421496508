import { SystemStyleObject } from "@chakra-ui/theme-tools"

export const Avatar: SystemStyleObject = {
  baseStyle: {
    color: "brand.avocado",
    fontWeight: "black",
    fontFamily: "heading",
    fontSize: ["2xl", "3xl"],
    lineHeight: ["31.2px", "39px"],
  },
}
