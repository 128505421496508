import { useCallback, memo } from "react"
import { Box, IconButton } from "@chakra-ui/react"
import { CgMenu } from "react-icons/cg"
import { useAppContext } from "@app/providers/app"

const NavigationHeaderWidget: React.FC = () => {
  const { state, dispatch } = useAppContext()

  const onToggle = useCallback(() => {
    dispatch({
      type: "menu",
      payload: !state.activeMenu,
    })
  }, [dispatch, state.activeMenu])

  return (
    <Box ml={1} display={["block", "block", "none"]}>
      <IconButton
        variant="icon-button"
        colorScheme="brand.avocado"
        aria-label="Manage account"
        icon={<CgMenu />}
        size="sm"
        fontSize="x-large"
        onClick={onToggle}
      />
    </Box>
  )
}

const MemoNavigationHeaderWidget = memo(NavigationHeaderWidget)
export { MemoNavigationHeaderWidget as NavigationHeaderWidget }
