import { memo, useMemo } from "react"
import { Text } from "@chakra-ui/react"
import { useBrand } from "@app/hooks/useBrand"

type BrandedProductTitleProps = {
  item?: any
  showDash?: boolean
}

const BrandedProductTitle: React.FC<BrandedProductTitleProps> = ({ item, showDash = true }) => {
  const { getBrand } = useBrand()
  const { hasBrandTag, brandTitle } = getBrand({ product: item })
  const title = useMemo(
    () => (item?.title?.toLowerCase()?.startsWith(brandTitle?.toLowerCase()) ? item?.title?.slice(brandTitle?.length) : item?.title),
    [brandTitle, item?.title]
  )

  return (
    <>
      {hasBrandTag && (
        <Text as="span" fontWeight={700} fontSize="inherit">
          {brandTitle}
          {showDash ? " — " : ""}
        </Text>
      )}
      {title}
    </>
  )
}

const MemoBrandedProductTitle = memo(BrandedProductTitle)
export { MemoBrandedProductTitle as BrandedProductTitle }
