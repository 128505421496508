import { Select, ChakraStylesConfig } from "chakra-react-select"
import { Box } from "@chakra-ui/react"

import type { FormatOptionLabelMeta } from "chakra-react-select"

export interface Option {
  label: string
  value: string
}
const chakraStyles = ({
  fontSize = "md",
  isFaqDropdown = false,
  isReviewsSort = false,
  isQuantitySelector = false,
  height,
  minW,
}): ChakraStylesConfig => ({
  // Wraps the menulist below with border and background styles
  menu: provided => ({
    ...provided,
    my: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderWidth: "1px",
    borderColor: "brand.avocado",
    borderBottomRadius: "md",
    borderTop: "none",
    boxShadow: "11px 11px 20px rgba(76, 66, 79, 0.2)",
    zIndex: 2,
  }),
  // Wraps all of the dropdown options
  menuList: provided => ({
    ...provided,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderWidth: 0,
    backgroundColor: "brand.offWhite",
  }),
  // The dropdown arrow in the input
  dropdownIndicator: provided => ({
    ...provided,
    w: "24px",
    paddingInlineEnd: isReviewsSort ? "0" : "16px",
  }),
  // The input element that contains the placeholder or selected value
  control: (provided, state) => ({
    ...provided,
    minW,
    height,
    backgroundColor: "brand.offWhite",
    borderBottomLeftRadius: state.menuIsOpen ? 0 : "base",
    borderBottomRightRadius: state.menuIsOpen ? 0 : "base",
    borderColor: isFaqDropdown ? "brand.lighterGreen" : state.menuIsOpen ? "brand.avocado" : "brand.avocado",
    borderBottomColor: state.menuIsOpen ? "transparent" : isFaqDropdown ? "brandlighterGreen" : "brand.avocado",
    transitionDuration: 0,
    fontSize,
    _after: state.menuIsOpen && {
      content: '""',
      position: "absolute",
      left: "1rem",
      bottom: 0,
      height: "1px",
      width: "calc(100% - 2rem)",
      // borderBottom: "1px",
      borderBottomColor: "brand.avocado",
    },
    cursor: "pointer",
  }),
  // An individual option
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    color: "brand.avocado",
    _hover: {
      backgroundColor: "gray.100",
    },
    // Ticked arrow SVG
    _after: state.isSelected && {
      content: `url("data:image/svg+xml,%3Csvg width='${isReviewsSort ? "20" : "28"}' height='${
        isReviewsSort ? "20" : "28"
      }' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='14' cy='14' r='13.5' stroke='%23143B34'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.0303 9.96967C20.3232 10.2626 20.3232 10.7374 20.0303 11.0303L13.0303 18.0303C12.7374 18.3232 12.2626 18.3232 11.9697 18.0303L7.96967 14.0303C7.67678 13.7374 7.67678 13.2626 7.96967 12.9697C8.26256 12.6768 8.73744 12.6768 9.03033 12.9697L12.5 16.4393L18.9697 9.96967C19.2626 9.67678 19.7374 9.67678 20.0303 9.96967Z' fill='black'/%3E%3C/svg%3E%0A")`,
      position: "absolute",
      right: "1rem",
      w: [`20px`, `28px`],
      h: [`20px`, `28px`],
    },
  }),
  // A line separator surrounding the dropdownIndicator
  indicatorSeparator: provided => ({
    ...provided,
    display: "none",
  }),
  // The container for the text in the control
  valueContainer: provided => ({
    ...provided,
    pl: 0,
    py: isQuantitySelector ? 2 : 0,
  }),
})

type CustomSelectProps = {
  options: Array<Option>
  placeholder?: React.ReactNode
  onChange?: (event: any) => void
  [rest: string]: any
  width?: string | number | Array<string> | Array<number>
  height?: string | number | Array<string> | Array<number>
  defaultValue?: any
  customLabel?: FormatOptionLabelMeta
  fontSize?: string
  name?: string
  value?: any
  isQuantitySelector?: boolean
  isFaqDropdown?: boolean
  isReviewsSort?: boolean
  label?: string
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  placeholder,
  onChange,
  width,
  height,
  defaultValue,
  components,
  fontSize,
  name = "",
  value,
  onBlur, // from react-hook-form
  innerRef, // from react-hook-form
  isQuantitySelector,
  isFaqDropdown,
  isReviewsSort,
  label = "Sort By",
  minW,
  ...rest
}) => {
  return (
    <Box w={width}>
      <Select
        name={name}
        placeholder={placeholder}
        options={options}
        selectedOptionStyle="color"
        chakraStyles={chakraStyles({ fontSize, isQuantitySelector, isFaqDropdown, height, minW, isReviewsSort })}
        placeholderColor="brand.avocado"
        isSearchable={false}
        onChange={onChange}
        defaultValue={defaultValue || value}
        value={value}
        label={label}
        components={{
          ...components,
          SingleValue: props => {
            if (props?.selectProps?.label) {
              return (
                <div>
                  <strong>{props?.selectProps?.label}: </strong> {props.children}
                </div>
              )
            }
            return <div>{props.children}</div>
          },
        }}
        onBlur={onBlur}
        ref={innerRef}
        {...rest}
      />
    </Box>
  )
}

export { CustomSelect }
