// TODO: switch this to use capitalise() from useCore() helpers
const capitalizeWord = (word: string): string => {
  return word?.charAt(0)?.toUpperCase() + word?.substring(1)?.toLowerCase()
}

// converts a shopify handle back into a space separated string
const deHandleize = (handle: string) => {
  if (!handle) return ""

  return handle
    ?.split("-")
    ?.map(word => capitalizeWord(word))
    ?.join(" ")
}

const escapeHtml = (unsafe: string) => {
  return unsafe
    ?.replaceAll("&", "&amp;")
    ?.replaceAll("<", "&lt;")
    ?.replaceAll(">", "&gt;")
    ?.replaceAll('"', "&quot;")
    ?.replaceAll("'", "&#039;")
}

const useStringHelpers = () => {
  return {
    capitalizeWord,
    deHandleize,
    escapeHtml,
  }
}

export { useStringHelpers }
