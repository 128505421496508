import { SystemStyleObject } from "@chakra-ui/theme-tools"

export const Heading: SystemStyleObject = {
  baseStyle: {
    color: "brand.avocado",
    fontWeight: "black",
    fontFamily: "heading",
  },
  sizes: {
    h1: {
      fontSize: ["7xl", "9xl"],
      lineHeight: ["64.8px", "85.26px"],
      fontWeight: "black",
    },
    h2: {
      fontSize: ["6xl", "8xl"],
      lineHeight: ["57.6px", "72px"],
      fontWeight: "black",
    },
    h3: {
      fontSize: ["4xl", "5xl"],
      lineHeight: ["41.6px", "46.8px"],
      fontWeight: "black",
    },
    h4: {
      fontSize: ["2xl", "3xl"],
      lineHeight: ["31.2px", "39px"],
      fontWeight: "black",
    },
    h5: {
      fontSize: "2xl",
      lineHeight: "28.8px",
      fontFamily: "body",
      fontWeight: "normal",
    },
    h6: {
      fontSize: "xl",
      lineHeight: "30px",
      fontFamily: "body",
      fontWeight: "normal",
    },
  },
}
