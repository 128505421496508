import { Box, Text } from "@chakra-ui/react"
import { SiteLogo } from "@app/components/SiteLogo"
import { memo } from "react"

type FooterLogoMessageProps = {
  message?: string
}

const FooterLogoMessage: React.FC<FooterLogoMessageProps> = ({ message }) => (
  <Box maxWidth="300px" mr={[0, 0, 7, 10]}>
    <Box mb={[5, 7]}>
      <SiteLogo fill="white" />
    </Box>
    <Text color="inherit" fontSize="sm">
      {message}
    </Text>
  </Box>
)

const MemoFooterLogoMessage = memo(FooterLogoMessage)
export { MemoFooterLogoMessage as FooterLogoMessage }
