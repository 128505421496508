import { memo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { CustomLink } from "@components/Link"
import { Icon, Stack, Box } from "@chakra-ui/react"
import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa"
import { BsPinterest } from "react-icons/bs"
import { useCore } from "@app/hooks/useCore"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { CopyToClipboard } from "@components/CopyToClipboard"

type SocialLinksProps = {
  orientation?: "horizontal" | "vertical"
}

const INSTAGRAM = "instagram"
const FACEBOOK = "facebook"
const PINTEREST = "pinterest"
const TIKTOK = "tiktok"

const socialLogoMappings = {
  [INSTAGRAM]: () => <Icon as={FaInstagram} w={5} h={5} />,
  [PINTEREST]: () => <Icon as={BsPinterest} w={5} h={5} />,
  [TIKTOK]: () => <Icon as={FaTiktok} w={5} h={5} />,
  [FACEBOOK]: () => <Icon as={FaFacebookF} w={5} h={5} />,
}

type socialLogoMappingsKeys = keyof typeof socialLogoMappings

const socialShareMappings = (channel: string) => {
  const pageUrl = window?.location?.href

  switch (channel) {
    case FACEBOOK:
      return `https://www.facebook.com/share.php?u=${pageUrl}`
    case PINTEREST:
      return `https://pinterest.com/pin/create/button/?url=${pageUrl}`
    default:
      return ""
  }
}

const SocialMedia: React.FC = () => {
  const { social } = useStaticQuery<GatsbyTypes.StaticSocialMediaQuery>(graphql`
    query StaticSocialMedia {
      social: sanitySettingSocial {
        channels {
          title
          url
        }
      }
    }
  `)

  if (!social?.channels?.length) return null

  return (
    <>
      {social?.channels?.map(item => {
        const Logo: React.FC<any> | null = socialLogoMappings[item?.title?.toLowerCase() as socialLogoMappingsKeys]

        if (!Logo) return null

        return (
          <CustomLink key={item?.title?.toString()} to={item?.url || ""} external styles={{ mr: 5 }}>
            <Logo w={20}>{item?.title}</Logo>
          </CustomLink>
        )
      })}
    </>
  )
}

const SocialLinks: React.FC<SocialLinksProps> = ({ orientation = "horizontal" }) => {
  const {
    helpers: { isBrowser },
  } = useCore()
  const locales = useLocale(LOCALE_KEYS.ARTICLE)

  const direction = orientation === "vertical" ? "column" : "row"

  return (
    <Stack direction={direction} spacing={5}>
      {Object.keys(socialLogoMappings).map(channelType => {
        const Logo: React.FC | null = socialLogoMappings[channelType as socialLogoMappingsKeys]

        if (channelType?.toLowerCase() !== FACEBOOK && channelType?.toLowerCase() !== PINTEREST) return null

        return (
          <Box key={channelType}>
            <CustomLink to={isBrowser ? socialShareMappings(channelType) : ""} external>
              <Logo>{channelType}</Logo>
            </CustomLink>
          </Box>
        )
      })}
      <CopyToClipboard copiedLinkText={locales?.copiedLinkText} />
    </Stack>
  )
}

const MemoSocialMedia = memo(SocialMedia)
const MemoSocialLinks = memo(SocialLinks)

export { MemoSocialMedia as SocialMedia, MemoSocialLinks as SocialLinks }
