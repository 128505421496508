import { Box, Heading } from "@chakra-ui/react"
import { memo } from "react"

type InitialBoxProps = {
  initial?: string
  bgColor?: string
}

const InitialBox: React.FC<InitialBoxProps> = ({ initial, bgColor = "brand.rain" }) => {
  if (!initial) return null

  return (
    <Box
      fontSize="md"
      textTransform="uppercase"
      w={8}
      h={8}
      bg={bgColor}
      borderRadius="round"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Heading as="div" fontSize="md" textTransform="uppercase">
        {initial}
      </Heading>
    </Box>
  )
}

const MemoInitialBox = memo(InitialBox)
export { MemoInitialBox as InitialBox }
