const baseStyle = {
  maxW: ["100%", "100%", 110],
  px: [5, 5, 12],
}

const variants = {
  article: {
    maxW: "60ch",
  },
  small: {
    maxW: ["100%", "100%", "1100px"],
  },
  contact: {
    maxW: ["100%", "100%", 100],
  },
  full: {
    maxW: "100%",
  },
}

const sizes = {}

const defaultProps = {}

export const Container = {
  baseStyle,
  variants,
  sizes,
  defaultProps,
}
