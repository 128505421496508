import { memo, useCallback, useState } from "react"
import { LinkIcon } from "@chakra-ui/icons"
import { Box, Tooltip } from "@chakra-ui/react"
import { useTimer } from "use-timer"
import { useCore } from "@app/hooks/useCore"

type CopyToClipboardProps = {
  copiedLinkText?: string
  hideIcon?: boolean
  children?: React.ReactNode
  linkToCopy?: string
  callback?: (args?: any) => void
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ copiedLinkText, hideIcon = false, children, linkToCopy, callback }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const {
    helpers: { isBrowser },
  } = useCore()

  const { start } = useTimer({
    initialTime: 3,
    endTime: 0,
    timerType: "DECREMENTAL",
    onTimeOver: () => setShowTooltip(false),
  })

  const handleCopyClick = useCallback(() => {
    if (isBrowser) {
      const currentUrl = window?.location?.href
      window.navigator.clipboard.writeText(linkToCopy || currentUrl)
    }

    setShowTooltip(true)
    start()
    callback?.()
  }, [start, setShowTooltip, isBrowser, linkToCopy, callback])

  return (
    <Box cursor="pointer">
      <Tooltip
        borderRadius="base"
        px={3}
        py={2}
        hasArrow
        placement="top"
        label={copiedLinkText}
        aria-label="Copy link"
        isOpen={showTooltip}
      >
        <Box onClick={handleCopyClick}>
          {!hideIcon && <LinkIcon w={5}>Link</LinkIcon>}
          {children}
        </Box>
      </Tooltip>
    </Box>
  )
}

const MemoCopyToClipboard = memo(CopyToClipboard)
export { MemoCopyToClipboard as CopyToClipboard }
