import { memo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { HStack, Img, Text } from "@chakra-ui/react"

type Props = {
  showTitle?: boolean
  [rest: string]: any
}

const PaymentTypes: React.FC<Props> = ({ showTitle, ...props }) => {
  const { types } = useStaticQuery<GatsbyTypes.StaticPaymentsQuery>(graphql`
    query StaticPayments {
      types: sanitySettingPayments {
        title
      }
    }
  `)

  const enabledTypes = [
    {
      _key: "MJfmXfvQ",
      label: "Amex",
      value: "Amex",
    },
    {
      _key: "lfW0PmTJ",
      label: "GooglePay",
      value: "GooglePay",
    },
    {
      _key: "s3VLz0tr",
      label: "ApplePay",
      value: "ApplePay",
    },
    {
      _key: "3d8Lj4zS",
      label: "JCB",
      value: "JCB",
    },
    {
      _key: "Ke01SLhT",
      label: "MasterCard",
      value: "MasterCard",
    },
    {
      _key: "KT59CqRH",
      label: "Paypal",
      value: "Paypal",
    },
    {
      _key: "C0yw3n6P",
      label: "ShopPay",
      value: "ShopPay",
    },
    {
      _key: "G5YyDDWB",
      label: "Visa",
      value: "Visa",
    },
    {
      _key: "q8VwRyjX",
      label: "AfterPay",
      value: "AfterPay",
    },
  ]

  return (
    <>
      {showTitle && <Text>{types?.title}</Text>}
      <HStack mt={showTitle ? 2 : 8} width="full" justifyContent="flex-start" spacing={0} columnGap={1} rowGap={1} {...props} wrap="wrap">
        {enabledTypes.map(({ value }: any) => (
          <Img key={value} src={`/images/payments/${value?.toLowerCase()}.png`} w={10} display="block" />
        ))}
      </HStack>
    </>
  )
}

const MemoPaymentTypes = memo(PaymentTypes)
export { MemoPaymentTypes as PaymentTypes }
