import { useCallback } from "react"
import { useStringHelpers } from "@app/hooks/useStringHelpers"
import { useConfigContext } from "@app/providers/config"

// Returns back the first matching brand string from an array
// of shopify product tags. eg `brand:test` will return test

const noBrandTag = {
  brandTag: "",
  hasBrandTag: false,
  brandHandle: "",
  brandTitle: "",
  brandCollectionUrl: "",
}

const useBrand = () => {
  const { deHandleize } = useStringHelpers()
  const {
    settings: {
      product: { tagBrandPrefix },
      routes,
    },
  } = useConfigContext()

  const getBrand = useCallback(
    ({ product }: { product: any }) => {
      const productTags = product?.tags || product?.variant?.product?.tags
      const hasProductTags = !!productTags?.length

      if (!hasProductTags) return noBrandTag

      const brandTag = productTags?.find((tag: string) => tag.startsWith(tagBrandPrefix))

      if (!brandTag) return noBrandTag

      const brandHandle = brandTag?.split(tagBrandPrefix)?.[1]

      const brandCollectionUrl = `${routes.COLLECTION}/${brandHandle}`

      return {
        brandTag,
        hasBrandTag: true,
        brandHandle,
        brandTitle: deHandleize(brandHandle),
        brandCollectionUrl,
      }
    },
    [deHandleize, routes.COLLECTION, tagBrandPrefix]
  )

  return { getBrand }
}

export { useBrand }
