import { memo } from "react"
import { Container, Breadcrumb as Breadcrumbs, BreadcrumbItem, BreadcrumbLink, Box } from "@chakra-ui/react"
import { CustomLink } from "@components/Link"
import { useBreadcrumb } from "@app/hooks/useBreadcrumb"

import type { Location } from "@root/types/global"

type BreadcrumbProps = {
  data: any
  location: Location
}

const currentPageStyles = {
  // No default current page selector https://chakra-ui.com/docs/components/breadcrumb
  "[aria-current='page']": { fontWeight: 700 },
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ location, data }) => {
  const { buildBreadcrumbs } = useBreadcrumb(location)
  const { breadcrumbs, shouldHideBreadcrumbs } = buildBreadcrumbs({ data })

  if (shouldHideBreadcrumbs) return null

  return (
    <Box sx={currentPageStyles} position="absolute" top={[5, 5, 8]} w="full" left="50%" transform="translateX(-50%)" zIndex={1}>
      <Container>
        <Breadcrumbs separator="•">
          {breadcrumbs.map((item: any) => (
            <BreadcrumbItem key={item?.title?.toString()} isCurrentPage={item?.url === location?.pathname?.slice(0, -1)}>
              <BreadcrumbLink as={CustomLink} to={item?.url} styles={{ fontSize: ["xs", "sm"] }}>
                {item?.title}
              </BreadcrumbLink>
            </BreadcrumbItem>
          ))}
        </Breadcrumbs>
      </Container>
    </Box>
  )
}

export default memo(Breadcrumb)
