import { memo } from "react"
import { ITEM_TYPES, ItemLink, ItemTitle, ItemBrand } from "./NavigationHeaderItemsDesktop"
import { Box, Text, HStack, Accordion, AccordionItem, AccordionButton, AccordionPanel } from "@chakra-ui/react"
import { CustomImage } from "@components/Image"
import { useContent } from "@app/hooks/useContent"
import { CustomLink } from "@components/Link"
import { useSanityImageUrl } from "@app/hooks/useSanity"

import type { NavigationItemsProps, MenuItemProps, ItemProps } from "./NavigationHeaderItemsDesktop"

type NavigationHeaderItemsMobileProps = NavigationItemsProps & {
  handleActiveSubMenu: (arg0: string) => void
  activeSubMenu: string
  activeSubMenuTitle?: string
  activeSubMenuUrl?: string
}

const MobileItemLink = ({ item, parentCollection, parentCollectionUrl, nonParent }: MenuItemProps) => {
  return (
    <Box pb={3}>
      <ItemLink
        {...item}
        fontSize={nonParent ? "26px" : "3xl"}
        parentCollection={parentCollection}
        parentCollectionUrl={parentCollectionUrl}
        nonParent={nonParent}
      />
    </Box>
  )
}

const MobileItemBrand = ({ item, parentCollection, parentCollectionUrl }: MenuItemProps) => {
  return (
    <Box pb={3}>
      <ItemBrand {...item} parentCollection={parentCollection} parentCollectionUrl={parentCollectionUrl} />
    </Box>
  )
}

const MobileItemTile = ({ item }: MenuItemProps) => {
  const { getSanityImageUrl } = useSanityImageUrl()
  const customPortableTextComponents = {
    // word-spacing breaks each word to new line
    block: ({ children }: any) => <Text sx={{ wordSpacing: "999px" }}>{children}</Text>,
  }

  const { getContent } = useContent()
  const content = getContent({ content: item?.title, components: customPortableTextComponents })
  const imgUrl = item?.image ? getSanityImageUrl(item?.image, 260, 200) : ""

  return (
    <CustomLink to={item?.url} external={item?.external}>
      <HStack bg="brand.lightSand" mb={6}>
        <CustomImage width="half" src={imgUrl} fit="cover" />
        <Box>{content}</Box>
      </HStack>
    </CustomLink>
  )
}

const MobileItemNestedSubMenu = ({ item, parentCollection, parentCollectionUrl, nonParent }: MenuItemProps) => {
  const customState = {
    breadcrumbs: [
      { title: parentCollection, url: parentCollectionUrl },
      { title: item?.title, url: item?.url },
    ],
  }

  return (
    <Accordion pb={nonParent ? 3 : 4} allowToggle>
      <AccordionItem border="none">
        <AccordionButton p={0} fontSize={nonParent ? "26px" : "3xl"} textAlign="left">
          {item?.title}
        </AccordionButton>
        {item?.items?.map((subItem: ItemProps) => (
          <AccordionPanel key={subItem?._key} p={0} mt={3}>
            <Text fontSize="md">
              <CustomLink to={subItem?.url} state={customState}>
                {subItem?.title}
              </CustomLink>
            </Text>
          </AccordionPanel>
        ))}
      </AccordionItem>
    </Accordion>
  )
}

const MobileItemSubMenu = memo(({ item, handleActiveSubMenu, activeSubMenu, parentCollection, parentCollectionUrl }: any) => {
  const hasSubItems = !!item?.items.length

  if (!hasSubItems)
    return (
      <MobileItemLink
        item={item}
        handleClick={() => null}
        parentCollection={parentCollection}
        parentCollectionUrl={parentCollectionUrl}
        nonParent={true}
      />
    )

  const isSecondLevelSubMenu = !!activeSubMenu

  if (isSecondLevelSubMenu) {
    const hasNonTiles = !!item?.items?.find(subItem => subItem?.type !== "tile")
    // hides the menu section if its children are only tiles
    if (!hasNonTiles) return null

    return (
      <>
        {!item?.hideTitle && (
          <Box pt={4} borderTop="1px" borderColor="brand.avocado">
            <ItemTitle {...item} parentCollection={parentCollection} parentCollectionUrl={parentCollectionUrl} />
          </Box>
        )}
        {item?.items.map((subItem: any) => {
          const isThirdLevelSubMenu = subItem.type === "sub"

          if (isThirdLevelSubMenu)
            return (
              <MobileItemNestedSubMenu
                key={subItem?._key}
                item={subItem}
                parentCollection={parentCollection}
                parentCollectionUrl={parentCollectionUrl}
                nonParent
              />
            )

          return (
            <MobileMenuItem
              key={subItem?._key}
              item={subItem}
              parentCollection={parentCollection}
              parentCollectionUrl={parentCollectionUrl}
              nonParent
            />
          )
        })}
      </>
    )
  }

  // Parent Items
  return (
    <Text
      pb={3}
      cursor="pointer"
      lineHeight="none"
      fontFamily="heading"
      fontWeight={900}
      fontSize="3xl"
      onClick={() => handleActiveSubMenu(item?._key)}
    >
      {item?.title}
    </Text>
  )
})

const MobileMenuItem = memo(({ item, handleActiveSubMenu, activeSubMenu, parentCollection, parentCollectionUrl, nonParent }: any) => {
  switch (item.type) {
    case ITEM_TYPES.LINK:
      return (
        <MobileItemLink item={item} parentCollection={parentCollection} parentCollectionUrl={parentCollectionUrl} nonParent={nonParent} />
      )
    case ITEM_TYPES.COLLECTION:
      return (
        <MobileItemLink item={item} parentCollection={parentCollection} parentCollectionUrl={parentCollectionUrl} nonParent={nonParent} />
      )
    case ITEM_TYPES.SUB_NAVIGATION:
      return (
        <MobileItemSubMenu
          item={item}
          handleActiveSubMenu={handleActiveSubMenu}
          activeSubMenu={activeSubMenu}
          parentCollection={parentCollection}
          parentCollectionUrl={parentCollectionUrl}
        />
      )
    case ITEM_TYPES.TILE:
      // uses a special tile in parent
      return null
    case ITEM_TYPES.BRAND:
      return (
        <MobileItemBrand item={item} parentCollection={parentCollection} parentCollectionUrl={parentCollectionUrl} nonParent={nonParent} />
      )
    default:
      return (
        <MobileItemLink item={item} parentCollection={parentCollection} parentCollectionUrl={parentCollectionUrl} nonParent={nonParent} />
      )
  }
})

const NavigationHeaderItemsMobile = ({
  items,
  handleActiveSubMenu,
  activeSubMenu,
  activeSubMenuTitle,
  activeSubMenuUrl,
}: NavigationHeaderItemsMobileProps) => (
  <>
    {items.map((item: any) => {
      return (
        <MobileMenuItem
          item={item}
          key={item?._key}
          handleActiveSubMenu={handleActiveSubMenu}
          activeSubMenu={activeSubMenu}
          parentCollection={activeSubMenuTitle}
          parentCollectionUrl={activeSubMenuUrl}
          nonParent={activeSubMenu !== ""}
        />
      )
    })}
  </>
)

const MemoNavigationHeaderItemsMobile = memo(NavigationHeaderItemsMobile)
export { MemoNavigationHeaderItemsMobile as NavigationHeaderItemsMobile, MobileItemTile }
