import { useStaticQuery, graphql } from "gatsby"

const useSubscriptions = () => {
  const { subscriptionBadges, subscriptionDinkus, subscriptionMessages } =
    useStaticQuery<GatsbyTypes.StaticSubscriptionBadgesQuery>(graphql`
      query StaticSubscriptionBadges {
        subscriptionBadges: sanitySettingSubscriptions {
          badges {
            title
            image {
              asset {
                url
              }
            }
          }
        }
        subscriptionDinkus: sanitySettingSubscriptions {
          dinkusMessage
        }
        subscriptionMessages: sanitySettingSubscriptions {
          oneTimeProductMessage
          subscriptionProductMessage
        }
      }
    `)

  return { subscriptionBadges, subscriptionDinkus, subscriptionMessages }
}

export { useSubscriptions }
