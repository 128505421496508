import { useTheme, useMediaQuery } from "@chakra-ui/react"
import { useAppContext } from "@app/providers/app"

type UseMedia = {
  isBase: boolean
  isSmall: boolean
  isMedium: boolean
  isUnderMedium: boolean
  isLarge: boolean
  isXLarge: boolean
  isTouch: boolean
}

const useMedia = (): UseMedia => {
  const { breakpoints } = useTheme()
  const { isHydrated } = useAppContext()

  const parseBreakpoint = (breakpoint: string) => Number(breakpoint.replace("rem", "").replace("em", "")) * 16

  const queries = {
    toBase: `(max-width: ${parseBreakpoint(breakpoints.sm) - 1}px)`,
    fromSm: `(min-width: ${parseBreakpoint(breakpoints.sm)}px)`,
    toMd: `(max-width: ${parseBreakpoint(breakpoints.md) - 1}px)`,
    fromMd: `(min-width: ${parseBreakpoint(breakpoints.md)}px)`,
    fromLg: `(min-width: ${parseBreakpoint(breakpoints.lg)}px)`,
    fromXl: `(min-width: ${parseBreakpoint(breakpoints.xl)}px)`,
    touch: `(hover: none)`,
  }

  // The undocumented SSR flag prevents hydration issues
  // during client rendering
  // see https://github.com/chakra-ui/chakra-ui/blob/6b6109cf725fc23e356462260943f3b4f145745b/.changelog/v2.2.0.mdx#chakra-uimedia-query310
  const [toBase, fromSm, toMd, fromMd, fromLg, fromXl, touch] = useMediaQuery(Object.values(queries), {
    ssr: true,
  })

  const isBase = isHydrated ? toBase : true
  const isSmall = isHydrated ? fromSm : false
  const isUnderMedium = isHydrated ? toMd : false
  const isMedium = isHydrated ? fromMd : false
  const isLarge = isHydrated ? fromLg : false
  const isXLarge = isHydrated ? fromXl : false
  const isTouch = isHydrated ? touch : false

  return { isBase, isSmall, isMedium, isUnderMedium, isLarge, isXLarge, isTouch }
}

export { useMedia }
