import { useCallback, useMemo, useState } from "react"
import { useConfigContext } from "@app/providers/config"
import client from "@sanity/client"
import deepEqual from "fast-deep-equal/react"
import imageUrlBuilder from "@sanity/image-url"

// See https://www.sanity.io/docs/js-client
// and https://www.sanity.io/docs/overview-groq

// Hook purpose: allows us to query Sanity client side at runtime where needed,
// since gatsby's useStaticQuery does not accept variables. For example - get all
// blog posts with the title containing X

const useSanityClient = () => {
  const {
    store: { sanityProjectId, sanityDataset, sanityApiVersion },
  } = useConfigContext()

  const sanityClient = useMemo(
    () =>
      client({
        projectId: sanityProjectId,
        dataset: sanityDataset,
        apiVersion: sanityApiVersion,
        useCdn: false,
      }),
    [sanityApiVersion, sanityDataset, sanityProjectId]
  )

  return { sanityClient }
}

const useSanity = () => {
  const [loading, setLoading] = useState(false)
  const [sanityItems, setSanityItems] = useState([])

  const { sanityClient } = useSanityClient()

  const getSanity = useCallback(
    query => {
      if (!loading) {
        setLoading(true)

        sanityClient.fetch(query).then(items => {
          if (!deepEqual(items, sanityItems)) {
            setSanityItems(items || [])
          }

          setLoading(false)
        })
      }
    },
    [loading, sanityClient, sanityItems]
  )

  return { getSanity, sanityItems, loading }
}

const useSanityImageUrl = () => {
  const { sanityClient } = useSanityClient()

  const builder = imageUrlBuilder(sanityClient)

  // See https://www.sanity.io/docs/image-url
  const getSanityImageUrl = useCallback(
    (source: string, width: number, height: number) => {
      return builder.image(source).width(width).height(height).format("webp").url()
    },
    [builder]
  )

  return { getSanityImageUrl }
}

export { useSanity, useSanityImageUrl }
