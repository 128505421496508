import { IconButton, HStack, Text } from "@chakra-ui/react"
import { CustomIcon } from "@components/Icon"
import { useConfigContext } from "@app/providers/config"
import { CustomLink } from "@components/Link"
import { memo } from "react"

const WishListWidget: React.FC = () => {
  const {
    settings: { routes },
  } = useConfigContext()

  return (
    <CustomLink to={routes.WISHLIST}>
      <HStack spacing={3}>
        <IconButton
          variant="icon-button"
          title="Open Shopping Lists"
          aria-label="Open Shopping Lists"
          icon={<CustomIcon name="shopping-list" width={25} height={25} />}
          size="lg"
          fontSize="x-large"
        />
        <Text fontSize="xs" display={["none", "none", "block"]}>
          Shopping
          <br /> Lists
        </Text>
      </HStack>
    </CustomLink>
  )
}

const MemoWishListWidget = memo(WishListWidget)
export { MemoWishListWidget as WishListWidget }
