// Note: make sure to update `objectTextAppearance` in the admin as well if any of the
// values here are updated

// see https://chakra-ui.com/docs/styled-system/theme#typography for weights

export const fontSizes = {
  xxs: "10px",
  xs: "12px",
  sm: "14px",
  md: "16px",
  lg: "18px",
  xl: "20px",
  "2xl": "24px",
  "3xl": "30px",
  "4xl": "32px",
  "5xl": "36px",
  "6xl": "48px",
  "7xl": "54px",
  "8xl": "60px",
  "9xl": "87px",
}
