module.exports = {
  id: "goodnessme-box",
  title: "GoodnessMe Box",
  description: "Shop healthy snacks and monthly boxes at GoodnessMe!",
  url: "goodnessme.com.au",

  locationRegion: "AU",
  locationCountries: "AU",
  locationForceRegion: true,
  locationLookupUrl: "https://ipapi.co/json/",

  shopifyPlus: true,
  shopifyApiVersion: "2024-07",
  shopifyShopDomain: "goodnessme-box.myshopify.com",
  shopifyCheckoutUrl: "checkout.goodnessme.com.au",
  shopifyStorefrontToken: "5ef3457ce7294abec9ab39768d7c16cb",
  shopifyAdminToken: "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",

  shopifyApiKey: "f196c0d53ac0de6e8d5abb0677125ac9",
  shopifyApiSecret: "7a04e1fd6b28bd247dafc4c995c40c16",

  stampedStoreUrl: "goodnessme-box.myshopify.com",
  stampedApiKey: "pubkey-CG99P3NwN8SM614FoVXYB2rVB8k3Y1",

  sanityProjectId: "h6pfw6o3",
  sanityDataset: "production",
  sanityApiVersion: "2022-02-24",
  sanityToken:
    "skIava9ZtpowyCnHtyqT9B3VhokOjBj9vOlvM16ex8zGiyXSemzcWZ7zwZJhaHFFXqpRDrAftAlnqafR3fIVutYMaG3IY4m3pct8geCrU4UbVvjs45Vwiyfe5vt9ekvXcWFndWKU3FY63qfjcfbemHym1mz2qopl632X0f1JgFngGuTq5MbI",

  searchIndex: "goodnessme-search",

  googleAuthClientId: "....apps.googleusercontent.com",
  googleMapsApiKey: "",

  klaviyoApiKey: "HwvvXB",

  emailSender: "hello@goodnessmebox.com",

  facebookAppId: "...",

  rechargeBaseApiUrl: "https://api.rechargeapps.com",
  rechargeApiVersion: "2021-01",

  loyaltyLionBaseApiUrl: "https://api.loyaltylion.com/v2",
  loyaltyLionApiToken: "2e3d0b9e9c9aa5b66548e25460741d65",

  gorgiasHelpChatAppId: "4309",
  siteCurrencyCode: "AUD",
  TEMP_CONTROL_POUCH_ID: "37653364244671",
}
