import { memo } from "react"
import { Box, Button, Text } from "@chakra-ui/react"

type CartMinimumSpendProps = {
  minimumAmount: number
  ctaText: string
  showCta?: boolean
  noNegativeMargin?: boolean
  isMinicart?: boolean
}

const CartMinimumSpendNotice: React.FC<CartMinimumSpendProps> = ({
  minimumAmount,
  ctaText,
  showCta = true,
  noNegativeMargin = false,
  isMinicart = false,
}) => {
  return (
    <>
      <Box bgColor="#fdf0b7" mx={noNegativeMargin ? "0" : "-24px"} mb="10px" py="18px" px={["18px", isMinicart ? "32px" : "24px"]}>
        <Text color="#143b34" fontSize="14px" textAlign={["center", "left"]}>
          Continue shopping to reach ${minimumAmount} min spend
        </Text>
      </Box>
      {showCta && (
        <Button variant="solid" w="full" opacity="0.5" pointerEvents="none" cursor="not-allowed">
          {ctaText}
        </Button>
      )}
    </>
  )
}

const MemoCartMinimumSpendNotice = memo(CartMinimumSpendNotice)
export { MemoCartMinimumSpendNotice as CartMinimumSpendNotice }
