import { Box, Container, Divider, Stack, Flex } from "@chakra-ui/react"
import { PaymentTypes } from "@components/PaymentTypes"

type ChildrenProps = {
  children?: any
}

const BgWrapper: React.FC<ChildrenProps> = ({ children }) => (
  <Box as="footer" bgColor="brand.avocado" color="brand.offWhite" pt={[12, 12, 24]} pb={[6, 14, 24]}>
    <Container py={[0, 0]}>{children}</Container>
  </Box>
)

const StyledDivider = () => <Divider mt={22} mb={9} opacity={1} borderColor="brand.offWhite" display={["none", "none", "block"]} />

const AboveDividerStack: React.FC<ChildrenProps> = ({ children }) => (
  <Stack flex="1" direction={["column", "column", "row"]} spacing={[7, 7, 0, 7]} justifyContent={[null, null, null, "space-between"]}>
    {children}
  </Stack>
)

const BelowDividerStack: React.FC<ChildrenProps> = ({ children }) => (
  <Stack direction={["column-reverse", "row"]} justifyContent="space-between" alignItems="center" mt={[10, 10, 0]}>
    {children}
  </Stack>
)

const CopyrightUtilityFlex: React.FC<ChildrenProps> = ({ children }) => (
  <Flex
    color="brand.disabledLight"
    w="full"
    flex="2"
    direction={["column-reverse", "column-reverse", "column-reverse", "row"]}
    alignItems={[null, null, null, "flex-end"]}
  >
    {children}
  </Flex>
)

const StyledPaymentTypes: React.FC = () => <PaymentTypes flex="1" justifyContent="flex-end" display={["none", "none", "flex"]} />

export { BgWrapper, StyledDivider, AboveDividerStack, BelowDividerStack, CopyrightUtilityFlex, StyledPaymentTypes }
