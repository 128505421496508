import { memo } from "react"
import { Box } from "@chakra-ui/react"
import NavigationFooterDesktop from "./NavigationFooterDesktop"
import NavigationFooterMobile from "./NavigationFooterMobile"

const NavigationFooter: React.FC = () => {
  return (
    <>
      <Box display={["none", "none", "block"]}>
        <NavigationFooterDesktop />
      </Box>
      <Box display={["block", "block", "none"]}>
        <NavigationFooterMobile />
      </Box>
    </>
  )
}

const MemoNavigationFooter = memo(NavigationFooter)
export { MemoNavigationFooter as NavigationFooter }
