module.exports = {
  routes: {
    ACTIVATE: "/account/activate",
    ADDRESSES: "/account/addresses",
    ALL_PRODUCTS: "/collections/all-products",
    ARTICLE: "/articles",
    BRAND: "/brands",
    CART: "/cart",
    CATEGORY: "/articles/category",
    CHANGE: "/account/change",
    COLLECTION: "/collections",
    CONTACT: "/contact",
    CHECKOUT: "/account/login/loading",
    DASHBOARD: "/account",
    DETAILS: "/account/details",
    DOWNLOADS: "/downloads",
    ERROR: "/404",
    FAQS: "/faqs",
    FLEXIBLE: "",
    FORGOT: "/account/forgot",
    GENERIC: "",
    GIFTCARD: "/giftcard",
    HOMEPAGE: "/",
    INVALID: "/account/invalid_token",
    LOGIN_REGISTER: "/account/login",
    NUTRITIONIST: "/nutritionists",
    ORDERS: "/account/orders",
    PAGE: "",
    PASSWORD: "/password",
    PREFERENCES: "/account/preferences",
    PRODUCT: "/products",
    RESET: "/account/reset",
    REWARDS: "/account/rewards",
    SAVED: "/wishlist",
    SEARCH: "/search",
    STORE: "/stores",
    WISHLIST: "/shopping-list",
    REVIEWS: "/reviews",
    REVIEW_WRITE: "/review/write",
  },
}
