import { useConfigContext } from "@app/providers/config"
import { useCallback, useMemo } from "react"

type useBadgeTitlesProps = {
  allProductTags?: string[]
}

type useBadgeTitlesReturn = {
  badgeTitles: false | string[]
}

const useBadgeTitles = ({ allProductTags }: useBadgeTitlesProps): useBadgeTitlesReturn => {
  const {
    settings: {
      product: { tagBadgePrefix },
    },
  } = useConfigContext()

  if (!allProductTags?.length) {
    return {
      badgeTitles: false,
    }
  }

  // need to use regex since multiple tags contain 'badge', eg 'image-badge'. needs to start with 'badge:'.
  const regexString = `^${tagBadgePrefix}`
  const regexPattern = new RegExp(regexString)

  const badgeTags = allProductTags?.filter((tag: string) => typeof tag === "string" && tag?.match(regexPattern))
  const removePrefix = (tag: string) => tag?.split(tagBadgePrefix)?.[1]

  const badgeTitles = badgeTags?.map((tag: string) => removePrefix(tag))
  return { badgeTitles }
}

const useProductCardBadges = () => {
  const {
    settings: {
      product: { tagCardBadgePrefix, tagImageBadgePrefix },
    },
  } = useConfigContext()

  const tagMappings = useMemo(
    () => ({
      image: tagImageBadgePrefix,
      card: tagCardBadgePrefix,
    }),
    [tagCardBadgePrefix, tagImageBadgePrefix]
  )

  const getProductCardBadges = useCallback(
    ({ badgeType, allProductTags }) => {
      type tagMappingsKey = keyof typeof tagMappings

      const badgeTagPrefix = tagMappings[badgeType as tagMappingsKey]

      const salesBadges = allProductTags?.filter((tag: string) => tag?.includes(badgeTagPrefix))
      const sorderdBadges = salesBadges?.sort((a: string) => {
        // Sort badges to have SPECIAL ones appear 1st (take precendece over others)
        if (a.split(":")[2].toLocaleLowerCase() === "special") {
          return -1
        } else {
          return 1
        }
      })

      const badgeTag = sorderdBadges?.find((tag: string) => tag?.includes(badgeTagPrefix))
      const badgeParts = badgeTag ? badgeTag?.split(":") : false
      const showBadge = !!badgeTag && !!badgeParts

      return {
        showBadge,
        badge: {
          bgColor: badgeParts?.[1] || "brand.offWhite",
          text: badgeParts?.[2] || "",
        },
      }
    },
    [tagMappings]
  )

  return { getProductCardBadges }
}

export { useBadgeTitles, useProductCardBadges }
