import { Global as EmotionGlobal } from "@emotion/react"
import { fonts } from "./fonts"
import { swiper } from "./libs/swiper"
import { loyaltyLion } from "./libs/loyalty-lion"

import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

// See https://chakra-ui.com/docs/styled-system/recipes/using-fonts#option-2-using-font-face
export const Global: React.FC = () => (
  <EmotionGlobal
    styles={`
      ${fonts}
      ${swiper}
      ${loyaltyLion}
    `}
  />
)

export default Global
