import { useCallback, useMemo } from "react"
import { useRewardsContext } from "@app/providers/rewards"
import { useCore } from "@app/hooks/useCore"

const useRewards = () => {
  const {
    helpers: { isBrowser },
  } = useCore()
  const { loyaltyLionGlobalReady, loyaltyLionCustomer } = useRewardsContext()

  const checkIfShouldRefer = useCallback((text?: string): boolean => {
    if (!text) return false

    return text.toLowerCase()?.includes("refer")
  }, [])

  // This refreshes the calculated values from loyalty lion, for any component
  // Hidden in documentation here https://developers.loyaltylion.com/sdk/components/points-for-cart#live-updating
  const refreshRewardsUI = useCallback((): void => {
    if (loyaltyLionGlobalReady && isBrowser) {
      window?.loyaltylion?.ui?.refresh()
    }
  }, [loyaltyLionGlobalReady, isBrowser])

  const loyaltyTierName = useMemo((): string => loyaltyLionCustomer?.loyalty_tier_membership?.loyalty_tier?.name, [loyaltyLionCustomer])

  const customerPoints = useMemo((): number => loyaltyLionCustomer?.points_approved, [loyaltyLionCustomer])

  const openReferralModal = useCallback(() => {
    if (loyaltyLionGlobalReady && isBrowser) {
      window?.loyaltylion?.ui?.openReferralWidgetModal()
    }
  }, [isBrowser, loyaltyLionGlobalReady])

  return {
    checkIfShouldRefer,
    refreshRewardsUI,
    loyaltyTierName,
    customerPoints,
    openReferralModal,
  }
}

export { useRewards }
