import { useCallback, useEffect, useMemo, useState, useContext, createContext } from "react"
import { useConfigContext } from "@app/providers/config"
import { graphql, useStaticQuery } from "gatsby"
import { useCore, useStorage } from "@app/hooks/useCore"
import { v4 as uuid } from "uuid"
import { useCustomerContext } from "./customer"
import axios from "axios"
import deepEqual from "fast-deep-equal/react"

import type { Customer, Product, ProductVariant } from "shopify-storefront-api-typings"

// Note: the endpoints and some of the fields used here are the result of installing the wishlist plus integration onto
// a shopify theme, and reverse-engineering the network requests, since SWYM doesn't have the Shopify API publicly documented.
// As a result, there's no reference documentation for the endpoints etc below. Though some of the fields and parameters are shared
// with the admin documentation here https://api-docs.swym.it/v3/index.html#rest-apis-premium

const DEFAULT_WISHLIST_NAME = "Your Favourites"

const PATHS = {
  LIST_WITH_CONTENTS: "lists/fetch-list-with-contents",
  FETCH_LISTS: "lists/fetch-lists",
  UPDATE_MULTIPLE_CTX: "lists/update-multiple-ctx",
  CREATE_MULTIPLE: "lists/create-multiple",
  USER_VALIDATE_SYNC: "lists/user-validate-sync",
  MARK_PUBLIC: "lists/markPublic",
  UPDATE: "lists/update",
  DELETE_LIST: "lists/update",
} as const

// human readable field names
// see https://api-docs.swym.it/v3/index.html#fields-in-list-item
const LIST_ID = "lid"
const LIST_NAME = "lname"
const PRODUCT_ID = "empi"
const PRODUCT_CANONICAL_URL = "du"
const PRODUCT_TITLE = "dt"
const PRODUCT_IMG_URL = "iu"
const VARIANT_ID = "epi"
const COLLECTION_NAME = "ct"
const SELECTED_VARIANT_INFO = "vi"
const PRODUCT_PRICE = "pr"
const CUSTOM_FIELDS = "cprops"

type PostOptions = {
  version?: number
}

type WishListProduct = Product & {
  quantity: number
  selectedSku: string
  selectedTitle: string
}

type ContextProps = {
  fetched: boolean
  wishLists: SwymList[]
  sharedWishList: any
  count: number
  tryCreateWishList: (name?: string) => void
  checkIfInWishList: (product: any) => { isInWishList: boolean }
  addToWishList: (args: {
    productVariant: ProductVariant
    wishListId?: SwymList["lid"]
    product: Product
    showNotification?: boolean
  }) => void
  removeFromWishList: (args: { productVariant: any; wishListId?: SwymList["lid"]; product: any }) => void
  removeAllFromWishList: (wishListId?: SwymList["lid"]) => void
  renameWishList: (wishListId: SwymList["lid"], name: SwymList["lname"]) => void
  deleteWishList: (wishListId: SwymList["lid"]) => void
  shareWishList: (wishListId: SwymList["lid"]) => void
  // getShareUrl: (wishListId: string) => string
  // defaultShareUrl: string | null
  login: (customer: Customer) => Promise<boolean>
  logout: () => void
  fetchWishList: (listId: string) => void
}

const WishListContext = createContext<ContextProps | undefined>(undefined)

const WishListProvider: React.FC = ({ children }) => {
  // State setters
  const [fetched, setFetched] = useState<boolean>(false)
  const [wishLists, setWishLists] = useState<any>([])
  const [sharedWishList, setSharedWishList] = useState<any>(null)

  // External hook calls
  const {
    store,
    settings: { keys },
  } = useConfigContext()
  const {
    helpers: { decodeShopifyId, isBrowser, getUrlParameter },
  } = useCore()
  const { getStorage, setStorage, removeStorage } = useStorage()
  const { customer } = useCustomerContext()

  const { plugins } = useStaticQuery<GatsbyTypes.SwymWishListQuery>(graphql`
    query SwymWishList {
      plugins: sanitySettingPlugins {
        swymWishlistPlusHost
        swymWishlistPlusPid
      }
    }
  `)

  const hasMissingSwymKeys = useMemo(() => !plugins?.swymWishlistPlusHost || !plugins?.swymWishlistPlusPid, [plugins])

  const swymWishlistPlusPid = useMemo(
    () => (plugins?.swymWishlistPlusPid ? encodeURIComponent(plugins?.swymWishlistPlusPid) : null),
    [plugins?.swymWishlistPlusPid]
  )

  const setUserId = useCallback((userId: string) => setStorage(keys.wishListUserId, userId), [keys, setStorage])

  const getUserId = useCallback(async () => {
    if (!isBrowser || hasMissingSwymKeys) return null

    try {
      const id = getStorage(keys.wishListUserId)
      if (id) return id

      // ? checkAndGet ?
      const endpoint = `${plugins?.swymWishlistPlusHost}/v3/provider/checkAndGet?pid=${swymWishlistPlusPid}`

      const response = await axios.post(
        endpoint,
        new URLSearchParams({
          js_v: "3.0.6", // ?
        })
      )

      const newId = response?.data?.get?.regid

      if (newId) {
        setUserId(newId)
        return newId
      }
    } catch (e) {
      console.warn(e)
    }

    return null
  }, [getStorage, hasMissingSwymKeys, isBrowser, keys, plugins, setUserId, swymWishlistPlusPid])

  const post = useCallback(
    async ({ path, data = {}, options = {} }: { path: string; data?: any; options?: PostOptions }) => {
      if (hasMissingSwymKeys) return null

      const endpoint = `${plugins?.swymWishlistPlusHost}/v${options.version || 3}/${path}?pid=${swymWishlistPlusPid}`

      const regid = await getUserId()
      const sessionid = uuid()

      try {
        const response = await axios.post(
          endpoint,
          new URLSearchParams({
            regid, // ?
            sessionid,
            ...data,
          })
        )

        return response
      } catch (e) {
        return null
      }
    },
    [getUserId, hasMissingSwymKeys, plugins, swymWishlistPlusPid]
  )

  const count = useMemo(() => {
    return wishLists?.reduce((sum: number, list: any) => sum + (list?.listcontents?.length ? list?.listcontents?.length : 0), 0)
  }, [wishLists])

  const fetchWishLists: any = useCallback(async () => {
    const response = await post({ path: PATHS.FETCH_LISTS })

    const sortedLists = response?.data
      ?.reverse()
      ?.map((wishList: any) => ({
        ...wishList,
        default: wishList?.[LIST_NAME] === DEFAULT_WISHLIST_NAME,
      }))
      ?.sort((a: any, b: any) => {
        if (a?.[LIST_NAME] === DEFAULT_WISHLIST_NAME && b?.[LIST_NAME] !== DEFAULT_WISHLIST_NAME) return -1
        else if (a?.[LIST_NAME] !== DEFAULT_WISHLIST_NAME && b?.[LIST_NAME] === DEFAULT_WISHLIST_NAME) return 1
        return 0
      })

    return sortedLists
  }, [post])

  const fetchWishList = useCallback(
    async (wishListId: string) => {
      const fetchedWishLists = await fetchWishLists()
      return fetchedWishLists?.find((list: any) => list?.[LIST_ID] === wishListId)
    },
    [fetchWishLists]
  )

  const fetchDefaultWishList: any = useCallback(async () => {
    const fetchedWishLists = await fetchWishLists()
    return fetchedWishLists?.find((list: any) => list?.[LIST_NAME] === DEFAULT_WISHLIST_NAME) || fetchedWishLists?.[0]
  }, [fetchWishLists])

  const fetchSharedWishList: any = useCallback(
    async (wishListId: any) => {
      const response = await post({
        path: PATHS.LIST_WITH_CONTENTS,
        data: {
          [LIST_ID]: wishListId,
        },
      })

      return {
        ...response?.data?.list,
        shared: true,
      }
    },
    [post]
  )

  const refreshWishLists = useCallback(async () => {
    const fetchedWishLists = await fetchWishLists()
    if (!deepEqual(wishLists, fetchedWishLists)) {
      setWishLists(fetchedWishLists)
    }
    setFetched(true)
  }, [fetchWishLists, wishLists])

  const tryCreateWishList = useCallback(
    async (name?: string) => {
      const lists = await fetchWishLists()

      const lastUnnamedWishListNumber = lists
        ?.map((list: any) => {
          return list?.[LIST_NAME]?.includes("WishList-") ? parseInt(list?.[LIST_NAME]?.split("-")?.[1]) || 0 : 0
        })
        .reduce((max: number, cur: number) => {
          return Math.max(max, cur)
        }, 0)

      await post({
        path: PATHS.CREATE_MULTIPLE,
        data: {
          lists: JSON.stringify([
            {
              [LIST_NAME]: name ? name : `WishList-${lastUnnamedWishListNumber + 1}`,
            },
          ]),
        },
      })

      await refreshWishLists()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const tryCreateDefaultList = useCallback(async () => {
    const list = await fetchDefaultWishList()
    if (list) return list?.[LIST_ID]

    const response = await post({
      path: PATHS.CREATE_MULTIPLE,
      data: {
        lists: JSON.stringify([
          {
            [LIST_NAME]: DEFAULT_WISHLIST_NAME,
          },
        ]),
      },
    })

    const listId = response?.data?.[0]?.[LIST_ID]

    return listId
  }, [fetchDefaultWishList, post])

  const checkIfInWishList = useCallback(
    (productVariant: any) => {
      const variantId = decodeShopifyId(productVariant?.id, "ProductVariant")

      if (!variantId) return { isInWishList: false }

      const isInWishList = !!wishLists?.find((list: any) =>
        list?.listcontents?.find((item: any) => item?.[VARIANT_ID] === parseInt(variantId))
      )

      return { isInWishList }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wishLists]
  )

  const addToWishList = useCallback(async ({ productVariant, wishListId = null, product }) => {
    const productId = decodeShopifyId(product?.id, "Product")
    const variantId = decodeShopifyId(productVariant?.id, "ProductVariant")
    if (!productId || !variantId) return false

    const listId = wishListId ? wishListId : await tryCreateDefaultList()
    if (listId === null) return false
    let price = null
    try {
      price = parseFloat(productVariant.priceV2?.amount)
    } catch (e) {
      // ignored
    }

    await post({
      path: PATHS.UPDATE_MULTIPLE_CTX,
      data: {
        listItem: JSON.stringify({
          [PRODUCT_CANONICAL_URL]: `https://${store.url}/products/${product.handle}`,
          [PRODUCT_TITLE]: product.title,
          [PRODUCT_IMG_URL]: product?.featuredImage?.originalSrc || product?.images?.[0]?.src || productVariant?.image?.src,
          [PRODUCT_ID]: productId,
          [VARIANT_ID]: variantId,
          [COLLECTION_NAME]: "",
          [SELECTED_VARIANT_INFO]: "",
          [PRODUCT_PRICE]: price,
          _cv: true, // ?
          // Any custom fields can be mapped onto the product object
          [CUSTOM_FIELDS]: {
            availableForSale: productVariant?.availableForSale,
            productTags: product?.tags,
            productType: product?.productType,
          },
        }),
        a: JSON.stringify([listId]),
      },
    })
    await refreshWishLists()
    return true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const removeFromWishList = useCallback(
    async ({ productVariant, wishListId = null, product }) => {
      const list = await (wishListId ? fetchWishList(wishListId) : fetchDefaultWishList())

      const productId = parseInt(decodeShopifyId(product?.id, "Product"))
      const variantId = parseInt(decodeShopifyId(productVariant?.id, "ProductVariant"))

      if (!productId || !variantId) return false

      const listsContainVariant = list?.listcontents?.find((item: any) => item?.[VARIANT_ID] === variantId)?.[VARIANT_ID]
      if (!listsContainVariant) return false

      await post({
        path: PATHS.UPDATE_MULTIPLE_CTX,
        data: {
          listItem: JSON.stringify({
            [PRODUCT_CANONICAL_URL]: `https://${store.url}/products/${product.handle}`,
            [PRODUCT_ID]: productId,
            [VARIANT_ID]: variantId,
          }),
          d: JSON.stringify([list?.[LIST_ID]]),
        },
      })

      await refreshWishLists()
      return true
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const removeAllFromWishList = useCallback(async (wishListId?: any) => {
    const list = await fetchWishList(wishListId)
    if (list === null) return false

    for (const item of list.listcontents) {
      await post({
        path: PATHS.UPDATE_MULTIPLE_CTX,
        data: JSON.stringify({
          listItem: {
            [PRODUCT_CANONICAL_URL]: item?.[PRODUCT_CANONICAL_URL],
            [PRODUCT_ID]: item?.[PRODUCT_ID],
            [VARIANT_ID]: item?.[VARIANT_ID],
          },
          d: [list?.[LIST_ID]], // ?
        }),
      })
    }
    await refreshWishLists()
    return true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renameWishList = useCallback(async (wishListId: string, wishListName: string) => {
    const list = await fetchWishList(wishListId)
    if (!list) return null

    await post({
      path: PATHS.UPDATE,
      data: {
        [LIST_ID]: list?.[LIST_ID],
        [LIST_NAME]: wishListName,
      },
    })
    await refreshWishLists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteWishList = useCallback(async (wishListId: string) => {
    const lists = await fetchWishLists()

    if (lists?.length) {
      await post({
        path: PATHS.DELETE_LIST,
        data: {
          [LIST_ID]: wishListId,
        },
      })
      await refreshWishLists()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const shareWishList = useCallback(async (wishListId: string) => {
    await post({
      path: PATHS.MARK_PUBLIC,
      data: {
        [LIST_ID]: wishListId,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const getShareUrl = useCallback(
  //   (wishListId: string) => {
  //     return `https://${store.url}${routes.WISHLIST}?sharedlist=${wishListId}`
  //   },
  //   [routes.WISHLIST, store?.url]
  // )

  // const defaultShareUrl = useMemo(() => {
  //   const defaultList = wishLists?.find((list: any) => list?.[LIST_NAME] === DEFAULT_WISHLIST_NAME)
  //   return defaultList ? getShareUrl(defaultList?.[LIST_ID]) : null
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [wishLists])

  const login = useCallback(async (customer: Customer) => {
    const shopifyId = decodeShopifyId(customer?.id, "Customer")
    if (!shopifyId) return false

    const userId = (
      await post({
        path: PATHS.USER_VALIDATE_SYNC,
        data: {
          platform: "shopify",
          extuid: shopifyId, // ?
        },
      })
    )?.data?.regid

    if (!userId) return false

    setUserId(userId)
    await refreshWishLists()
    return true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const logout = useCallback(async () => {
    removeStorage(keys.wishListUserId)
    await refreshWishLists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const initWishLists = async () => {
      const fetchedWishLists = await fetchWishLists()
      if (!deepEqual(wishLists, fetchedWishLists)) {
        setWishLists(fetchedWishLists)
      }

      const swymListId = getUrlParameter("swymListId")
      if (swymListId) {
        const sharedLists = await fetchSharedWishList(swymListId)
        setSharedWishList(sharedLists)
      }

      setFetched(true)
    }

    initWishLists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (customer) login(customer)
  }, [customer, login])

  const hasWishLists = useMemo(() => !!wishLists?.length, [wishLists?.length])

  const contextValue = useMemo<ContextProps>(
    () => ({
      fetched,
      wishLists,
      sharedWishList,
      count,
      tryCreateWishList,
      checkIfInWishList,
      addToWishList,
      removeFromWishList,
      removeAllFromWishList,
      renameWishList,
      deleteWishList,
      shareWishList,
      // getShareUrl,
      // defaultShareUrl,
      login,
      logout,
      hasWishLists,
      fetchWishList,
    }),
    [
      fetched,
      wishLists,
      sharedWishList,
      count,
      tryCreateWishList,
      checkIfInWishList,
      addToWishList,
      removeFromWishList,
      removeAllFromWishList,
      renameWishList,
      deleteWishList,
      shareWishList,
      // getShareUrl,
      // defaultShareUrl,
      login,
      logout,
      hasWishLists,
      fetchWishList,
    ]
  )

  return <WishListContext.Provider value={contextValue}>{children}</WishListContext.Provider>
}

const useWishListContext = (): ContextProps => ({ ...useContext(WishListContext) } as ContextProps)

export { WishListContext, useWishListContext, WishListProvider }
export type { WishListProduct, PostOptions, ContextProps }
