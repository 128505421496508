import { memo, useMemo, useEffect } from "react"
import { Box, Text, HStack } from "@chakra-ui/react"
import { CartItemMini } from "./CartItemMini"
import { CartItemMain } from "./CartItemMain"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { useCore } from "@app/hooks/useCore"
import { useMedia } from "@app/hooks/useMedia"
// import { useCart } from "@app/hooks/useCart"
import { useDeepDependency } from "@app/hooks/useDeepDependency"
import { useCartContext } from "@app/providers/cart"
import type { CartLine } from "@shopify/hydrogen-react/storefront-api-types"

type CartListProps = {
  isMain?: boolean
}

const desktopColumns = [
  {
    title: "product",
    width: "50%",
  },
  {
    title: "price",
    width: "15%",
  },
  {
    title: "quantity",
    width: "25%",
  },
  {
    title: "total",
    width: "10%",
  },
]

const CartList: React.FC<CartListProps> = ({ isMain }) => {
  const { cart, count } = useCartContext()
  // Find any products with `heat-sensitive` tag applied
  const tempControlledLineItems = cart?.lines?.filter(item =>
    item?.merchandise?.product?.tags?.find((tag: any) => tag.toLowerCase() === "heat-sensitive")
  )

  useEffect(() => {
    if (tempControlledLineItems && !tempControlledLineItems?.length) {
      window?.sessionStorage?.removeItem("temp-controlled-pouch-removed")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempControlledLineItems?.length])

  const locales = useLocale(LOCALE_KEYS.CART)
  const {
    helpers: { ErrorBoundary },
  } = useCore()
  const { isSmall } = useMedia()
  const lineItemsRef = useDeepDependency(cart?.lines)

  // By default, the checkout adds new items to the array. client would like most recently
  // added items to appear at top
  const sortedLineItems = useMemo(
    // slice is needed to prevent mutation which leads to back/forward bugs
    (): CartLine[] => {
      // eslint-disable-next-line no-unsafe-optional-chaining
      return lineItemsRef?.current?.length ? [...lineItemsRef?.current]?.reverse() : []
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lineItemsRef?.current]
  )

  if (!count) return <Text>{locales?.cartEmpty}</Text>

  return (
    <ErrorBoundary>
      {isMain && isSmall ? (
        <Box>
          <HStack justifyContent="space-between" borderTop={["none", "none", "2px"]} borderBottom={["none", "none", "1px"]} py={5}>
            {desktopColumns.map(({ title, width }, idx) => {
              const isLastColumn = idx === desktopColumns?.length - 1

              return <Box key={title} fontWeight={700} width={width} textAlign={isLastColumn ? "right" : "left"}></Box>
            })}
          </HStack>
          {sortedLineItems?.map(item => (
            <CartItemMain key={item?.id} item={item} columns={desktopColumns} />
          ))}
        </Box>
      ) : (
        <Box>
          {sortedLineItems?.map(item => (
            <CartItemMini key={item?.id} item={item} renderedFromMain={isMain} />
          ))}
        </Box>
      )}
    </ErrorBoundary>
  )
}

const MemoCartList = memo(CartList)
export { MemoCartList as CartList }
export type { desktopColumns }
