import { memo, useEffect, useState } from "react"
import { Container, Box, Center, HStack } from "@chakra-ui/react"
import Headroom from "react-headroom"
import { SiteLogo } from "@app/components/SiteLogo"
import { CartWidget } from "@components/Cart/CartWidget"
import { WishListWidget } from "@components/WishList/WishListWidget"
import { AccountWidget } from "@components/Account/Widget/AccountWidget"
import { SearchWidgetAlt } from "@components/Search/Widget/SearchWidgetAlt"
import { NavigationHeaderWidget } from "@components/Navigation/Header/NavigationHeaderWidget"
import NavigationHeader from "@components/Navigation/Header/NavigationHeader"

import type { Location } from "@root/types/global"
import { useAppContext } from "@app/providers/app"

type HeaderProps = {
  location: Location
}

const Header: React.FC<HeaderProps> = ({ location }) => {
  const { headerRef } = useAppContext()
  const [isPinned, setIsPinned] = useState(false)

  useEffect(() => {
    isPinned
      ? document.documentElement.style.setProperty("--pinned-offset", `110px`)
      : document.documentElement.style.setProperty("--pinned-offset", `0px`)
  }, [isPinned])

  return (
    <>
      <Headroom
        style={{ zIndex: 11 }}
        // onPin={() => setIsPinned(true)}
        onUnpin={() => setIsPinned(false)}
        onUnfix={() => setIsPinned(false)}
        downTolerance={50}
      >
        <Box as="nav" ref={headerRef} borderBottom="1px">
          <Box bg="brand.offWhite" borderBottom={{ lg: "1px" }}>
            <Container as="header">
              <HStack pt={[5, 5, 4]} pb={[4.5, 4.5, 4]} justifyContent="space-between" spacing={9}>
                <HStack spacing={5}>
                  <NavigationHeaderWidget />
                  <SiteLogo />
                </HStack>

                <Box width="800px" justifySelf="center" display={["none", "none", "block"]}>
                  <SearchWidgetAlt location={location} isPinned={isPinned} />
                </Box>
                <HStack spacing={[4, 4, 7]}>
                  <AccountWidget />
                  <WishListWidget />
                  <CartWidget />
                </HStack>
              </HStack>

              <Box pb={4} display={["block", "block", "none"]}>
                <SearchWidgetAlt location={location} isPinned={isPinned} />
              </Box>
            </Container>
          </Box>

          <Box bg="brand.offWhite" display={["none", "none", "block"]}>
            <Container as="header" p={[0, 0]} pb={{ base: 4, lg: 0 }}>
              <Center>
                <NavigationHeader location={location} />
              </Center>
            </Container>
          </Box>
        </Box>
      </Headroom>
    </>
  )
}

const MemoHeader = memo(Header)
export { MemoHeader as Header }
