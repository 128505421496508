import { memo } from "react"
import { Accordion, AccordionItem, AccordionPanel, AccordionButton, AccordionIcon, Stack, Box, Text } from "@chakra-ui/react"
import { useNavigation } from "@app/hooks/useNavigation"
import { CustomLink } from "@components/Link"
import { SocialMedia } from "@components/SocialMedia"

const NavigationFooter: React.FC = () => {
  const { footer: items } = useNavigation()

  return (
    <>
      <Accordion allowToggle>
        {items.map(({ type, title, url, items }, index) =>
          type !== "sub" ? (
            <Text key={index} color="inherit">
              <CustomLink
                to={url}
                styles={{
                  fontWeight: 700,
                  color: "inherit",
                  fontFamily: "heading",
                }}
              >
                {title}
              </CustomLink>
            </Text>
          ) : (
            <AccordionItem key={index} borderTop={index === 0 ? "none" : "brand.offWhite"}>
              <Text fontWeight={700} color="inherit" fontFamily="heading" fontSize="xl">
                <AccordionButton pl={0} pt={4} pb={3}>
                  <Box flex="1" textAlign="left" fontSize="xl">
                    {title}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </Text>
              <AccordionPanel pb={5} pl={2}>
                {!!items?.length && (
                  <Stack>
                    {items.map(({ title, url }, index) => (
                      <Text key={index} color="inherit">
                        <CustomLink to={url} styles={{ fontSize: "md" }}>
                          {title}
                        </CustomLink>
                      </Text>
                    ))}
                  </Stack>
                )}
              </AccordionPanel>
            </AccordionItem>
          )
        )}
      </Accordion>
      <Box mt={6} mb={10}>
        <SocialMedia />
      </Box>
    </>
  )
}

export default memo(NavigationFooter)
