import { Container, Text, Center } from "@chakra-ui/react"

type Props = {
  isEnabled?: boolean
  text?: string
}

export const FooterAcknowledgement: React.FC<Props> = ({ isEnabled, text }) => {
  if (!isEnabled) return null

  return (
    <Container bg="brand.lightSand" py={[7, 7]} textAlign={["left", "center"]} variant="full">
      <Center>
        <Text fontSize="sm" maxWidth={["100%", "930px"]} lineHeight="21px">
          {text}
        </Text>
      </Center>
    </Container>
  )
}
