const baseStyleContainer = {
  borderRadius: "68px",
  minHeight: "25px",
  minWidth: "8ch",
  justifyContent: "center",
  border: "1px",
}

const baseStyleLabel = {}

const baseStyleCloseButton = {
  fill: "white",
  h: 2,
  w: 2,
  ml: 1.5,
  opacity: 1,
}

const baseStyle = {
  container: baseStyleContainer,
  label: baseStyleLabel,
  closeButton: baseStyleCloseButton,
}

const unstyledVariant = {
  container: {
    bgColor: "unset",
  },
}

const variantDate = {
  container: {
    bgColor: "transparent",
    textTransform: "uppercase",
    paddingX: 2,
    fontSize: "xxs",
  },
}

const variantSubcategory = {
  container: {
    fontSize: ["xs", "sm"],
    py: 1.5,
    px: 4,
    bg: "brand.offWhite",
    border: "1px",
    minH: 8,
  },
  label: {
    lineHeight: 1,
  },
}

const variantSubcategoryBrands = {
  ...variantSubcategory,
  container: {
    ...variantSubcategory?.container,
    p: 0,
    w: 10,
  },
}

const variantSubcategoryActive = {
  container: {
    ...variantSubcategory.container,
    bg: "brand.avocado",
    color: "brand.offWhite",
    borderColor: "brand.avocado",
  },
  label: {
    ...variantSubcategory.label,
  },
}

const variantSubcategoryActiveBrands = {
  ...variantSubcategoryActive,
}

const variantProductCard = {
  container: {
    fontSize: "xxs",
    border: "none",
    textTransform: "uppercase",
    paddingX: 2,
    whiteSpace: "nowrap",
  },
}

const variantPdpDescription = {
  container: {
    fontSize: "xs",
    height: 6,
  },
}

const variantNutritionistHero = {
  container: {
    bg: "brand.offWhite",
    fontSize: ["xs", "sm"],
    py: 2,
    px: 4,
  },
}

const variantSearchSuggested = {
  container: {
    py: 1.5,
    px: 3.5,
    fontSize: "sm",
    bg: "brand.offWhite",
  },
}

const variantBenefits = {
  container: {
    fontSize: ["xxs", "xs"],
    border: "none",
    px: 2,
  },
}

const variantTwoColumns = {
  container: {
    minH: 8,
    px: 4,
    fontSize: "sm",
  },
}

const variantArticleHero = {
  container: {
    minH: 8,
    px: 4,
    fontSize: "sm",
    bg: "brand.offWhite",
    border: "1px",
  },
}

const variantPdpImage = {
  container: {
    textTransform: "uppercase",
    minH: [7, 9],
    fontSize: ["md", "lg"],
    border: "none",
    px: ["13px", "15px"],
    fontWeight: 400,
  },
}

const variantPdpCard = {
  container: {
    textTransform: "uppercase",
    fontSize: ["11px", "13px"],
    minH: ["25px", "29px"],
    px: 2.5,
    border: "none",
    fontWeight: 400,
  },
}

const variants = {
  date: variantDate,
  subcategory: variantSubcategory,
  "subcategory-active": variantSubcategoryActive,
  "product-card": variantProductCard,
  "pdp-image": variantPdpImage,
  "pdp-card": variantPdpCard,
  "pdp-description": variantPdpDescription,
  "nutritionist-hero": variantNutritionistHero,
  "search-suggested": variantSearchSuggested,
  "two-columns": variantTwoColumns,
  benefits: variantBenefits,
  unstyled: unstyledVariant,
  "article-hero": variantArticleHero,
  brand: variantSubcategoryBrands,
  "brand-active": variantSubcategoryActiveBrands,
}

const sizes = {
  sm: {
    container: {
      minW: 14,
    },
  },
}

const defaultProps = {
  variant: "unstyled",
  size: "sm",
}

export const Tag = {
  variants,
  baseStyle,
  sizes,
  defaultProps,
}
