import { useMemo } from "react"
import { useCore } from "@app/hooks/useCore"
import { useShopify } from "./useShopify"

import type { ProductVariant } from "shopify-storefront-api-typings"
import { useCartContext } from "@app/providers/cart"

const getPrice = ({ variant, quantity }: any) => {
  if (variant?.priceV2?.amount) {
    return Number(variant?.priceV2?.amount) * quantity
  }

  if (variant?.priceV2) {
    return Number(variant?.priceV2) * quantity
  }

  return 0
}

const useShopifyPrice = (variant: ProductVariant, quantity = 1) => {
  const { currencyCode: checkoutCurrency } = useCartContext()
  const { formatMoney } = useShopify()

  return useMemo(() => {
    if (!variant || !checkoutCurrency) {
      return {
        onSale: false,
        price: undefined,
        currencyCode: "AUD",
        formattedPrice: undefined,
        compareAtPrice: undefined,
        formattedCompareAtPrice: undefined,
      }
    }

    const currencyCode = checkoutCurrency ?? "AUD"

    const price = getPrice({ variant, quantity })

    const compareAtPrice = variant?.compareAtPriceV2?.amount ? Number(variant?.compareAtPriceV2?.amount) * quantity : 0
    const onSale = !!compareAtPrice && !!price && compareAtPrice > price
    const formattedPrice = formatMoney(price, currencyCode)
    const formattedCompareAtPrice = formatMoney(compareAtPrice, currencyCode)

    return {
      price,
      onSale,
      currencyCode,
      compareAtPrice,
      formattedPrice,
      formattedCompareAtPrice,
    }
  }, [variant, checkoutCurrency, formatMoney, quantity])
}

const useShopifyPriceRange = (product: any, separator = " to ") => {
  const { currencyCode: checkoutCurrency } = useCartContext()
  const { formatMoney } = useShopify()
  const {
    helpers: { formatPrice },
  } = useCore()

  return useMemo(() => {
    if (!product || !checkoutCurrency) {
      return {
        priceMin: undefined,
        priceMax: undefined,
        currencyCode: "AUD",
        formattedPriceRange: undefined,
      }
    }

    const currencyCode = checkoutCurrency ?? "AUD"
    const priceRangeMin = product?.priceRange?.minVariantPrice || 0
    const priceRangeMax = product?.priceRange?.maxVariantPrice || 0
    const priceMin = priceRangeMin?.amount ? Number(priceRangeMin?.amount) : 0
    const priceMax = priceRangeMax?.amount ? Number(priceRangeMax?.amount) : 0
    const formattedPriceRange =
      priceMin < priceMax && priceMin > 0
        ? `${formatMoney(priceMin, currencyCode)}${separator}${formatPrice(`${priceMax}`)}`
        : formatMoney(priceMax, currencyCode)

    return {
      priceMin,
      priceMax,
      currencyCode,
      formattedPriceRange,
    }
  }, [product, checkoutCurrency, formatMoney, separator, formatPrice])
}

export { useShopifyPrice, useShopifyPriceRange }
