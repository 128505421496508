const baseStyle = {
  track: {
    height: "100px",
    bg: "brand.offWhite",
  },
  filledTrack: {
    bg: "brand.peachy",
  },
}

const variants = {}

const sizes = {}

const defaultProps = {}

export const Progress = {
  baseStyle,
  variants,
  sizes,
  defaultProps,
}
