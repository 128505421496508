import { useCallback, useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useStorage } from "@app/hooks/useCore"
import { useAppContext } from "@app/providers/app"
import { useFunctions } from "@app/hooks/useFunctions"
import { useConfigContext } from "@app/providers/config"

import type { Location } from "@root/types/global"

const INITIAL_STATE = { email: "", firstName: "" }

const useNewsletter = (location: Location, isPopup = false) => {
  const [data, setData] = useState(INITIAL_STATE)
  const [success, setSuccess] = useState(false)
  const { state, dispatch } = useAppContext()
  const {
    settings: { keys, routes, functions },
  } = useConfigContext()
  const { getStorage, setStorage } = useStorage()
  const { callFunction, errors, loading } = useFunctions()

  const { newsletter } = useStaticQuery<GatsbyTypes.StaticNewsletterQuery>(graphql`
    query StaticNewsletter {
      newsletter: sanitySettingNewsletter {
        enabled
        delay
        expiry
        templateHomepage
        templateCollection
        templateProduct
        templateArticle
        additionalTitle
        additionalPlaceholder
        additionalSuccess
      }
    }
  `)

  const route =
    Object.entries(routes)?.find(
      ([route, url]) => !route?.toLowerCase()?.includes("page") && url && location?.pathname?.includes(url)
    )?.[0] ||
    (location?.pathname === routes.HOMEPAGE && "HOMEPAGE")

  const active =
    // @ts-ignore
    (newsletter?.enabled && route && newsletter[`template${route?.charAt(0)?.toUpperCase() + route?.slice(1)?.toLowerCase()}`]) || false

  const handleActiveSubscribe = useCallback(
    (state: boolean) => {
      dispatch({
        type: "subscribe",
        payload: state,
      })
    },
    [dispatch]
  )

  const handleChange = useCallback(
    ({ target: { type, name, value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
      setData(prevState => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }))
    },
    [setData]
  )

  const handleClose = useCallback(() => handleActiveSubscribe(false), [handleActiveSubscribe])

  const handleSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault()
      // newsletter tag is needed https://help.klaviyo.com/hc/en-us/articles/115005080667-How-to-Sync-Shopify-Email-Subscribers-to-a-Klaviyo-List
      const response = await callFunction(functions.customerSubscribe, {
        ...data,
        emailMarketingConsent: { marketingOptInLevel: "SINGLE_OPT_IN", marketingState: "SUBSCRIBED" },
        tags: ["newsletter"],
      })

      const isSuccess = response.status === "success"

      setSuccess("success" === response.status)

      if (isSuccess && isPopup) {
        handleClose()
      }

      return { customer: response.body }
    },
    [callFunction, data, setSuccess, handleClose, functions, isPopup]
  )

  useEffect(() => {
    let timeout: any = null

    if (active && !getStorage(keys.newsletter) && !state.activeSubscribe) {
      timeout = setTimeout(() => {
        setStorage(keys.newsletter, true, newsletter?.expiry)
        handleActiveSubscribe(true)
      }, (newsletter?.delay || 0) * 1000)
    }

    return () => (timeout ? clearTimeout(timeout) : null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  return {
    data,
    errors,
    loading,
    success,
    newsletter,
    handleClose,
    handleSubmit,
    handleChange,
    activeSubscribe: state.activeSubscribe,
  }
}

export { useNewsletter }
