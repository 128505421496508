import { memo } from "react"
import { SubscribeForm } from "./SubscribeForm"
import { Stack, Text, Container, Box, Heading } from "@chakra-ui/react"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"

import type { Location } from "@root/types/global"

type SubscribeBannerProps = {
  location: Location
}

const SubscribeBanner: React.FC<SubscribeBannerProps> = ({ location }) => {
  const locales = useLocale(LOCALE_KEYS.SUBSCRIBE)

  const bannerHeading = locales?.subscribeBannerHeading || ""
  const bannerText = locales?.subscribeBannerText || ""

  return (
    <Box bg="brand.peachy" color="brand.avocado">
      <Container pt={[9, 14]} pb={[12, 14]}>
        <Stack spacing={[4, 4, 4, 12]} direction={["column", "column", "column", "row"]} justifyContent="space-between">
          <Box mb={[4]}>
            <Heading as="p" size="h3">
              {bannerHeading}
            </Heading>
            <Text mt={2}>{bannerText}</Text>
          </Box>
          <Stack spacing={0} direction={["column", "column", "row"]} alignItems={["stretch", "stretch", "center"]}>
            <SubscribeForm minimal location={location} height={12} fontSize="sm" _placeholder={{ color: "brand.avocado" }} />
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

const MemoSubscribeBanner = memo(SubscribeBanner)
export { MemoSubscribeBanner as SubscribeBanner }
