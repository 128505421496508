import { useCallback } from "react"
import { format, parseISO } from "date-fns"

const useDate = () => {
  const getDayMonthLabel = useCallback((dateTime: string, formatter?: string, uppercase?: boolean) => {
    if (!dateTime)
      return {
        dayMonthLabel: null,
      }

    // https://date-fns.org/v2.28.0/docs/format
    const FNS_DAY_MONTH = formatter || "d LLL"

    const parsedLabel = format(parseISO(dateTime), FNS_DAY_MONTH)
    const dayMonthLabel = (uppercase ? parsedLabel.toUpperCase() : parsedLabel) || {}

    return {
      dayMonthLabel,
    }
  }, [])

  const getArticleDate = useCallback((dateTime: string) => {
    if (!dateTime)
      return {
        formattedDate: null,
      }

    const FNS_DATE_FORMAT = "d.MM.yy"

    const formattedDate = format(parseISO(dateTime), FNS_DATE_FORMAT)

    return {
      formattedDate,
    }
  }, [])

  const timeAgo = useCallback((dateParam: string, unit: string, time: number) => {
    if (!dateParam || !unit || !time) {
      return null
    }
    const date = typeof dateParam === "object" ? dateParam : new Date(dateParam)
    const today = new Date()
    // const yesterday = new Date(today - DAY_IN_MS)
    const seconds = Math.round((today - date) / 1000)
    const minutes = Math.round(seconds / 60)
    // const isToday = today.toDateString() === date.toDateString()
    // const isYesterday = yesterday.toDateString() === date.toDateString()
    // const isThisYear = today.getFullYear() === date.getFullYear()

    if (unit === "seconds" && seconds > time) {
      return true
    } else if (unit === "minutes" && minutes > time) {
      return true
    }
    return false
  }, [])

  return {
    getArticleDate,
    getDayMonthLabel,
    timeAgo,
  }
}

export { useDate }
