import { useForm, useController } from "react-hook-form"
import { FormControl, FormErrorMessage, Input, Textarea } from "@chakra-ui/react"
import { CustomSelect } from "@components/Select"
import { nanoid } from "nanoid"

import type { Option } from "@components/Select"

// TODO: add types here https://codesandbox.io/s/chakra-react-select-react-hook-form-usecontroller-typescript-qcm23?file=/app.tsx
type ControlledSelectProps = {
  options: Array<Option>
  placeholder: string
  name: string
  control: any
  setData: any
  formTypeTitle: string
}

// Import these mappings to pass as the `type` prop to `ControlledInput`
const FIELD_TYPES = {
  ADDRESS: "address",
  BIRTHDAY: "birthday",
  COMPANY: "company",
  EMAIL: "email",
  FIRST_NAME: "firstName",
  FULL_NAME: "name",
  LAST_NAME: "lastName",
  MESSAGE: "message",
  NUMBER_OF_BOXES: "numberOfBoxes",
  DELIVERY_DATE: "deliveryDate",
  PASSWORD: "password",
  PHONE: "phone",
  REVIEW_NAME: "reviewName",
  REVIEW_TITLE: "reviewTitle",
  REVIEW_MESSAGE: "reviewMessage",
  QUESTION: "question",
}

// Unified mappings of FIELD_TYPES for reuse throughout codebase
const FIELD_INFO = {
  [FIELD_TYPES.ADDRESS]: {
    name: FIELD_TYPES.ADDRESS,
    type: "text",
    placeholder: "Address",
  },
  [FIELD_TYPES.EMAIL]: {
    name: FIELD_TYPES.EMAIL,
    type: "text",
    placeholder: "Email",
    validation: {
      required: "Email is required",
      pattern: { value: /^\S+@\S+$/i, message: "Please enter a valid Email" },
    },
  },
  [FIELD_TYPES.BIRTHDAY]: {
    name: FIELD_TYPES.BIRTHDAY,
    type: "text",
    placeholder: "Date of birth",
    validation: {
      maxLength: { value: 50, message: "Can't be longer than 50 characters" },
    },
  },
  [FIELD_TYPES.COMPANY]: {
    name: FIELD_TYPES.COMPANY,
    type: "text",
    placeholder: "Company",
  },
  [FIELD_TYPES.FIRST_NAME]: {
    name: FIELD_TYPES.FIRST_NAME,
    type: "text",
    placeholder: "First Name",
    validation: { required: "First Name is required" },
  },
  [FIELD_TYPES.LAST_NAME]: {
    name: FIELD_TYPES.LAST_NAME,
    type: "text",
    placeholder: "Last Name",
    validation: { required: "Last Name is required" },
  },
  [FIELD_TYPES.NUMBER_OF_BOXES]: {
    name: FIELD_TYPES.NUMBER_OF_BOXES,
    type: "text",
    placeholder: "Number of Boxes",
  },
  [FIELD_TYPES.DELIVERY_DATE]: {
    name: FIELD_TYPES.DELIVERY_DATE,
    type: "text",
    placeholder: "DD//MM/YYYY",
  },
  [FIELD_TYPES.PASSWORD]: {
    name: FIELD_TYPES.PASSWORD,
    type: "password",
    placeholder: "Password",
    validation: { required: "Password is required" },
  },
  [FIELD_TYPES.PHONE]: {
    name: FIELD_TYPES.PHONE,
    type: "text",
    placeholder: "Phone Number",
  },
  [FIELD_TYPES.FULL_NAME]: {
    name: FIELD_TYPES.FULL_NAME,
    type: "text",
    placeholder: "Name",
  },
  [FIELD_TYPES.MESSAGE]: {
    name: FIELD_TYPES.MESSAGE,
    type: "textarea",
    placeholder: "Message",
    validation: { required: "Message is required" },
  },
  [FIELD_TYPES.REVIEW_NAME]: {
    name: FIELD_TYPES.REVIEW_NAME,
    type: "text",
    placeholder: "Name",
    validation: { required: "Name is required" },
  },
  [FIELD_TYPES.REVIEW_TITLE]: {
    name: FIELD_TYPES.REVIEW_TITLE,
    type: "text",
    placeholder: "Title Of Your Review",
    validation: { required: "Title is required" },
  },
  [FIELD_TYPES.REVIEW_MESSAGE]: {
    name: FIELD_TYPES.REVIEW_MESSAGE,
    type: "textarea",
    placeholder: "How was your overall experience?",
    validation: { required: "Message is required" },
  },
  [FIELD_TYPES.QUESTION]: {
    name: FIELD_TYPES.QUESTION,
    type: "text",
    placeholder: "Question",
  },
}

// See `AccountForgotForm.tsx` for simple usage
const useFormBuilder = ({ onSubmit }: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control, // only used for react-select
  } = useForm()

  return {
    fieldErrors: errors,
    register: register,
    handleSubmit: handleSubmit(onSubmit),
    control,
  }
}

// See `AccountForgotForm.tsx` for simple usage
// TODO: add types
const ControlledInput = ({ register, type, fieldErrors, onChange, data, bg = "brand.offWhite", showAsterisk, ...rest }: any) => {
  const fieldInfo = FIELD_INFO[type]
  const error = fieldErrors?.[type]

  if (!fieldInfo) return null

  const placeholder = `${showAsterisk ? "*" : ""}${fieldInfo?.placeholder}`

  return (
    <FormControl isInvalid={!!error}>
      <Input
        type={fieldInfo?.type}
        placeholder={placeholder}
        {...register(fieldInfo.name, { onChange, ...fieldInfo?.validation })}
        // @ts-ignore
        value={data?.[fieldInfo.name] || ""}
        bg={bg}
        {...rest}
      />
      <FormErrorMessage mt={1}>{error?.message}</FormErrorMessage>
    </FormControl>
  )
}

const ControlledTextarea = ({ register, type, fieldErrors, onChange, data, bg = "brand.offWhite", placeholder, ...rest }: any) => {
  const fieldInfo = FIELD_INFO[type]
  const error = fieldErrors?.[type]

  if (!fieldInfo) return null

  return (
    <FormControl isInvalid={!!error}>
      <Textarea
        placeholder={placeholder || fieldInfo?.placeholder}
        rows={6}
        {...register(fieldInfo.name, { onChange, ...fieldInfo?.validation })}
        // @ts-ignore
        value={data[fieldInfo.name]}
        bg={bg}
        {...rest}
      />
      <FormErrorMessage mt={1}>{error?.message}</FormErrorMessage>
    </FormControl>
  )
}

// The select needed custom config compared to the ControlledInput and ControlledTextarea,
// since it needed to integrated with react-select. see
// https://github.com/csandman/chakra-react-select#usage-with-react-form-libraries
const ControlledSelect: React.FC<ControlledSelectProps> = ({ placeholder, formTypeTitle, options, name, control, setData }) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
  })

  const handleControlledSelectChange = (newReactSelectValue: Option) => {
    // updates the controlled input
    onChange(newReactSelectValue)

    // need to call useForms `setData` manually, since the shape expected by `handleChange` is
    // different from that of react-selects `onChange` event
    setData((prevState: any) => {
      const optionAlreadyInOptions = prevState?.dropdownOptions?.find((option: Option) => option?.label === formTypeTitle)

      const newDropdownOptions = optionAlreadyInOptions
        ? prevState?.dropdownOptions?.map((option: Option) => {
            return option?.label === formTypeTitle ? { ...option, value: newReactSelectValue?.value } : option
          })
        : [...(prevState?.dropdownOptions || {}), { label: formTypeTitle, value: newReactSelectValue?.value, _key: nanoid() }]

      return {
        ...prevState,
        dropdownOptions: newDropdownOptions,
      }
    })
  }

  return (
    <FormControl isInvalid={!!error}>
      <CustomSelect
        name={name}
        placeholder={placeholder}
        options={options}
        onBlur={onBlur}
        innerRef={ref}
        value={value}
        onChange={handleControlledSelectChange}
        height={14}
      />
    </FormControl>
  )
}

// Used to display errors returned back from form functions
const FunctionErrors = ({ errors, color }: any) => {
  const hasFunctionErrors = !!errors?.length
  if (!hasFunctionErrors) return null

  const errorMappings = {
    // https://shopify.dev/api/storefront/2022-04/mutations/customeraccesstokencreate
    UNIDENTIFIED_CUSTOMER: "Incorrect password, or no customer account",
  }
  const mappedErrors = errors?.map(error => errorMappings?.[error?.code] || error)

  return (
    <FormControl isInvalid>
      {mappedErrors?.map((error: any) => {
        if (typeof error === "string") {
          return (
            <FormErrorMessage key={error} color={color}>
              • {error}
            </FormErrorMessage>
          )
        } else {
          return (
            <FormErrorMessage key={error?.code} color={color}>
              • {error?.message}
            </FormErrorMessage>
          )
        }
      })}
    </FormControl>
  )
}

export { useFormBuilder, ControlledInput, ControlledTextarea, ControlledSelect, FIELD_TYPES, FunctionErrors }
