export const styles = {
  global: {
    html: {
      // scrollBehavior: "smooth",
      // lineHeight: "none",
      // stops iOS bounce
      // height: "full",
      // overflow: "hidden",
      // position: "relative",
      // overflowX: ["hidden", "initial"],
    },
    body: {
      bg: "brand.offWhite",
      color: "brand.avocado",
      lineHeight: "none",
      // stops iOS bounce
      // height: "full",
      // overflow: "auto",
      // position: "relative",
      // overflowX: "hidden",
    },
    // Reduce 10% Off label z-index
    "button.needsclick": {
      zIndex: "10!important",
    },
    a: {
      color: "brand.avocado",
    },
    ul: {
      lineHeight: "body",
      listStylePosition: "inside",
    },
    ol: {
      listStylePosition: "inside",
    },
  },
}
