import { useContext, useMemo, createContext, useState, useReducer, useEffect, useCallback, useRef } from "react"

type ContextProps = {
  state: {
    activeMenu: boolean
    activeCart: boolean
    activeSearch: boolean
    activeSubscribe: boolean
  }
  dispatch: React.Dispatch<any>
  activeOptions: any | null
  setActiveOptions: (state: any) => void
  activeProduct: any | null
  setActiveProduct: (state: any) => void
  activeCollection: any | null
  setActiveCollection: (state: any) => void
  isHydrated: boolean
  headerRef: any
}

const AppContext = createContext<ContextProps | undefined>(undefined)

const AppProvider: React.FC = ({ children }) => {
  // State setters
  const [activeOptions, setActiveOptions] = useState(null)
  const [activeProduct, setActiveProduct] = useState(null)
  const [activeCollection, setActiveCollection] = useState(null)
  const [isHydrated, setIsHydrated] = useState(false)

  // Refs
  const headerRef = useRef()

  // Consts
  const initialValues = useMemo(
    () => ({
      activeMenu: false,
      activeCart: false,
      activeSearch: false,
      activeSubscribe: false,
    }),
    []
  )

  // Reducers
  const reducer = useCallback(
    (state: any, action: any) => {
      switch (action.type) {
        case "initial":
          return { ...state, ...initialValues }
        case "menu":
          return { ...state, activeMenu: action.payload, activeSearch: false, activeCart: false, activeSubscribe: false }
        case "cart":
          return { ...state, activeCart: action.payload, activeSearch: false, activeMenu: false, activeSubscribe: false }
        case "search":
          return { ...state, activeSearch: action.payload, activeCart: false, activeMenu: false, activeSubscribe: false }
        case "subscribe":
          return { ...state, activeSubscribe: action.payload, activeSearch: false, activeCart: false, activeMenu: false }
      }
    },
    [initialValues]
  )

  const [state, dispatch] = useReducer(reducer, initialValues)

  useEffect(() => {
    setIsHydrated(true)
  }, [])

  const contextValue = useMemo<ContextProps>(
    () => ({
      state,
      dispatch,
      activeOptions,
      setActiveOptions,
      activeProduct,
      setActiveProduct,
      activeCollection,
      setActiveCollection,
      isHydrated,
      headerRef,
    }),
    [
      state,
      dispatch,
      activeOptions,
      setActiveOptions,
      activeProduct,
      setActiveProduct,
      activeCollection,
      setActiveCollection,
      isHydrated,
      headerRef,
    ]
  )

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
}

const useAppContext = (): ContextProps => ({ ...useContext(AppContext) } as ContextProps)

export { AppContext, AppProvider, useAppContext }
