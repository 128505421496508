import { SystemStyleObject } from "@chakra-ui/theme-tools"

const baseStyle: SystemStyleObject = {
  // control: {
  // }
}

const variants = {}

const sizes = {}

const defaultProps = {}

export const Radio = {
  baseStyle,
  variants,
  sizes,
  defaultProps,
}
