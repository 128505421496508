import { useStaticQuery, graphql } from "gatsby"
import { useMemo, useCallback } from "react"

// Hook Usage: useLocale(LOCALE_KEYS.{KEY})

const LOCALE_KEYS = {
  ACCOUNT: "account",
  ARTICLE: "article",
  CART: "cart",
  COLLECTION: "collection",
  CONTACT: "contact",
  FOOTER: "footer",
  PRODUCT: "product",
  REWARDS: "rewards",
  SEARCH: "search",
  SUBSCRIBE: "subscribe",
  SHOPPING_LIST: "shoppingList",
  FAQS: "faqs",
}

const useLocale = (labelGroup: string) => {
  // Below map onto the field names in admin/settingLocales
  const { data } = useStaticQuery(
    graphql`
      query {
        data: sanitySettingLocales {
          _rawAccount(resolveReferences: { maxDepth: 10 })
          _rawArticle(resolveReferences: { maxDepth: 10 })
          _rawCart(resolveReferences: { maxDepth: 10 })
          _rawCollection(resolveReferences: { maxDepth: 10 })
          _rawContact(resolveReferences: { maxDepth: 10 })
          _rawFaqs(resolveReferences: { maxDepth: 10 })
          _rawFooter(resolveReferences: { maxDepth: 10 })
          _rawProduct(resolveReferences: { maxDepth: 10 })
          _rawRewards(resolveReferences: { maxDepth: 10 })
          _rawSearch(resolveReferences: { maxDepth: 10 })
          _rawSubscribe(resolveReferences: { maxDepth: 10 })
          _rawShoppingList(resolveReferences: { maxDepth: 10 })
        }
      }
    `
  )

  if (!labelGroup) {
    console.warn("Must pass a labelGroup to useLocale")
  }

  const textNormaliser = useCallback((text: any, locale = `enAu`) => (text && text[locale] ? text[locale] : null), [])

  // recursively removes _type keys, removes _raw prefix, downcases first char of _rawCase word
  // and sets the value to the textNormalised string based on the selected locale
  const languageNormaliser: any = useCallback(
    (labels: any) => {
      const obj = {}

      const labelEntries = labels ? Object.entries(labels) : []
      const nonTypeLabelEntries = labelEntries.filter(([key, value]) => key !== `_type` && value)

      nonTypeLabelEntries.forEach(([key, value]: any) => {
        const newKey = key?.replace("_raw", "")?.charAt(0)?.toLowerCase() + key?.replace("_raw", "")?.slice(1)
        const newValue = value._type === "localeString" ? textNormaliser(value) : languageNormaliser(value)

        // @ts-ignore-next-line
        if (newKey && newValue) obj[newKey] = newValue
      })

      return obj
    },
    [textNormaliser]
  )

  const language = useMemo(() => languageNormaliser(data), [data, languageNormaliser])

  const locales = useMemo(() => language?.[labelGroup], [labelGroup, language])

  if (!locales) console.warn(`No language string for "${labelGroup}"`)

  return locales
}

export { LOCALE_KEYS, useLocale }
