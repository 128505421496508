import { Text } from "@chakra-ui/react"
import { memo, useEffect } from "react"
import { useRewardsContext } from "@app/providers/rewards"
import { useRewards } from "@app/hooks/useRewards"

// See https://developers.loyaltylion.com/sdk/components/customer-points
const RewardsCustomerPoints = () => {
  const { loyaltyLionCustomer } = useRewardsContext()
  const { refreshRewardsUI } = useRewards()

  useEffect(() => {
    if (loyaltyLionCustomer) {
      refreshRewardsUI()
    }
  }, [refreshRewardsUI, loyaltyLionCustomer])

  if (!loyaltyLionCustomer) return null

  return (
    <Text fontSize="xs" minW="8ch">
      {/* {<span data-lion-points="approved" />} points */}
      {loyaltyLionCustomer?.points_approved} points
    </Text>
  )
}

// See https://developers.loyaltylion.com/sdk/components/points-for-cart
const RewardsCartPoints = ({ total }: { total: string }) => {
  const totalInCents = Math.floor(parseFloat(total) * 100)
  return <span data-lion-points-for={totalInCents}></span>
}

const MemoRewardsCartPoints = memo(RewardsCartPoints)
const MemoRewardsCustomerPoints = memo(RewardsCustomerPoints)
export { MemoRewardsCustomerPoints as RewardsCustomerPoints, MemoRewardsCartPoints as RewardsCartPoints }
