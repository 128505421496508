import React, { useCallback, useState } from "react"
import { GridItem, Stack, Text, HStack, Box, Tag, AspectRatio } from "@chakra-ui/react"
import { useDate } from "@app/hooks/useDate"
import { useContent } from "@app/hooks/useContent"
import { CustomImage } from "@components/Image"
import { CustomLink } from "@components/Link"
import { useSanityImageUrl } from "@app/hooks/useSanity"

const createBreadcrumb = (title?: string, url?: string) => {
  if (!title || !url) return null
  return { breadcrumbs: [{ title, url }] }
}

type handleClick = () => void

type ItemProps = {
  url: string
  type: string
  title: any
  image?: string
  items: ItemProps[]
  external: boolean
  handleClick: handleClick | undefined
  item?: any
  _key?: string
  hideTitle?: boolean
  brandLogo?: any
  fontSize?: any
  parentCollection?: string
  parentCollectionUrl?: string
  nonParent?: boolean
}

type MenuItemProps = {
  item: ItemProps
  handleClick?: handleClick | undefined
  parentCollection?: string
  parentCollectionUrl?: string
  nonParent?: boolean
}

type NavigationItemsProps = {
  items: Array<ItemProps>
  handleClick?: handleClick
  parentCollection?: string
  parentCollectionUrl?: string
}

type ItemTitleProps = {
  title: string
  url: string
  external: boolean
  hide?: boolean
  handleClick: any
  variant?: string
  parentCollection?: string
  parentCollectionUrl?: string
}

const ITEM_TYPES = {
  SUB_NAVIGATION: "sub",
  TILE: "tile",
  LINK: "link",
  PAGE: "page",
  COLLECTION: "collection",
  PRODUCT: "product",
  ARTICLE: "article",
  STORE: "store",
  BRAND: "brand",
}

const ItemTitle = ({
  url,
  external,
  title,
  hide,
  handleClick,
  variant = "underline",
}: // parentCollection,
// parentCollectionUrl,
ItemTitleProps) => (
  <CustomLink
    to={url}
    external={external}
    variant={variant}
    onClick={handleClick}
    styles={{
      display: "block",
      mb: 4,
      fontWeight: 400,
      fontFamily: "body",
      fontSize: "md",
      visibility: hide ? "hidden" : "visible",
    }}
  >
    {title}
  </CustomLink>
)

const DesktopItemSubMenu = ({ items, handleClick, title, external, url, hideTitle, parentCollection, parentCollectionUrl }: ItemProps) => {
  const [expandedSubMenu, setExpandedSubMenu] = useState("")

  const handleSubMenuClick = useCallback(
    key => {
      if (expandedSubMenu === key) {
        return setExpandedSubMenu("")
      } else {
        setExpandedSubMenu(key)
      }
    },
    [expandedSubMenu]
  )

  return (
    <GridItem width="100%" mb={[4, "unset"]}>
      <ItemTitle
        url={url}
        external={external}
        title={title}
        hide={hideTitle}
        handleClick={handleClick}
        parentCollection={parentCollection}
        parentCollectionUrl={parentCollectionUrl}
      />
      <Stack spacing="2" mt="2">
        {items.map(subItem => {
          const isThirdLevelSubNav = subItem.type === "sub"
          if (isThirdLevelSubNav) {
            return (
              <React.Fragment key={subItem?._key}>
                <ItemLink
                  {...subItem}
                  handleClick={() => handleSubMenuClick(subItem?._key)}
                  url="#"
                  parentCollection={parentCollection}
                  parentCollectionUrl={parentCollectionUrl}
                />
                {expandedSubMenu === subItem?._key && (
                  <Box mb={2} key={subItem?._key}>
                    {subItem?.items?.map(item => (
                      <ItemTitle
                        key={item?._key}
                        title={item?.title}
                        external={item?.external}
                        url={item?.url}
                        handleClick={handleClick}
                        variant="no-underline"
                        parentCollection={parentCollection}
                        parentCollectionUrl={parentCollectionUrl}
                      />
                    ))}
                  </Box>
                )}
              </React.Fragment>
            )
          }

          return (
            <DesktopMenuItem
              key={subItem?._key}
              item={subItem}
              handleClick={handleClick}
              parentCollection={parentCollection}
              parentCollectionUrl={parentCollectionUrl}
            />
          )
        })}
      </Stack>
    </GridItem>
  )
}

const ItemTile = ({ title, url, external, image, handleClick, item }: ItemProps) => {
  const { getSanityImageUrl } = useSanityImageUrl()
  const isArticle = item?._type === "article"
  const { getDayMonthLabel } = useDate()

  const { dayMonthLabel } = getDayMonthLabel(item?.attributes?.publishedAt)
  const showArrow = !isArticle || !dayMonthLabel

  const sanityImageUrl = image ? getSanityImageUrl(image, 310, 230) : ""

  const customPortableTextComponents = {
    block: ({ children }: any) => (
      <Text
        // Various attributes below are needed to vertical align the arrow svg whilst also
        // supporting multiline text, which would ordinarily center it with the last line
        w="full"
        _after={
          showArrow
            ? {
                content: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16' focusable='false' class='chakra-icon css-4qht2t'%3E%3Cpath fill='currentColor' d='M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z'%3E%3C/path%3E%3C/svg%3E")`,
                // transition: "100ms ease-out",
                // marginLeft: "6px",

                // display: "inline-block",
                // _groupHover: { transform: "translateX(8px)" },
                position: "absolute",
                top: "50%",
                transform: "translate(0, -8px)",
                transition: "100ms ease-out",
                marginLeft: "6px",
                right: 0,
                _groupHover: { transform: "translate(8px, -8px)" },
              }
            : {}
        }
        position="relative"
      >
        {children}
      </Text>
    ),
  }

  const { getContent } = useContent()
  const content = getContent({ content: title, components: customPortableTextComponents })

  return (
    <GridItem width="full" display="block" mb={[4, 5]} role="group">
      <CustomLink to={url} external={external} onClick={handleClick}>
        <AspectRatio ratio={323 / 240}>
          <CustomImage fit="cover" width="full" src={sanityImageUrl} />
        </AspectRatio>
        <HStack mt={2} justifyContent="space-between" spacing={[0, 2, 3]} alignItems="flex-start">
          {content}
          {isArticle && dayMonthLabel && <Tag variant="date">{dayMonthLabel}</Tag>}
        </HStack>
      </CustomLink>
    </GridItem>
  )
}

const ItemLink = ({
  title,
  url,
  external,
  handleClick,
  fontSize = "2xl",
  parentCollection,
  parentCollectionUrl,
  nonParent = false,
}: ItemProps) => (
  <CustomLink
    to={url}
    onClick={handleClick}
    styles={{
      lineHeight: "none",
      pb: nonParent ? 3 : 4,
      fontFamily: "heading",
      fontWeight: 900,
      fontSize,
    }}
    external={external}
    state={createBreadcrumb(parentCollection, parentCollectionUrl)}
  >
    {title}
  </CustomLink>
)

const ItemBrand = ({ title, url, handleClick, brandLogo, parentCollection, parentCollectionUrl }: ItemProps) => {
  return (
    <CustomLink to={url} onClick={handleClick} state={createBreadcrumb(parentCollection, parentCollectionUrl)}>
      <HStack>
        <Box
          bg={["brand.lightSand", "brand.lightSand", "brand.offWhite"]}
          width="44px"
          height="44px"
          borderRadius="round"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CustomImage width="70%" height="70%" fit="contain" src={brandLogo?.asset?.url} />
        </Box>
        <Text fontSize={["md", "md"]}>{title}</Text>
      </HStack>
    </CustomLink>
  )
}

const DesktopMenuItem = ({ item, handleClick, parentCollection, parentCollectionUrl }: MenuItemProps) => {
  switch (item.type) {
    case ITEM_TYPES.SUB_NAVIGATION:
      return (
        <DesktopItemSubMenu
          {...item}
          handleClick={handleClick}
          parentCollection={parentCollection}
          parentCollectionUrl={parentCollectionUrl}
        />
      )
    case ITEM_TYPES.TILE:
      return <ItemTile {...item} handleClick={handleClick} />
    case ITEM_TYPES.BRAND:
      return <ItemBrand {...item} handleClick={handleClick} parentCollection={parentCollection} parentCollectionUrl={parentCollectionUrl} />
    default:
      return <ItemLink {...item} handleClick={handleClick} parentCollection={parentCollection} parentCollectionUrl={parentCollectionUrl} />
  }
}

const NavigationHeaderItemsDesktop = ({ items, handleClick, parentCollection, parentCollectionUrl }: NavigationItemsProps) => (
  <>
    {items.map(item => (
      <DesktopMenuItem
        item={item}
        key={item?._key}
        handleClick={handleClick}
        parentCollection={parentCollection}
        parentCollectionUrl={parentCollectionUrl}
      />
    ))}
  </>
)

export { ITEM_TYPES, ItemTitle, ItemLink, ItemBrand, NavigationHeaderItemsDesktop }
export type { ItemProps, MenuItemProps, NavigationItemsProps }
