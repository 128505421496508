import { memo } from "react"
import { Box, Progress, Text, HStack } from "@chakra-ui/react"
import { CustomIcon } from "@components/Icon"
import { useCart } from "@app/hooks/useCart"

type CartShippingProps = {
  isMain?: boolean
  [rest: string]: any
}

const CartShipping: React.FC<CartShippingProps> = ({ isMain, ...props }) => {
  const {
    freeShipping: { percentage, message, isFreeShipping },
  } = useCart()

  return (
    <Box bg={isMain ? "inherit" : "brand.lightSand"} py={6} {...props}>
      {!isFreeShipping && (
        <Progress
          bg="brand.lightSand"
          size="sm"
          value={percentage}
          overflow="visible"
          pr={2}
          mb={3}
          sx={{
            "& > div:first-of-type": {
              transition: "width 200ms linear",
              position: "relative",
              "&::after": {
                content: `url("data:image/svg+xml,%3Csvg width='31' height='21' viewBox='0 0 31 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.92729 17.3386H29.3182C29.9091 17.3295 30.3818 16.8477 30.3818 16.2568V11.7295C30.3818 10.7841 29.9909 9.86591 29.3091 9.21136L28.0727 8.02045C28.0182 7.96591 27.9546 7.92045 27.8909 7.875L25.8546 4.79318C25.2 3.82045 24.1091 3.23864 22.9273 3.23864H20.5364C20.2364 1.61136 18.8091 0.375 17.1 0.375H1.46365C0.863654 0.375 0.381836 0.856818 0.381836 1.45682V16.2568C0.381836 16.8568 0.863654 17.3386 1.46365 17.3386H1.92729Z' fill='%23E8CDAF'/%3E%3Cpath d='M27.8907 7.88422L25.8543 4.80241C25.1998 3.82968 24.1089 3.23877 22.927 3.23877H20.5361V7.14786C20.5361 7.60241 20.8998 7.96604 21.3543 7.96604H27.9998C27.9634 7.93877 27.927 7.9115 27.8907 7.88422Z' fill='%23FC9173'/%3E%3Cpath d='M7.4425 20.6692C9.39174 20.6692 10.9719 19.089 10.9719 17.1398C10.9719 15.1905 9.39174 13.6104 7.4425 13.6104C5.49326 13.6104 3.91309 15.1905 3.91309 17.1398C3.91309 19.089 5.49326 20.6692 7.4425 20.6692Z' fill='%23FC9173'/%3E%3Cpath d='M22.4425 20.6692C24.3917 20.6692 25.9719 19.089 25.9719 17.1398C25.9719 15.1905 24.3917 13.6104 22.4425 13.6104C20.4933 13.6104 18.9131 15.1905 18.9131 17.1398C18.9131 19.089 20.4933 20.6692 22.4425 20.6692Z' fill='%23FC9173'/%3E%3C/svg%3E%0A")`,
                position: "absolute",
                right: "-16px",
                top: "-5px",
                zIndex: 2,
              },
            },
          }}
        />
      )}
      <HStack>
        {isFreeShipping && <CustomIcon name="truck" />}
        <Text fontSize="md" mb={isMain ? [2, 5] : 0} dangerouslySetInnerHTML={{ __html: message }} />
      </HStack>
    </Box>
  )
}

const MemoCartShipping = memo(CartShipping)
export { MemoCartShipping as CartShipping }
