import { memo } from "react"
import { Box } from "@chakra-ui/react"
import NavigationHeaderDesktop from "./NavigationHeaderDesktop"
import NavigationHeaderMobile from "./NavigationHeaderMobile"

import type { Location } from "@root/types/global"

type Props = {
  location: Location
}

const NavigationHeader: React.FC<Props> = ({ location }) => {
  return (
    <>
      <Box display={["none", "none", "block"]}>
        <NavigationHeaderDesktop location={location} />
      </Box>
      <Box display={["block", "block", "none"]}>
        <NavigationHeaderMobile />
      </Box>
    </>
  )
}

export default memo(NavigationHeader)
