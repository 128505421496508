import { memo } from "react"
import { SimpleGrid, Stack, Box, Text } from "@chakra-ui/react"
import { useNavigation } from "@app/hooks/useNavigation"
import { CustomLink } from "@components/Link"
import { SocialMedia } from "@components/SocialMedia"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"

const NavigationFooterDesktop: React.FC = () => {
  const { footer: footerItems } = useNavigation()
  const locales = useLocale(LOCALE_KEYS.FOOTER)

  const socialButtonsTitle = locales?.socialButtonsTitle || "Let’s get social"

  return (
    <SimpleGrid columns={[null, null, 2, 4]} spacing={[10, 4, 12]} flex={[null, null, 1, null]} justifyItems={[null, null, "end"]}>
      {footerItems.map(({ type, title, url, items }, index) =>
        type !== "sub" ? (
          <Text key={index}>
            <CustomLink
              to={url}
              styles={{
                fontWeight: 700,
                color: "inherit",
              }}
            >
              {title}
            </CustomLink>
          </Text>
        ) : (
          <Box key={index} minW="180px" mb={[null, null, 10, null]}>
            <Text mb="4" color="inherit" fontWeight={700} fontFamily="heading" fontSize="2xl">
              {title}
            </Text>
            {!!items?.length && (
              <Stack spacing={4}>
                {items.map(({ title, url }, index) => (
                  <Text key={index} color="inherit">
                    <CustomLink to={url} styles={{ color: "inherit" }} variant="no-underline">
                      {title}
                    </CustomLink>
                  </Text>
                ))}
              </Stack>
            )}
          </Box>
        )
      )}
      <Box minW="180px">
        <Text mb={5} color="inherit" fontWeight={700} fontFamily="heading" fontSize="2xl">
          {socialButtonsTitle}
        </Text>
        <SocialMedia />
      </Box>
    </SimpleGrid>
  )
}

export default memo(NavigationFooterDesktop)
