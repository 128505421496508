// Added new values from the design system to extend Chakra's defaults
// https://chakra-ui.com/docs/styled-system/theming/theme#spacing
// And set as px values to be more readable and explicit
export const space = {
  0: "0px",
  0.5: "2px",
  1: "4px",
  1.5: "6px",
  2: "8px",
  2.5: "10px",
  3: "12px",
  3.5: "14px",
  4: "16px",
  4.5: "18px",
  5: "20px",
  6: "24px",
  7: "28px",
  8: "32px",
  9: "36px",
  10: "40px",
  11: "44px",
  12: "48px",
  14: "56px",
  15: "60px",
  16: "64px",
  18: "72px",
  20: "80px",
  21: "85px",
  22: "90px",
  24: "96px",
  25: "100px",
  27: "108px",
  28: "112px",
  30: "120px",
  32: "128px",
  34: "136px",
  36: "144px",
  38: "155px",
  40: "160px",
  42: "168px",
  44: "176px",
  46: "185px",
  48: "192px",
  49: "210px",
  50: "216px",
  52: "208px",
  56: "224px",
  60: "240px",
  64: "256px",
  66: "264px",
  68: "270px",
  72: "288px",
  75: "305px",
  76: "312px",
  80: "320px",
  83: "335px",
  85: "360px",
  87: "420px",
  90: "440px",
  93: "480px",
  95: "550px",
  96: "640px",
  97: "664px",
  98: "680px",
  99: "820px",
  100: "886px",
  110: "1440px",
  brand: {
    sectionPaddingLeft1: "7%",
  },
}
