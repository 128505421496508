import config from "@root/config.js"
import { useMemo, createContext, useContext } from "react"
import { useLocationContext } from "@app/providers/location"

type ContextProps = Config

export const ConfigContext = createContext<ContextProps | undefined>(undefined)

export const ConfigProvider: React.FC = ({ children }) => {
  const { store } = useLocationContext() as StoreLocation

  const activeConfig = useMemo<ContextProps>(
    () =>
      ({
        ...config,
        store,
      } as Config),
    [store]
  )

  const contextValue = useMemo<ContextProps>(() => activeConfig, [activeConfig])

  return <ConfigContext.Provider value={contextValue}>{children}</ConfigContext.Provider>
}

export const useConfigContext = (): ContextProps => ({ ...useContext(ConfigContext) } as ContextProps)
