import React, { useCallback, useMemo } from "react"
import { PortableText } from "@portabletext/react"
import { Heading, Text, UnorderedList, OrderedList, Box } from "@chakra-ui/react"
import { CustomLink } from "@components/Link"
import { CustomImage } from "@components/Image"
import { useRoutes } from "@app/hooks/useRoutes"
import { CustomTable } from "@components/Table"
import { ProductCallouts } from "@components/ProductCallouts"
import { useSanityImageUrl } from "@app/hooks/useSanity"

type getContentProps = {
  content: any
  components?: any
}

type useContentProps = {
  type?: string
}

export const PADDING_BOTTOM = [5]
export const LINE_HEIGHT = ["24px"]

// See https://github.com/portabletext/react-portabletext

// WARNING: be careful when modifying these styles as this block is used throughout
// many components and pages on the site, and can lead to global effects

const extraTextProps = {}

const useContent = (props?: useContentProps) => {
  const { type } = props || {}
  const { urlResolver } = useRoutes()
  const { getSanityImageUrl } = useSanityImageUrl()

  if (type === "productCalloutsHorizontal") {
    Object.assign(extraTextProps, { pb: 0 })
  }

  if (type === "accordionList") {
    Object.assign(extraTextProps, { fontSize: "sm", lineHeight: "21px" })
  }

  if (type === "article") {
    Object.assign(extraTextProps, {
      fontSize: ["md", "xl"],
      lineHeight: ["24px", "30px"],
    })
  }

  const defaultComponents = useMemo(
    () => ({
      block: {
        h1: ({ children }: any) => (
          <Heading as="h1" size="h1" pb={PADDING_BOTTOM}>
            {children}
          </Heading>
        ),
        h2: ({ children }: any) => (
          <Heading as="h2" size="h2" pb={PADDING_BOTTOM}>
            {children}
          </Heading>
        ),
        h3: ({ children }: any) => (
          <Heading as="h3" size="h3" pb={PADDING_BOTTOM}>
            {children}
          </Heading>
        ),
        h4: ({ children }: any) => (
          <Heading as="h4" size="h4" pb={PADDING_BOTTOM}>
            {children}
          </Heading>
        ),
        h5: ({ children }: any) => (
          <Heading as="h5" size="h5" pb={PADDING_BOTTOM}>
            {children}
          </Heading>
        ),
        h6: ({ children }: any) => (
          <Heading as="h6" size="h6" pb={PADDING_BOTTOM}>
            {children}
          </Heading>
        ),
        normal: ({ children }: any) => {
          return (
            <Text fontSize="md" lineHeight={LINE_HEIGHT} pb={PADDING_BOTTOM} {...extraTextProps}>
              {children}
            </Text>
          )
        },
        small: ({ children }: any) => (
          <Text variant="label" mb={[1, 3]}>
            {children}
          </Text>
        ),
        blockquote: () => null,
      },
      list: {
        bullet: ({ children }: any) => {
          return (
            <UnorderedList m={0} pb={PADDING_BOTTOM} lineHeight="normal">
              {React.Children.map(children, child => (
                <Box ml={4} pb={[1, 2]} {...extraTextProps}>
                  {child}
                </Box>
              ))}
            </UnorderedList>
          )
        },
        number: ({ children }: any) => {
          return (
            <OrderedList m={0} pb={PADDING_BOTTOM} lineHeight="normal">
              {React.Children.map(children, child => (
                <Box ml={4} pb={[1, 2]} {...extraTextProps}>
                  {child}
                </Box>
              ))}
            </OrderedList>
          )
        },
      },
      types: {
        customImage: ({ value }: any) => (
          <CustomImage
            mb={PADDING_BOTTOM}
            fit="cover"
            aspectRatio="landscape"
            width="full"
            src={value.asset?.url ? getSanityImageUrl(value.asset?.url, value?.asset?.width, value?.asset?.height) : ""}
            alt={value?.alt}
          />
        ),
        table: ({ value }: any) => (
          <Box pb={PADDING_BOTTOM}>
            <CustomTable table={value} />
          </Box>
        ),
        objectProductCallouts: ({ value }: any) => <ProductCallouts {...value} />,
      },
      marks: {
        // internal link
        document: ({ children, value }: any) => {
          const resolvedUrl = urlResolver(value?.document?.document)
          return (
            <CustomLink to={resolvedUrl?.url} external={resolvedUrl?.external} variant="underline">
              {children}
            </CustomLink>
          )
        },
        // external link
        link: ({ children, value }: any) => {
          const resolvedUrl = urlResolver(value?.link)
          return (
            <CustomLink to={resolvedUrl?.url} external={resolvedUrl?.external} title={resolvedUrl?.title} variant="underline">
              {children}
            </CustomLink>
          )
        },
        // strong: ({ children }: any) => (
        //   <Text as="span" fontWeight={700} fontSize="inherit" lineHeight="inherit" fontFamily="inherit">
        //     {children}
        //   </Text>
        // ),
      },
    }),
    [urlResolver, getSanityImageUrl]
  )

  const getContent = useCallback(
    ({ content, components: customComponents }: getContentProps) => {
      if (!content) return null

      return <PortableText value={content} components={customComponents || defaultComponents} />
    },
    [defaultComponents]
  )

  return {
    getContent,
  }
}

export { useContent }
