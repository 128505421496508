import { memo, useMemo, useCallback } from "react"
import { Box, Text } from "@chakra-ui/react"
import { CustomIcon } from "@components/Icon"
import { CustomLink } from "@components/Link"
import { useStamped, STAMPED_TYPES } from "@app/hooks/useStamped"

type ProductReviewStarsProps = {
  product: {
    id: string
    title: string
    productType: string
    metafields: Array<any>
  }
  size?: "small" | "large" | "xl"
  showLink?: boolean
  productId?: string
  hideCount?: boolean
}

const ProductReviewStars: React.FC<ProductReviewStarsProps> = ({
  product,
  size = "small",
  showLink = false,
  hideCount = false,
  productId,
}) => {
  const { reviews } = useStamped({
    productId,
    pageSize: 5,
    type: STAMPED_TYPES.REVIEWS,
  })

  const reviewsCount = useMemo(() => reviews?.total || 0, [reviews?.total])

  // needed, since stamped defaults the rating to 5 if no reviews. this resets it to 0
  const reviewsRating = useMemo(() => (!reviews?.total ? 0 : reviews?.rating), [reviews?.total, reviews?.rating])

  if (!productId) return <></>

  const textSize = size === "small" ? "xxs" : "sm"

  return (
    <Box display="flex" alignItems="center">
      <Stars rating={reviewsRating} count={reviewsCount} size={size} />
      {!hideCount && (
        <Text fontSize={textSize} ml={1} mr={2}>
          ({reviewsCount})
        </Text>
      )}
      {showLink && (
        <CustomLink to={`/review/write/${product?.handle}`} variant="underline">
          <Text variant="label">Write a product review</Text>
        </CustomLink>
      )}
    </Box>
  )
}

const Stars = ({ rating, size, count, ...rest }: any) => {
  const percent = useMemo(() => (rating - Math.floor(rating)) * 100 || 0, [rating])
  const stars = useMemo(() => [...Array(5).keys()], [])
  const starSize = size === "xl" ? 24 : size === "small" ? 10 : 15

  const getFilledStarsWidth = useCallback(
    index => (index === Math.floor(rating) ? `${percent}%` : index > rating ? "0%" : "100%"),
    [percent, rating]
  )

  return (
    <Box display="inline-flex" data-lang="en-Au" data-rating={rating} aria-label={`Rated ${rating} out of ${count} reviews`} {...rest}>
      {stars?.map((item: any, index: number) => (
        <Box key={index} pos="relative" width={`${starSize}px`} height={`${starSize}px`}>
          <Box pos="absolute">
            <CustomIcon name="star-outline" width={starSize} height={starSize} />
          </Box>
          <Box pos="absolute" w={getFilledStarsWidth(index)} overflow="hidden">
            <CustomIcon name="star" width={starSize} height={starSize} />
          </Box>
        </Box>
      ))}
    </Box>
  )
}

const MemoProductReviewStars = memo(ProductReviewStars)
export { MemoProductReviewStars as ProductReviewStars, Stars }
