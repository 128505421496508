import BrickDisplayProBlackWoff from "@static/fonts/brick-display-pro-black.woff"
import BrickDisplayProBlackWoff2 from "@static/fonts/brick-display-pro-black.woff2"
import RelativeBookRegularWoff from "@static/fonts/relative-book-regular.woff"
import RelativeBookRegularWoff2 from "@static/fonts/relative-book-regular.woff2"
import RelativeBookBoldWoff from "@static/fonts/relative-book-bold.woff"
import RelativeBookBoldWoff2 from "@static/fonts/relative-book-bold.woff2"

export const fonts = `
  @font-face {
    font-family: 'Brick Display Pro Black';
    src: url('${BrickDisplayProBlackWoff2}') format('woff2'),
        url('${BrickDisplayProBlackWoff}') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Relative Book';
    src: url('${RelativeBookRegularWoff2}') format('woff2'),
        url('${RelativeBookRegularWoff}') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Relative Book';
    src: url('${RelativeBookBoldWoff2}') format('woff2'),
        url('${RelativeBookBoldWoff}') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
`
