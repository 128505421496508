import { useCore } from "@app/hooks/useCore"
// Object.hasOwn polyfill to support safari 15
import ObjectHasOwn from "object.hasown"

// Waits for a variable to become available on the window obj
// For libs that don't provide a callback

const useWaitForGlobal = () => {
  const INTERVAL_TO_RECHECK_MS = 200

  const {
    helpers: { isBrowser },
  } = useCore()

  const checkIfReady = (key: string) => {
    if (isBrowser) {
      const variableReady = ObjectHasOwn(window, key)
      return variableReady ? true : false
    } else {
      return false
    }
  }

  const waitForGlobal = (key: string, callback: () => void) => {
    if (checkIfReady(key)) return callback?.()
    else setTimeout(() => waitForGlobal(key, callback), INTERVAL_TO_RECHECK_MS)
  }

  return {
    waitForGlobal,
  }
}

export { useWaitForGlobal }
