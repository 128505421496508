const baseStyle = {
  fontFamily: "body",
  color: "brand.avocado",
  fontWeight: "normal",
  lineHeight: "normal",
}

const variants = {
  overline: {
    fontSize: "sm",
    lineHeight: "overline",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "2.1px",
  },
  label: {
    fontSize: "xs",
    lineHeight: "18px",
  },
  unstyled: {
    lineHeight: "normal",
  },
  imageText: {
    fontSize: ["md", "xl"],
    lineHeight: ["24px", "30px"],
  },
  bold: {
    fontWeight: "bold",
  },
}

const sizes = {
  xs: {
    fontSize: "xs",
  },
  sm: {
    fontSize: "sm",
  },
  md: {
    fontSize: "md",
  },
  lg: {
    fontSize: ["sm", "md"],
    lineHeight: ["24px", "30px"],
  },
  xl: {
    fontSize: ["md", "xl"],
  },
}

const defaultProps = {
  size: "md",
}

export const Text = {
  baseStyle,
  variants,
  sizes,
  defaultProps,
}
