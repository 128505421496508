import gql from "graphql-tag"

import { CART_FRAGMENT } from "../fragments/cartFragment"
import { CART_USER_ERROR_FRAGMENT } from "../fragments/cartUserErrorFragment"

export const CART_ATTRIBUTES_UPDATE = gql`
  mutation cartAttributesUpdate($cartId: ID!, $attributes: [AttributeInput!]!, $countryCode: CountryCode!)
  @inContext(country: $countryCode) {
    cartAttributesUpdate(cartId: $cartId, attributes: $attributes) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`

export const CART_NOTE_UPDATE = gql`
  mutation cartAttributesUpdate($cartId: ID!, $note: String!, $countryCode: CountryCode!) @inContext(country: $countryCode) {
    cartNoteUpdate(cartId: $cartId, note: $note) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`

export const CART_CREATE = gql`
  mutation CART_CREATE($input: CartInput!, $countryCode: CountryCode!, $languageCode: LanguageCode)
  @inContext(country: $countryCode, language: $languageCode) {
    cartCreate(input: $input) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`

export const CART_BUYER_IDENTITY_UPDATE = gql`
  mutation cartBuyerIdentityUpdate($buyerIdentity: CartBuyerIdentityInput!, $cartId: ID!) {
    cartBuyerIdentityUpdate(buyerIdentity: $buyerIdentity, cartId: $cartId) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`

export const CART_DISCOUNT_CODES_UPDATE = gql`
  mutation cartDiscountCodesUpdate($discountCodes: [String!]!, $cartId: ID!) {
    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`

export const CART_LINE_ADD = gql`
  mutation cartLinesAdd($lines: [CartLineInput!]!, $cartId: ID!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`

export const CART_LINE_UPDATE = gql`
  mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`

export const CART_LINE_REMOVE = gql`
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`

export const CART_SHIPPING_ADDRESS_UPDATE = gql`
  mutation cartShippingAddressUpdate($cartId: ID!, $shippingAddress: MailingAddressInput!, $countryCode: CountryCode!)
  @inContext(country: $countryCode) {
    cartShippingAddressUpdate(cartId: $cartId, shippingAddress: $shippingAddress) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`

export const CART_EMAIL_UPDATE = gql`
  mutation cartEmailUpdate($cartId: ID!, $email: String!, $countryCode: CountryCode!) @inContext(country: $countryCode) {
    cartEmailUpdate(cartId: $cartId, email: $email) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`

export const CART_DISCOUNT_APPLY = gql`
  mutation cartDiscountCodeApply($discountCode: String!, $cartId: ID!, $countryCode: CountryCode!) @inContext(country: $countryCode) {
    cartDiscountCodeApply(discountCode: $discountCode, cartId: $cartId) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`

export const CART_DISCOUNT_REMOVE = gql`
  mutation cartDiscountCodeRemove($cartId: ID!, $countryCode: CountryCode!) @inContext(country: $countryCode) {
    cartDiscountCodeRemove(cartId: $cartId) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`

export const CART_GIFTCARDS_APPEND = gql`
  mutation cartGiftCardCodesUpdate($giftCardCodes: [String!]!, $cartId: ID!, $countryCode: CountryCode!) @inContext(country: $countryCode) {
    cartGiftCardCodesUpdate(giftCardCodes: $giftCardCodes, cartId: $cartId) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`

export const CART_CUSTOMER_ASSOCIATE = gql`
  mutation cartCustomerAssociate($cartId: ID!, $customerAccessToken: String!, $countryCode: CountryCode!)
  @inContext(country: $countryCode) {
    cartCustomerAssociate(cartId: $cartId, customerAccessToken: $customerAccessToken) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`

export const CART_CUSTOMER_DISASSOCIATE = gql`
  mutation cartCustomerDisassociate($cartId: ID!, $countryCode: CountryCode!) @inContext(country: $countryCode) {
    cartCustomerDisassociate(cartId: $cartId) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`
