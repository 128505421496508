import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client"
import { useConfigContext } from "@app/providers/config"

export const ShopifyProvider: React.FC = ({ children }) => {
  const { store } = useConfigContext()

  const client = new ApolloClient({
    link: new HttpLink({
      uri: `https://${store.shopifyCheckoutUrl}/api/${store.shopifyApiVersion}/graphql.json`,
      headers: {
        "X-Shopify-Storefront-Access-Token": store.shopifyStorefrontToken,
      },
    }),
    cache: new InMemoryCache(),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
