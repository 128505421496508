import { memo, useCallback, useMemo, useState } from "react"
import { CustomLink } from "@components/Link"
import { NavigationHeaderItemsDesktop } from "./NavigationHeaderItemsDesktop"
import { ChevronDownIcon } from "@chakra-ui/icons"
import { Box, MenuButton, Container, Grid, GridItem, keyframes } from "@chakra-ui/react"

import type { Location } from "@root/types/global"
import type { ItemProps } from "./NavigationHeaderItemsDesktop"

export type MenuItemPropsDesktop = ItemProps & {
  active: string | null
  location: Location
  handleActive: (key: string | null) => void
}

type NavigationHeaderDesktopArrowButtonProps = {
  children: any
  currentRoute: boolean
  handleActive: any
  title: string
  url: string
  hasSubItems: boolean
  currentActive: boolean
  buttonTitle: string
  handleClick: any
}

type NavigationHeaderDropdownDesktopProps = {
  buttonTitle: string
  handleActive: any
  handleClick: any
  items: any
  hasSubItems: boolean
  currentActive: boolean
  parentCollectionUrl: string
}

const NavigationHeaderDesktopArrowButton = ({
  children,
  currentRoute,
  handleActive,
  title,
  url,
  hasSubItems,
  currentActive,
  buttonTitle,
  handleClick,
}: NavigationHeaderDesktopArrowButtonProps) => {
  const [delayHandler, setDelayHandler] = useState<NodeJS.Timeout | null>(null)

  const handleMouseEnter = useCallback(() => {
    const timer = setTimeout(() => handleActive(buttonTitle), 250)
    setDelayHandler(timer)
    return () => clearTimeout(timer)
  }, [handleActive, buttonTitle])

  const handleMouseLeave = useCallback(() => {
    if (delayHandler) clearTimeout(delayHandler)
    handleActive(null)
  }, [handleActive, delayHandler])

  return (
    <MenuButton
      pointerEvents="initial"
      fontWeight={currentRoute ? 700 : 400}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      fontSize="md"
      py={[0, 3]}
      px={[0, 3]}
    >
      <CustomLink
        to={url}
        onClick={handleClick}
        styles={{
          pointerEvents: "initial",
          display: "flex",
          alignItems: "flex-end",
          whiteSpace: "noWrap",
        }}
      >
        {title}
        {hasSubItems && (
          <ChevronDownIcon
            display={{ base: "none", lg: "block" }}
            transform={currentActive ? "rotate(-180deg)" : "none"}
            transition="200ms ease-in-out"
            w={4}
            h={4}
            ml={1}
          />
        )}
      </CustomLink>
      {children}
    </MenuButton>
  )
}

const fadeIn = keyframes`
  from { opacity: 0 ;}
  to { opacity: 1; }
`

const NavigationHeaderDropdownDesktop = ({
  buttonTitle,
  handleActive,
  items,
  handleClick,
  hasSubItems,
  currentActive,
  parentCollectionUrl,
}: NavigationHeaderDropdownDesktopProps) => {
  const handleMouseEnter = useCallback(() => handleActive(buttonTitle), [handleActive, buttonTitle])
  if (!hasSubItems) return null

  return (
    <Box
      position="absolute"
      minW="0"
      w="full"
      py={9}
      left={0}
      right={0}
      marginTop={[0, 3]}
      zIndex={9999}
      textAlign="left"
      display={{ base: "none", lg: currentActive ? "block" : "none" }}
      opacity={0}
      onMouseEnter={handleMouseEnter}
      pointerEvents="initial"
      boxShadow="lg"
      bg="brand.lightSand"
      borderTop="1px"
      borderTopColor="brand.avocado"
      // duration, easing, delay, iteration
      animation={`300ms ${fadeIn} 0ms 1 linear forwards`}
    >
      <Container>
        <Grid
          as="nav"
          display={["none", "flex"]}
          templateColumns="repeat(4, 1fr)"
          gap="10"
          fontWeight={700}
          fontSize="md"
          width="100%"
          role="navigation"
          aria-label="Main navigation"
        >
          <NavigationHeaderItemsDesktop
            items={items}
            handleClick={handleClick}
            parentCollection={buttonTitle}
            parentCollectionUrl={parentCollectionUrl}
          />
          {items.length < 4 && <GridItem width="100%" />}
        </Grid>
      </Container>
    </Box>
  )
}

const NavigationHeaderDesktopButtonDropdown: React.FC<MenuItemPropsDesktop> = ({
  title,
  url,
  items,
  active,
  handleActive,
  handleClick,
  location,
}) => {
  const hasSubItems = !!items.length
  const buttonTitle = title.toString()
  const currentActive = useMemo(() => buttonTitle === active, [active, buttonTitle])
  const currentRoute = url === location?.pathname && url !== "/"

  return (
    <NavigationHeaderDesktopArrowButton
      handleActive={handleActive}
      title={title}
      url={url}
      hasSubItems={hasSubItems}
      buttonTitle={buttonTitle}
      currentActive={currentActive}
      currentRoute={currentRoute}
      handleClick={handleClick}
    >
      <NavigationHeaderDropdownDesktop
        hasSubItems={hasSubItems}
        handleClick={handleClick}
        items={items}
        handleActive={handleActive}
        buttonTitle={buttonTitle}
        currentActive={currentActive}
        parentCollectionUrl={url}
      />
    </NavigationHeaderDesktopArrowButton>
  )
}

const MemoNavigationHeaderDesktopButtonDropdown = memo(NavigationHeaderDesktopButtonDropdown)
export { MemoNavigationHeaderDesktopButtonDropdown as NavigationHeaderDesktopButtonDropdown }
