import { memo, useEffect, useMemo } from "react"
import { Box, VStack, HStack, Text } from "@chakra-ui/react"
import { useShopify } from "@app/hooks/useShopify"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { RewardsCartPoints } from "@components/Rewards"
import { useRewards } from "@app/hooks/useRewards"
import { useCartContext } from "@app/providers/cart"

type CartSummaryProps = {
  isMain?: boolean
}

type SummaryRowProps = {
  label: string
  value: string | React.ReactNode
}

const SummaryRow: React.FC<SummaryRowProps> = ({ label, value }) => (
  <HStack justifyContent="space-between" pb={2}>
    <Text>{label}</Text>
    <Text>{value}</Text>
  </HStack>
)

const CartSummary: React.FC<CartSummaryProps> = ({ isMain = false }) => {
  const { cart } = useCartContext()
  const { refreshRewardsUI } = useRewards()
  const { formatMoney } = useShopify()

  const subscriptionsDiscountValue =
    cart?.lines
      ?.map(lineItem => {
        const oneTimeOrSubscription = lineItem?.attributes?.find(field => field.key === "_oneTimeOrSubscription")?.value
        const subscriptionDiscount = lineItem?.attributes?.find(attr => attr.key === "_subscriptionDiscount")?.value
        const itemPrice = parseFloat(lineItem?.merchandise?.priceV2?.amount)
        const quantity = lineItem?.quantity
        if (oneTimeOrSubscription === "monthly" && subscriptionDiscount) {
          return (itemPrice / 100) * parseFloat(subscriptionDiscount) * quantity
        }
      })
      .filter(Number)
      .reduce((prev: number, curr: number) => prev + curr, 0) || 0

  const amountDue = formatMoney(parseFloat(cart?.cost.totalAmount?.amount || "0") - subscriptionsDiscountValue)

  // TODO: review this, can we just look at line level subtotal discounts instead?
  const discountApplied = cart?.lines?.reduce((prev: any, curr) => {
    if (!curr?.merchandise?.compareAtPriceV2?.amount) return prev
    const singleDiscount = parseFloat(curr?.merchandise?.compareAtPriceV2?.amount) - parseFloat(curr?.merchandise?.priceV2?.amount)
    const quantityDiscount = singleDiscount * curr?.quantity
    return prev + quantityDiscount
  }, 0)

  const subTotal = cart?.lines?.reduce((prev: any, curr) => {
    const itemCost = curr?.merchandise?.compareAtPriceV2?.amount
      ? parseFloat(curr?.merchandise?.compareAtPriceV2?.amount)
      : parseFloat(curr?.merchandise?.priceV2?.amount)
    return prev + itemCost * curr.quantity
  }, 0)

  const formattedDiscountApplied = formatMoney(discountApplied + subscriptionsDiscountValue)

  const totalTax = formatMoney(parseInt(cart?.cost?.totalTaxAmount?.amount || "0"))
  const locales = useLocale(LOCALE_KEYS.CART)

  const totalGiftCardDiscount = useMemo(
    () => cart?.appliedGiftCards?.reduce((acc, curr) => acc + Number(curr.amountUsed.amount), 0) || 0,
    [cart?.appliedGiftCards]
  )

  useEffect(() => {
    refreshRewardsUI()
  }, [refreshRewardsUI, amountDue])

  return (
    <Box textAlign="left" mb={[2, 2, 6]}>
      <VStack width="full" align="stretch" spacing={1}>
        {isMain && (
          <>
            <SummaryRow label="Subtotal" value={formatMoney(subTotal)} />
            {/* TODO: add label to locales */}
            {!!totalGiftCardDiscount && <SummaryRow label="Gift Card" value={formatMoney(-totalGiftCardDiscount)} />}
            {!!discountApplied && <SummaryRow label="Savings" value={formattedDiscountApplied} />}
            {locales?.cartGstIncludedLabel && <SummaryRow label={locales?.cartGstIncludedLabel} value={totalTax} />}
            <SummaryRow label={locales?.cartPointsEarnedLabel} value={<RewardsCartPoints total={cart?.cost?.subtotalAmount?.amount} />} />
          </>
        )}
        <HStack justify="space-between" fontWeight={700} fontSize="lg" borderTop={isMain ? "0.5px" : "none"} pt={isMain ? 2 : 0}>
          <Text fontWeight="inherit" fontSize="inherit">
            Total
          </Text>
          <Text fontWeight="inherit" fontSize="inherit">
            {amountDue}
          </Text>
        </HStack>
      </VStack>
    </Box>
  )
}

const MemoCartSummary = memo(CartSummary)
export { MemoCartSummary as CartSummary }
