// purpose is to re-render a useEffect if
// a dependency which is a 'deep' dependency (ie object
// or array) has values/subvalues change.
// place the return ref in the effect deps array.
// eg useEffect(() => null, [ref?.current])
import isDeepEqual from "fast-deep-equal/react"
import { useRef } from "react"

const useDeepDependency = (dependency: any) => {
  const ref = useRef(dependency)

  if (!isDeepEqual(ref.current, dependency)) {
    ref.current = dependency
  }

  return ref
}

export { useDeepDependency }
