import { memo, useMemo } from "react"
import { VStack, HStack, Heading } from "@chakra-ui/react"
import { useCustomerContext } from "@app/providers/customer"
import { useConfigContext } from "@app/providers/config"
import { useMedia } from "@app/hooks/useMedia"
import { useCore } from "@app/hooks/useCore"
import { CustomLink } from "@components/Link"
// import { ChevronDownIcon } from "@chakra-ui/icons"
import { InitialBox } from "@components/InitialBox"
import { RewardsCustomerPoints } from "@components/Rewards"

type AccountWidgetProps = {
  isMobileMenu?: boolean
}

const AccountWidget: React.FC<AccountWidgetProps> = ({ isMobileMenu = false }) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const {
    helpers: { isBrowser },
  } = useCore()
  const { customer } = useCustomerContext()
  const {
    helpers: { capitalise },
  } = useCore()
  const { isUnderMedium } = useMedia()

  const firstInitial = customer?.firstName?.[0] || "L"
  const firstName = useMemo(() => capitalise(customer?.firstName) || "Login", [capitalise, customer?.firstName])

  if (isBrowser && isUnderMedium && !isMobileMenu) return null

  return (
    <HStack spacing={2} alignItems={customer ? "flex-start" : "center"}>
      <InitialBox initial={firstInitial} />
      <VStack>
        <CustomLink to={customer ? routes.DASHBOARD : routes.LOGIN_REGISTER} title="Manage account">
          <Heading as="div" fontSize={["md", "sm"]}>
            {isMobileMenu ? "My Account" : firstName} {/* customer && <ChevronDownIcon /> */}
          </Heading>
          {customer && <RewardsCustomerPoints />}
        </CustomLink>
      </VStack>
    </HStack>
  )
}

const MemoAccountWidget = memo(AccountWidget)
export { MemoAccountWidget as AccountWidget }
