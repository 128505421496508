import { memo } from "react"
import { Text } from "@chakra-ui/react"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"

type Props = {
  title?: string
  [rest: string]: any
}

const FooterCopyright: React.FC<Props> = ({ title, ...props }) => {
  const currentYear = new Date().getFullYear()
  const locales = useLocale(LOCALE_KEYS.FOOTER)

  const copyRightText = locales?.copyrightText || "Copyright &copy;"

  return (
    <Text {...props} fontSize="sm" mr={14} color="inherit">
      <span dangerouslySetInnerHTML={{ __html: copyRightText }} /> {currentYear}, {title}
    </Text>
  )
}

const MemoFooterCopyright = memo(FooterCopyright)
export { MemoFooterCopyright as FooterCopyright }
