const baseStyle = {
  fontFamily: "body",
  lineHeight: "none",
  borderRadius: "base",
  fontWeight: "normal",
  border: "1.5px",
}

const variantBaseOutline = {
  bgColor: "brand.offWhite",
  borderColor: "brand.avocado",
  color: "brand.avocado",
}

const variantBaseSolid = {
  bgColor: "brand.avocado",
  borderColor: "brand.avocado",
  color: "brand.offWhite",
}

const variantSolid = {
  ...variantBaseSolid,
  _active: {
    ...variantBaseSolid,
  },
  _hover: {
    bgColor: ["brand.avocado", "brand.offWhite"],
    color: ["brand.offWhite", "brand.avocado"],
  },
  _disabled: {
    ...variantBaseSolid,
    opacity: 1,
  },
}

const variantUnstyled = {
  border: "none",
  minW: "unset",
  minH: "unset",
}

// IconButton component uses the Button token
// https://github.com/chakra-ui/chakra-ui/issues/3746
const variantIconButton = {
  minW: "unset",
  minH: "unset",
  border: "none",
  h: "unset",
}

const variantOutline = {
  ...variantBaseOutline,
  _active: {
    ...variantBaseOutline,
  },
  _hover: {
    bgColor: ["brand.offWhite", "brand.avocado"],
    borderColor: ["brand.avocado", "transparent"],
    color: ["brand.avocado", "brand.offWhite"],
    _disabled: {
      bgColor: ["brand.offWhite", "brand.avocado"],
      borderColor: ["brand.avocado", "transparent"],
      color: ["brand.avocado", "brand.offWhite"],
      opacity: 1,
    },
  },
  _disabled: {
    ...variantBaseOutline,
    opacity: 1,
  },
}

const variantOutlineDisabled = {
  ...variantBaseOutline,
  _active: {
    ...variantBaseOutline,
  },
  _hover: {
    bgColor: ["brand.offWhite", "brand.avocado"],
    borderColor: ["brand.avocado", "transparent"],
    color: ["brand.avocado", "brand.offWhite"],
    _disabled: {
      color: "brand.avocado",
      backgroundColor: "brand.disabledLight",
      borderColor: "transparent",
      opacity: 1,
    },
  },
  _disabled: {
    color: "brand.avocado",
    backgroundColor: "brand.disabledLight",
    borderColor: "transparent",
    opacity: 1,
  },
}

const sizes = {
  sm: {
    py: 2.5,
    px: 1,
    minW: [56],
    maxW: "full",
    fontSize: ["sm", "xl"],
    h: [10, 12],
  },
  md: {
    py: 2,
    minW: [25, 49],
    maxW: "full",
    fontSize: ["md", "xl"],
  },
  lg: {
    p: 2,
    fontSize: "xl",
    h: [10, 12],
  },
  ui: {
    py: 2,
    px: 3,
    maxW: [20],
    mr: "2",
    fontSize: "sm",
  },
}

const defaultProps = {
  variant: "solid",
  size: "md",
}

const variants = {
  "icon-button": variantIconButton,
  outline: variantOutline,
  outlineDisabled: variantOutlineDisabled,
  "outline-no-hover": variantBaseOutline,
  solid: variantSolid,
  "solid-no-hover": variantBaseSolid,
  unstyled: variantUnstyled,
}

export const Button = {
  baseStyle,
  variants,
  sizes,
  defaultProps,
}
