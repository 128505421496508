import { useMemo, createContext, useContext, useEffect, useCallback, useState } from "react"
import { useConfigContext } from "@app/providers/config"
import { useFunctions } from "@app/hooks/useFunctions"
import { useCore } from "@app/hooks/useCore"
import { useStorage } from "@app/hooks/useCore"
import { useCustomerContext } from "@app/providers/customer"

import type { Customer } from "shopify-storefront-api-typings"

type ContextProps = {
  checkIfSubscriptionProduct: ({ productId }: { productId: string }) => { isSubscriptionProduct: boolean }
  fetched: boolean
  subscriptionProducts: Array<any>
  portalUrl: string
  checkSubscriptionText: (text?: string) => boolean
}

const SubscriptionContext = createContext<ContextProps | undefined>(undefined)

const SubscriptionProvider: React.FC = ({ children }) => {
  const {
    settings: { functions, keys },
  } = useConfigContext()
  const { getStorage, setStorage } = useStorage()
  const {
    helpers: { decodeShopifyId, isBrowser },
  } = useCore()
  const { customer } = useCustomerContext()
  const { callFunction } = useFunctions()

  const [subscriptionProducts, setSubscriptionProducts] = useState<any>([])
  const [fetched, setFetched] = useState<boolean>(false)
  const [portalUrl, setPortalUrl] = useState("")

  const getSubscriptionProducts = useCallback(async () => {
    const storageSubscriptionProducts = getStorage(keys.subscriptionProducts)
    if (!fetched && storageSubscriptionProducts) {
      setSubscriptionProducts(storageSubscriptionProducts)
    }

    if (!fetched && !storageSubscriptionProducts) {
      try {
        const { status, body } = await callFunction(functions.rechargeGetSubscriptionProducts, null, "GET")
        if (status === "error") throw new Error("Error calling the recharge get subscription products endpoint")
        setSubscriptionProducts(body)
        const ONE_HOUR = 0.0412
        setStorage(keys.subscriptionProducts, body, ONE_HOUR)
      } catch (err) {
        console.warn("Error fetching subscription products", err)
      }
    }
    setFetched(true)
  }, [callFunction, fetched, functions, getStorage, keys, setStorage])

  const checkIfSubscriptionProduct = useCallback(
    ({ productId }: { productId: string }) => {
      const isSubscriptionProduct = subscriptionProducts?.find((product: any) => {
        return product?.product_id === Number(productId)
      })
      return { isSubscriptionProduct }
    },
    [subscriptionProducts]
  )

  const getCustomerPortal = useCallback(
    async (customer: Customer) => {
      const storageCustomerPortalUrl = getStorage(keys.customerPortalUrl)

      // Set the localstorage portal url as state if it already exists
      if (!portalUrl && storageCustomerPortalUrl) return setPortalUrl(storageCustomerPortalUrl)

      const shopifyCustomerId = decodeShopifyId(customer?.id, "Customer")
      if (!shopifyCustomerId) return false

      try {
        const response = await callFunction(functions.rechargeGetCustomerPortal, {
          shopifyCustomerId,
        })
        if (response.status === "error") throw new Error("Error creating recharge customer portal URL", response)
        const { portal_url, expires_at }: { portal_url: string; expires_at: string } = response?.body || {}

        if (!portal_url || !expires_at) return false

        // @ts-ignore next-line
        const secToExpiry: number = (new Date(expires_at) - new Date()) / 1000
        const hourToExpiry = secToExpiry / 60 / 60
        const dayToExpiry = hourToExpiry / 24

        // // Store the portal url in localStorage to prevent refetching, and update state
        if (isBrowser) {
          setStorage(keys.customerPortalUrl, portal_url, dayToExpiry)
          setPortalUrl(portal_url)
        }
      } catch (err) {
        console.warn(err)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBrowser, customer?.id]
  )

  const checkSubscriptionText = useCallback((text?: string) => {
    return !!text?.toLowerCase()?.includes("subscription")
  }, [])

  useEffect(() => {
    getSubscriptionProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Get recharge customer portal URL once customer is loaded
  useEffect(() => {
    const shouldCreateCustomerPortal = customer && isBrowser

    if (shouldCreateCustomerPortal) getCustomerPortal(customer)
  }, [customer, getCustomerPortal, isBrowser])

  const contextValue = useMemo<ContextProps>(
    () => ({
      checkIfSubscriptionProduct,
      subscriptionProducts,
      fetched,
      portalUrl,
      checkSubscriptionText,
    }),
    [subscriptionProducts, fetched, checkIfSubscriptionProduct, portalUrl, checkSubscriptionText]
  )

  return <SubscriptionContext.Provider value={contextValue}>{children}</SubscriptionContext.Provider>
}

const useSubscriptionContext = (): ContextProps => ({ ...useContext(SubscriptionContext) } as ContextProps)

export { SubscriptionContext, SubscriptionProvider, useSubscriptionContext }
export type { ContextProps }
