import { memo } from "react"
import { Box, Stack, Button, Text, InputProps } from "@chakra-ui/react"
import { useNewsletter } from "@app/hooks/useNewsletter"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { useFormBuilder, FIELD_TYPES, ControlledInput, FunctionErrors } from "@app/hooks/useFormBuilder"

import type { Location } from "@root/types/global"

type Props = InputProps & {
  initialFocusRef?: any
  location: Location
  minimal?: boolean
  height?: string | number
}

const SubscribeForm: React.FC<Props> = ({ initialFocusRef, location, minimal = false, height, ...rest }) => {
  const {
    data,
    errors: functionErrors,
    handleSubmit: handleNewsletterSubmit,
    handleChange,
    loading,
    success,
    newsletter,
  } = useNewsletter(location)
  const locales = useLocale(LOCALE_KEYS.SUBSCRIBE)

  const { fieldErrors, register, handleSubmit } = useFormBuilder({
    onSubmit: (data: any, event: any) => handleNewsletterSubmit(event),
  })

  if (success) return <Text>{newsletter?.additionalSuccess}</Text>

  const bannerButtonTxt = locales?.subscribeBannerButtonTxt || ""

  return (
    <Box id="subscribe" as="form" onSubmit={handleSubmit}>
      <Stack spacing={[2, 2, 4]} direction={["column", "row", minimal ? "row" : "column"]}>
        <ControlledInput
          type={FIELD_TYPES.FIRST_NAME}
          register={register}
          onChange={handleChange}
          fieldErrors={fieldErrors}
          data={data}
          ref={initialFocusRef}
          w={["full", null, 52]}
          bg="brand.inputBg"
          h={height}
          {...rest}
        />
        <ControlledInput
          type={FIELD_TYPES.EMAIL}
          register={register}
          onChange={handleChange}
          fieldErrors={fieldErrors}
          data={data}
          w={["full", null, 80]}
          bg="brand.inputBg"
          h={height}
          {...rest}
        />

        <Button type="submit" flexShrink={0} width={["full", "auto"]} isDisabled={loading} isLoading={loading} h={height} fontSize="xl">
          {bannerButtonTxt}
        </Button>
      </Stack>

      <FunctionErrors errors={functionErrors} color="brand.offWhite" />
    </Box>
  )
}

const MemoSubscribeForm = memo(SubscribeForm)
export { MemoSubscribeForm as SubscribeForm }
