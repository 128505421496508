import { useCore } from "@app/hooks/useCore"
import { useCallback } from "react"
import { useConfigContext } from "@app/providers/config"
import { navigate } from "gatsby"

export const useRoutes = () => {
  const {
    helpers: { getUrlParameter, setUrlParameter, isBrowser },
  } = useCore()
  const {
    settings: { routes },
  } = useConfigContext()
  const parentResolver = useCallback((parentUrls: Array<string>, item: any) => {
    if (item?.parentCollection?.shopify?.handle) {
      parentUrls.unshift(`/${item?.parentCollection?.shopify?.handle}`)
      parentResolver(parentUrls, item.parentCollection)
    }
  }, [])

  const urlResolver = useCallback(
    (source: any, route = "") => {
      const urlParts = []
      const parentUrls: Array<string> = []
      const type = source?._type || source?.document?._type
      const item = source?.[type] || source?.document || source
      const external = source?.external || false
      const url = item?.url || source?.link || ""
      const title = source?.title || item?.title || ""
      const handle = item?.meta?.canonicalUrl || item?.handle?.current || item?.shopify?.handle || item?.handle || ""
      const image = item?.image || source?.image || ""
      const key = source?._key

      if (type === "nutritionist") urlParts.push(routes.NUTRITIONIST)
      if (url?.length) urlParts.push(url)
      if (route?.length && !url?.length) urlParts.push(route)

      parentResolver(parentUrls, item)

      if (parentUrls.length) {
        urlParts.push(...parentUrls)
      }

      if (handle?.length) urlParts.push(`/${handle}`)

      const completeUrl = urlParts?.[0] !== "/home" ? urlParts.join("") || "/" : "/"

      return {
        title,
        image,
        url: completeUrl,
        external,
        key,
      }
    },
    [parentResolver, routes.NUTRITIONIST]
  )

  // If browser history, go back to prev page
  // else if fallback provided and no history, go to fallback url
  const goBack = useCallback(
    ({ fallback }: { fallback?: string }) => {
      if (isBrowser) {
        const hasHistory = window?.history?.length > 2

        if (!hasHistory && fallback) {
          return navigate(fallback)
        }

        window?.history?.back()
      }
    },
    [isBrowser]
  )

  return {
    getUrlParameter,
    setUrlParameter,
    urlResolver,
    goBack,
  }
}
