import { memo, forwardRef } from "react"
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby"
import { Link as ChakraLink, LinkProps } from "@chakra-ui/react"
import { useBreadcrumb } from "@app/hooks/useBreadcrumb"
import deepEqual from "fast-deep-equal/react"

interface GatsbyProps extends Omit<GatsbyLinkProps<Record<string, unknown>>, '"ref"'> {
  external?: boolean
  state?: any
}

type Props = GatsbyProps &
  LinkProps & {
    onClick?: (args: any) => any
    styles?: any
  }

// See https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link

export const DEFAULT_STATE = {}

const Link = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      children,
      to = "/",
      title = "",
      external = false, // gets passed to isExternal chakra prop for opening links in a new tab
      state = DEFAULT_STATE,
      onClick,
      variant = "unstyled", // chakra link variant
      styles, // pass a style object explicitly for any chakra styles
      className = "", // passed down from chakra's LinkOverlay etc
    },
    ref // forwardRef is used so that <ChakraComponent as={CustomLink} works correctly
  ) => {
    const { breadcrumbState } = useBreadcrumb()

    // if the link passed starts with /
    const isInternalLink = /^\/(?!\/)/.test(to) && !external
    const customState = breadcrumbState(state)

    // Use Gatsby Link for navigating between pages on the current domain,
    // using SPA features of @reach/router
    if (isInternalLink) {
      return (
        <ChakraLink
          as={GatsbyLink}
          to={to}
          title={title}
          state={customState}
          variant={variant}
          onClick={onClick}
          ref={ref}
          className={className}
          {...styles}
        >
          {children}
        </ChakraLink>
      )
    }

    if (!isInternalLink) {
      return (
        <ChakraLink
          href={to}
          title={title}
          ref={ref}
          variant={variant}
          onClick={onClick}
          className={className}
          {...(external ? { isExternal: true, target: "_blank", rel: "noopener noreferrer" } : {})}
          {...styles}
        >
          {children}
        </ChakraLink>
      )
    }

    return null
  }
)

// See https://reactjs.org/docs/react-api.html#reactmemo
const propsAreEqual = (prevProps: Props, nextProps: Props) => deepEqual(prevProps, nextProps)

const MemoCustomLink = memo(Link, propsAreEqual)
export { MemoCustomLink as CustomLink }
