import { graphql, useStaticQuery } from "gatsby"
import { useCore } from "@app/hooks/useCore"
import { useRoutes } from "@app/hooks/useRoutes"
import { useConfigContext } from "@app/providers/config"
import { useMedia } from "@app/hooks/useMedia"
import { useCallback } from "react"

import type { Location } from "@root/types/global"

type BreadcrumbProps = {
  data: any
}

type BuildBreadcrumbsReturn = {
  breadcrumbs: Array<BreadcrumbProps>
  shouldHideBreadcrumbs: boolean
}

const useBreadcrumb = (location?: Location) => {
  const {
    helpers: { capitalise, isBrowser, getUrlParameter },
  } = useCore()
  const {
    settings: { routes, params },
  } = useConfigContext()
  const { isBase } = useMedia()
  const { urlResolver } = useRoutes()
  const rootHomeBreadcrumb = urlResolver({ title: "Home" }, routes.HOMEPAGE)

  const { global, organisation } = useStaticQuery<GatsbyTypes.StaticBreadcrumbsQuery>(graphql`
    query StaticBreadcrumbs {
      global: sanityTemplateGlobal {
        title
      }
      organisation: sanitySettingOrganisation {
        title
        separator
      }
    }
  `)

  const cleanTitle = useCallback(
    (title: string) =>
      title
        ?.replace(global?.title || "", "")
        ?.replace(organisation?.title || "", "")
        ?.replace(` ${organisation?.separator} `, "")
        ?.replace(organisation?.separator || "", ""),
    [global?.title, organisation?.separator, organisation?.title]
  )

  const breadcrumbState = useCallback(
    (state: any) =>
      location?.pathname.includes(`${routes.COLLECTION}/`) && isBrowser
        ? {
            ...state,
            breadcrumbs: [
              { title: "All Products", url: `${routes.COLLECTION}/all-products` },
              {
                title: cleanTitle(window.document.title),
                url: location?.pathname,
              },
            ],
          }
        : state,
    [cleanTitle, isBrowser, location?.pathname, routes.COLLECTION]
  )

  const parentResolver = useCallback((parentCollections: Array<string>, item: any) => {
    if (item?.parentCollection?.shopify?.handle) {
      parentCollections.unshift(item.parentCollection)
      parentResolver(parentCollections, item.parentCollection)
    }
  }, [])

  const buildBreadcrumbs = useCallback(
    ({ data }: BreadcrumbProps): BuildBreadcrumbsReturn => {
      const builtItems: Array<any> = [rootHomeBreadcrumb]

      const item = data?.page || data?.product || data?.collection || data?.article || data?.store || data?.nutritionist
      const currentUrl = location?.pathname

      // last slice removes the trailing slash
      const paths = currentUrl?.split("/")?.slice(1).slice(0, -1)

      const isProductRoute = location?.pathname.includes(routes.PRODUCT)
      const isCollectionRoute = !!item && !!data?.collection
      const isNutritionistsListingRoute = location?.pathname === routes.NUTRITIONIST
      const isArticlesListingRoute = location?.pathname === routes.ARTICLE
      // const isAccountSubRoute = location?.pathname?.includes(routes.DASHBOARD) && paths?.length >= 2

      // This is passed through from the <Link /> component if a state={{ breadcrumbs: [{ title: <eg>, url: <eg> }] }} prop is set
      // See https://www.gatsbyjs.com/docs/location-data-from-props/#example-of-providing-state-to-a-link-component
      const locationState = location?.state?.breadcrumbs
      const breadcrumbIsParentCollection = locationState?.[0]?.title === item?.title && locationState?.[0]?.url === urlResolver(item)?.url

      if (locationState && !breadcrumbIsParentCollection) {
        // Output: Home -> <state> -> <collection>
        builtItems.push(...locationState)
      }

      if (isProductRoute) {
        if (locationState) {
          // Output: Home -> <state> -> <product>
          builtItems.push({
            ...item,
            title: item?.title?.trim(),
            url: urlResolver(item)?.url,
          })
        } else {
          // Output: Home -> Collections -> <product>
          builtItems.push({ title: "All Products", url: `${routes.COLLECTION}/all-products` })
        }
      }

      if (isNutritionistsListingRoute) {
        // Output: Home -> Directory -> Nutritionists
        builtItems.push({ title: "Directory" })
      }

      if (location?.pathname?.startsWith(routes.REVIEWS)) {
        builtItems.push({ title: "Review Your Box", url: routes.REVIEWS })

        if (location?.pathname !== routes.REVIEWS) {
          const handle = currentUrl?.split("/reviews/")?.[1]
          const collection = data?.page?.reviewcollections?.find((collection: any) => collection?.shopify?.handle === handle)
          builtItems.push({ title: collection?.title, url: location?.pathname })
        }
      }

      if (location?.pathname?.startsWith(routes.REVIEW_WRITE)) {
        builtItems.push({ title: "Review Your Box", url: routes.REVIEWS })
      }

      if (isCollectionRoute) {
        const parentCollections = [item]

        parentResolver(parentCollections, item)

        // Output: Home -> <parent collection> -> <current collection>
        parentCollections.map(collection => {
          urlResolver(collection)
          builtItems.push({
            ...item,
            title: collection?.title?.trim(),
            url: urlResolver(collection).url,
          })
        })

        // Output: Home -> [paths from above] -> <reactify fitlter name...>
        const categoryFilters = getUrlParameter(params.reactifyCategory)
        const categories = typeof categoryFilters === "string" ? JSON.parse(categoryFilters) : []
        categories?.map((filterName: string) => {
          builtItems.push({
            title: capitalise(filterName),
          })
        })
      }

      // if (paths?.length === 1) {
      // For all flexible and generic pages
      // Structure: home -> {page title}
      // builtItems.push({ title: item?.title, url: urlResolver(item)?.url })
      // }

      // For normal flexible and generic pages
      if (!isCollectionRoute) {
        // Output: Home -> <page title>
        paths?.map(path => {
          const shouldShowPageTitle = ["brands"].includes(path)
          const shouldShowPagePath = !["products", "reviews", "review", "write"].includes(path)

          if (path === "shopping-list") {
            builtItems.push({
              ...item,
              title: "Shopping List",
              url: urlResolver(item)?.url,
            })
          } else if (path === "login") {
            // If on the login-register route
            // Output: Home -> Account -> Login
            builtItems.push({
              ...item,
              title: "Login",
              url: urlResolver(item)?.url,
            })
          } else if (path === "cart") {
            builtItems.push({
              ...item,
              title: "Your Cart",
              url: urlResolver(item)?.url,
            })
          } else if (path === item?.handle?.current || path === item?.shopify?.handle) {
            // Output: ?
            builtItems.push({
              ...item,
              title: item?.title?.trim(),
              url: urlResolver(item)?.url,
            })
          } else if (shouldShowPageTitle || isArticlesListingRoute) {
            // Output: Home -> <page title>
            builtItems.push({
              ...item,
              title: item?.title,
              url: urlResolver(item)?.url,
            })
          } else if (shouldShowPagePath) {
            // Output: Home -> <Path>
            builtItems.push({
              ...item,
              title: capitalise(path?.trim()),
              url: `/${path}`,
            })
          }
        })
      }

      const shouldHideBreadcrumbs =
        location?.pathname === routes.HOMEPAGE ||
        location?.pathname === "/box-subscription" ||
        (isBase && location?.pathname?.includes(routes.PRODUCT)) ||
        (!isBase && location?.pathname?.includes(routes.ARTICLE))

      return {
        shouldHideBreadcrumbs: !!shouldHideBreadcrumbs,
        breadcrumbs: builtItems,
      }
    },
    [
      capitalise,
      getUrlParameter,
      isBase,
      location?.pathname,
      location?.state?.breadcrumbs,
      params.reactifyCategory,
      parentResolver,
      rootHomeBreadcrumb,
      routes,
      urlResolver,
    ]
  )

  return {
    breadcrumb: rootHomeBreadcrumb,
    buildBreadcrumbs,
    breadcrumbState,
  }
}

export { useBreadcrumb }
