import { Header } from "@components/Header"
import { withLayout } from "@app/hoc/Layout"
import { CartMini } from "@components/Cart/CartMini"
import { Announcement } from "@components/Announcement"
import Main from "@components/Main"
import Breadcrumb from "@components/Breadcrumb"
import Global from "@app/theme/foundations/global"
import Footer from "@app/components/Footer"

import type { PageProps } from "@root/types/global"

const LayoutComponent: React.FC<PageProps<any>> = ({ children, data, location }) => {
  return (
    <>
      <Global />
      <Announcement />
      <Header location={location} />
      <Main>
        <Breadcrumb data={data} location={location} />
        {children}
      </Main>
      <CartMini />
      <Footer location={location} />
    </>
  )
}

export const Layout = withLayout(LayoutComponent)
