import { memo, useCallback } from "react"
import { HStack, Text, Skeleton } from "@chakra-ui/react"
import { useShopifyPrice } from "@app/hooks/useShopify"

type ProductPriceProps = {
  variant?: any
  loading?: boolean
  size?: string
  mb?: number | Array<number>
  type?: string
  quantity?: number
  totalOnly?: boolean
  appliedDiscount?: number | undefined
  isPlp?: boolean
}

type SpanProps = {
  children: string
  fontSize: Array<string>
  lineHeight?: number
  px?: number
}

type PriceRegularProps = {
  onSale: ReturnType<typeof useShopifyPrice>["onSale"]
  formattedCompareAtPrice: ReturnType<typeof useShopifyPrice>["formattedCompareAtPrice"]
  formattedPrice: ReturnType<typeof useShopifyPrice>["formattedPrice"]
  loading: ProductPriceProps["loading"]
  size: ProductPriceProps["size"]
  mb: ProductPriceProps["mb"]
  totalOnly?: boolean
  appliedDiscount?: number | undefined
  isPlp?: boolean
}

type PriceCartMiniProps = {
  onSale: ReturnType<typeof useShopifyPrice>["onSale"]
  formattedCompareAtPrice: ReturnType<typeof useShopifyPrice>["formattedCompareAtPrice"]
  formattedPrice: ReturnType<typeof useShopifyPrice>["formattedPrice"]
  quantity: ProductPriceProps["quantity"]
  totalOnly?: boolean
  appliedDiscount?: number | undefined
}

const Span: React.FC<SpanProps> = ({ children, fontSize, lineHeight, px }) => (
  <Text as="span" fontWeight="inherit" fontSize={fontSize} lineHeight={lineHeight || 1} px={px || 0}>
    {children}
  </Text>
)

const PriceCartMini: React.FC<PriceCartMiniProps> = ({
  onSale,
  formattedPrice,
  formattedCompareAtPrice,
  totalOnly = false,
  appliedDiscount,
}) => {
  const getPriceParts = useCallback(() => {
    if (!formattedPrice) return null
    // splits at both $ and .
    let priceParts = formattedPrice.toString()?.split(/[$.]/)

    // If applied discount is present, calculate the discounted price
    if (appliedDiscount && appliedDiscount > 0) {
      const tempPrice = Number(priceParts?.[1] + "." + priceParts?.[2])
      const discountedPrice = `$${(tempPrice - tempPrice * (appliedDiscount / 100)).toFixed(2)}`
      priceParts = discountedPrice.toString()?.split(/[$.]/)
    }
    return priceParts
  }, [formattedPrice, appliedDiscount])

  const priceParts = getPriceParts()

  return (
    <Text fontSize="sm">
      {appliedDiscount && appliedDiscount > 0 && priceParts ? (
        <>
          <Text textDecoration="line-through" as="span">
            {onSale ? formattedCompareAtPrice : formattedPrice}
          </Text>
          <Text as="span" ml={1.5} color={totalOnly ? "brand.avocado" : "brand.error"}>{`$${priceParts[1]}.${priceParts[2]}`}</Text>
        </>
      ) : (
        <>
          <Text as="span" textDecoration={onSale ? "line-through" : "unset"}>
            {onSale ? formattedCompareAtPrice : formattedPrice}
          </Text>
          {onSale && (
            <Text as="span" ml={1.5} color={totalOnly ? "brand.avocado" : "brand.error"}>
              {formattedPrice}
            </Text>
          )}
        </>
      )}
    </Text>
  )
}

const PriceRegular: React.FC<PriceRegularProps> = ({
  loading,
  formattedPrice,
  onSale,
  formattedCompareAtPrice,
  size,
  mb,
  appliedDiscount,
  isPlp,
}) => {
  const getPriceParts = useCallback(() => {
    if (!formattedPrice) return null
    // splits at both $ and .
    let priceParts = formattedPrice.toString()?.split(/[$.]/)

    // If applied discount is present, calculate the discounted price
    if (appliedDiscount && appliedDiscount > 0) {
      const tempPrice = Number(priceParts?.[1] + "." + priceParts?.[2])
      const discountedPrice = `$${(tempPrice - tempPrice * (appliedDiscount / 100)).toFixed(2)}`
      priceParts = discountedPrice.toString()?.split(/[$.]/)
    }
    return priceParts
  }, [formattedPrice, appliedDiscount])

  const priceParts = getPriceParts()
  const smallFonts = size === "sm" ? ["md", "sm"] : ["md", "2xl"]
  const lgFont = size === "sm" ? ["5xl", "3xl"] : ["5xl", "7xl"]

  return (
    <Skeleton isLoaded={!loading && !!formattedPrice} mb={mb || 4}>
      <HStack spacing={0}>
        {priceParts && (
          <Text fontWeight={700} color={onSale ? "red" : "brand.avocado"} display="flex" alignItems="flex-start">
            <Span fontSize={smallFonts}>$</Span>
            <Span fontSize={lgFont} lineHeight={0.8} px={0.5}>
              {priceParts?.[1]}
            </Span>
            <Span fontSize={smallFonts}>{priceParts?.[2]}</Span>
          </Text>
        )}
        {appliedDiscount && appliedDiscount > 0 && !isPlp && (
          <>
            <Text as="s" pl={1}>
              {formattedPrice}
            </Text>
          </>
        )}
        {onSale && !appliedDiscount && (
          <>
            <Text as="s" pl={1}>
              {formattedCompareAtPrice}
            </Text>
            &nbsp;
          </>
        )}
      </HStack>
    </Skeleton>
  )
}

const ProductPrice: React.FC<ProductPriceProps> = ({
  variant,
  loading = false,
  size,
  mb,
  type,
  quantity,
  totalOnly = false,
  appliedDiscount,
  isPlp,
}) => {
  const { formattedCompareAtPrice, onSale, formattedPrice } = useShopifyPrice(variant, quantity)

  if (type === "cartMini") {
    return (
      <PriceCartMini
        onSale={onSale}
        formattedPrice={formattedPrice}
        formattedCompareAtPrice={formattedCompareAtPrice}
        quantity={quantity}
        totalOnly={totalOnly}
        appliedDiscount={appliedDiscount}
      />
    )
  }

  return (
    <PriceRegular
      formattedCompareAtPrice={formattedCompareAtPrice}
      onSale={onSale}
      formattedPrice={formattedPrice}
      size={size}
      mb={mb}
      loading={loading}
      totalOnly={totalOnly}
      appliedDiscount={appliedDiscount}
      isPlp={isPlp}
    />
  )
}

const MemoProductPrice = memo(ProductPrice)
export { MemoProductPrice as ProductPrice }
