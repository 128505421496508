import { memo, Fragment } from "react"
import { useNavigation } from "@app/hooks/useNavigation"
import { Text, Box } from "@chakra-ui/react"
import { CustomLink } from "@components/Link"

const FooterUtility: React.FC = () => {
  const { utility } = useNavigation()

  if (!utility?.length) return null

  const lastIdx = utility.length - 1

  return (
    <Box mb={[2, 2, 2, 0]}>
      {utility.map(({ title, url }, idx) => (
        <Fragment key={title}>
          <CustomLink
            to={url}
            title={title}
            styles={{
              display: "inline-block",
              px: 2,
              _first: {
                pl: 0,
              },
            }}
          >
            <Text color="inherit" variant="overline" fontWeight={400} fontSize="xs">
              {title}
            </Text>
          </CustomLink>
          {idx !== lastIdx && "|"}
        </Fragment>
      ))}
    </Box>
  )
}

const MemoFooterUtility = memo(FooterUtility)
export { MemoFooterUtility as FooterUtility }
