// Supported styles: https://chakra-ui.com/docs/styled-system/theming/theme#colors
// Usage: brand.{color} eg <Box bg="brand.avocado" />
export const colors = {
  brand: {
    // primary
    avocado: "#143B34",
    peachy: "#FC9173",
    rain: "#CDD8E3",
    lavender: "#D1B6D8",
    // secondary
    offWhite: "#FFFCF7",
    lightSand: "#F7EEE7",
    darkerSand: "#F4E7DD",
    sand: "#E1D8CE",
    midSand: "#EFDCC7",
    apricot: "#E8CDAF",
    apricotSand: "#DDBF9E",
    // tertiary
    yellow: "#FCE58A",
    disabledLight: "#D2DAD8",
    green30: "rgba(20, 59, 52, 0.3)",
    lighterGreen: "#174A41",
    error: "#EB6844",
    accepted: "#8DDCA8",
    // other / utility
    disabledDark: "rgba(20, 59, 52, .3)",
    inputBg: "#F6EEE7",
  },
}
