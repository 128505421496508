import React from "react"
import config from "./config"
import { Layout } from "./src/components/Layout"
import { Providers } from "./src/providers/providers"

export const wrapRootElement = ({ element }) => <Providers>{element}</Providers>

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props} settings={config.settings}>
    {element}
  </Layout>
)
