import { memo } from "react"
import { Box } from "@chakra-ui/react"

const Main = ({ children, ...props }: { children: any }) => (
  <Box as="main" position="relative" {...props}>
    {children}
  </Box>
)

export default memo(Main)
