import { memo, useEffect, useState, useRef, RefObject } from "react"
import { Container, Flex, Box, Center, Text, Fade } from "@chakra-ui/react"
import { useAnnouncement } from "@app/hooks/useAnnouncement"
import { useAppContext } from "@app/providers/app"
import { CustomLink } from "@components/Link"
import { ArrowForwardIcon } from "@chakra-ui/icons"

const Announcement: React.FC = () => {
  const { items, show, durationSeconds } = useAnnouncement()
  const [activeAnnouncementIdx, setActiveAnnouncementIdx] = useState(0)
  const barRef = useRef<HTMLDivElement>(null)
  const { state } = useAppContext()

  const isAnnouncementInView = (elRef: RefObject<HTMLDivElement>) => {
    const rect = elRef?.current?.getBoundingClientRect()
    return (
      rect &&
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  useEffect(() => {
    if (durationSeconds) {
      if (!show) {
        document.documentElement.style.setProperty("--announcement-height", `0px`)
      }
      document.documentElement.style.setProperty("--announcement-height", `${barRef.current?.offsetHeight}px`)
      const numAnnouncements = items?.length - 1

      const interval = setInterval(() => {
        const nextIdx = activeAnnouncementIdx >= numAnnouncements ? 0 : activeAnnouncementIdx + 1
        if (isAnnouncementInView(barRef) && !state.activeSearch) setActiveAnnouncementIdx(nextIdx)
      }, durationSeconds)

      return () => clearInterval(interval)
    }
  }, [durationSeconds, activeAnnouncementIdx, items?.length, state.activeSearch, show])

  if (!show) return null

  return (
    <Box as="section" bg="brand.peachy" role="group" ref={barRef}>
      <Container maxW="11xl" py={{ base: 2, sm: 2 }}>
        <Flex as="section" justify="space-between" align="center" color="brand.avocado" px={[0, 4]}>
          <Center display="flex" flex={1} flexGrow={1}>
            {items?.map(({ link, title }, idx) => (
              <Fade in={idx === activeAnnouncementIdx} key={idx}>
                <Box display={idx === activeAnnouncementIdx ? "flex" : "none"} alignItems="center">
                  {link?.link ? (
                    <CustomLink to={link?.link} external={link?.external}>
                      <Text>
                        {title}
                        <ArrowForwardIcon mx={2.5} w={4} _groupHover={{ transform: "translateX(8px)" }} transition="100ms ease-out" />
                      </Text>
                    </CustomLink>
                  ) : (
                    <Text>{title}</Text>
                  )}
                </Box>
              </Fade>
            ))}
          </Center>
        </Flex>
      </Container>
    </Box>
  )
}

const MemoAnnouncement = memo(Announcement)
export { MemoAnnouncement as Announcement }
