import { memo } from "react"
import { CustomLink } from "@components/Link"
import { BrandLogo } from "@components/Icon"

type SiteLogoProps = {
  fill?: string
}

const SiteLogo: React.FC<SiteLogoProps> = ({ fill = "brand.avocado " }) => {
  return (
    <CustomLink to="/">
      <BrandLogo w={[38, 38, 42]} h={[5, 5, 6]} fill={fill} />
    </CustomLink>
  )
}

const MemoSiteLogo = memo(SiteLogo)
export { MemoSiteLogo as SiteLogo }
