import { Image, AspectRatio, Box } from "@chakra-ui/react"
import { memo } from "react"

type CustomImageProps = {
  src?: string
  width?: any
  height?: number | string
  fit?: string
  aspectRatio?: "square" | "landscape" | "portrait" | "article" | "natural"
  variant?: string
  srcSet?: string
  sizes?: string
  [rest: string]: any
}

// TODO: integrate with `useImage` hook for perf. just added here initially for aspect ratio helper
const CustomImage: React.FC<CustomImageProps> = ({ src, width, height, fit, aspectRatio, variant, srcSet, sizes, ...imageProps }) => {
  // if (!src) return null

  const ratioMappings: any = {
    square: 1 / 1,
    landscape: 11 / 9,
    portrait: 9 / 11,
    article: 43 / 32,
    natural: "natural",
  }

  const ratio = ratioMappings[aspectRatio as keyof typeof ratioMappings]

  const extraImageProps =
    variant === "oval"
      ? {
          ...imageProps,
          height: 87,
          width: 75,
          fit: "cover",
          zIndex: 0,
          borderRadius: "ovalImg",
          objectPosition: "center",
        }
      : imageProps

  if (!src) {
    return (
      <AspectRatio ratio={ratio} width={width} bg="brand.disabledDark">
        <Box />
      </AspectRatio>
    )
  }

  return aspectRatio ? (
    <AspectRatio ratio={ratio} width={width} bg="white">
      <Image src={src} srcSet={srcSet} sizes={sizes} {...extraImageProps} loading="lazy" />
    </AspectRatio>
  ) : (
    <Image
      src={src}
      srcSet={srcSet}
      sizes={sizes}
      fit={fit || "contain"}
      width={width}
      height={height}
      {...extraImageProps}
      loading="lazy"
    />
  )
}

const MemoCustomImage = memo(CustomImage)
export { MemoCustomImage as CustomImage }
